import styled from 'styled-components';
import { colors } from '@ddsweb/selectors';

const BannerBackgroundImage = styled.div`
  ${({ path }) =>
    path &&
    `
      background-image: url(${path});
    `}
  background-size: cover;
  background-color: ${colors.utils};
  background-repeat: no-repeat;
  height: 100%;
  width: 100%;
  overflow: hidden;
  position: absolute;
  z-index: 0;

  @media (min-width: 992px) {
    width: 100vw;
  }

  @media (min-width: 1200px) {
    left: calc((100vw - 1200px) / -2);
  }

  .ui-panel,
  picture img {
    height: 100%;
  }
`;
BannerBackgroundImage.displayName = 'BannerBackgroundImage';

const HomepageHeader = styled.div`
  position: relative;
  background: ${colors.utils};

  .browse-store--button,
  .browse-favorites--button {
    background: ${colors.background.base};
    border: none;
    color: ${colors.tescoBlue};
    margin-top: 0;
    margin-bottom: 0;
  }

  @media (min-width: 992px) {
    position: relative;
    max-width: 1200px;
    margin: 0 auto;
  }
`;
HomepageHeader.displayName = 'HomepageHeader';

const BackgroundOverlay = styled.div`
  background: rgba(0, 0, 0, 0.1);
  height: 100%;
`;

export { BannerBackgroundImage, HomepageHeader, BackgroundOverlay };
