import React from 'react';
import { SUPPORTED_WISMO_TRACKING_STATUS, SUPPORTED_PAYMENT_RETRY_OPTION } from '#/constants/order-statuses';
import Delivered from './delivered';
import DeliveryScheduled from './delivery-scheduled';
import PreparingOrder from './preparing-order';
import ArrivedAtLocation from './arrived-at-location';
import PaymentFailed from './payment-failed';
import { useOrderContext } from '#/context/order-context-provider';
import CancelledContextCard from '#/components/home/cancelled-context-card';

type Props = {
  t: Function;
  showMap?: boolean;
  starRating: React.Component;
  display3Steps: boolean;
};

export const HomeDeliveryWismoStepper: React.FC<Props> = ({ showMap, starRating, display3Steps }) => {
  const { order } = useOrderContext();
  const retryOption = order.payment?.retryOption;
  const paymentFailed =
    retryOption === SUPPORTED_PAYMENT_RETRY_OPTION.ENABLED || retryOption === SUPPORTED_PAYMENT_RETRY_OPTION.EXPIRED;
  const status = order.wismoSlot?.currentTrackingStatus?.status;

  if (paymentFailed) {
    return <PaymentFailed starRating={starRating} />;
  }

  switch (status) {
    case SUPPORTED_WISMO_TRACKING_STATUS.CANCELLED:
      return <CancelledContextCard starRating={starRating} />;

    case SUPPORTED_WISMO_TRACKING_STATUS.PREPARING_ORDER:
      return <PreparingOrder starRating={starRating} display3Steps={display3Steps} />;

    case SUPPORTED_WISMO_TRACKING_STATUS.DELIVERY_SCHEDULED:
      return <DeliveryScheduled showMap={showMap} starRating={starRating} display3Steps={display3Steps} />;

    case SUPPORTED_WISMO_TRACKING_STATUS.ARRIVED_AT_CUSTOMER_LOCATION:
      return <ArrivedAtLocation starRating={starRating} display3Steps={display3Steps} />;

    case SUPPORTED_WISMO_TRACKING_STATUS.DELIVERED:
      return <Delivered starRating={starRating} display3Steps={display3Steps} />;

    default:
      return null;
  }
};

export default HomeDeliveryWismoStepper;
