import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment-timezone';
import styled from 'styled-components';
import Notification from '@ddsweb/notification';
import { connect } from '#/lib/render/connect-deep-compare';
import analyticsBus from '#/analytics/analyticsBus';
import helpers from '#/lib/decorators/helpers';
import ChevronLink from '#/components/link/chevron-link';
import { selectTaxonomyItem } from '#/actions/taxonomy-action-creators';
import { openNavMenu, openModal } from '#/actions/ui-action-creators';
import { getFirstNavItem } from '#/reducers/taxonomy';
import { basicEvent } from '#/analytics/types/basic';
import { getUrlWithJourneyParam } from '#/lib/url/journey-utils';
import { SUPERDEPARTMENT } from '#/constants/taxonomy-levels';
import { TABLET } from '#/constants/platforms';
import { ECOUPON_MODAL } from '#/constants/modal-names';
import { AMEND_COUPONS_JOURNEY } from '#/constants/journey-names';
import {
  getCheckoutEntryPoint,
  getPaymentOptionsLink
} from '#/utils/checkout-utils';
import { getDefaultSlotsPath } from '#/reducers/slot';
import SafelyOutputString from '#/components/safely-output-string';
import { getLanguage, getTimezone, getCurrentUrl } from '#/reducers/app';

const StyledNotification = styled(Notification)`
  margin-bottom: 18px;
`;

const mapStateToProps = (state, { f: feature, c: config }) => {
  const showAmendChangeSlot = feature('allowAmendSlot');

  return {
    checkoutEntryPoint: getCheckoutEntryPoint(state),
    checkoutInAmend: config('checkout:enableInAmendMode'),
    currentUrl: getCurrentUrl(state),
    defaultSlotsPath: getDefaultSlotsPath(feature)(state),
    language: getLanguage(state),
    paymentOptionsLink: getPaymentOptionsLink(state),
    showAmendChangeSlot,
    taxonomyFirstItem: getFirstNavItem(state),
    timezone: getTimezone(state)
  };
};

@helpers(['c', 'f', 't', 'l'])
@connect(mapStateToProps, {
  selectTaxonomyItem,
  openNavMenu,
  openModal
})
export default class AmendOrderContextCard extends React.Component {
  static propTypes = {
    address: PropTypes.object.isRequired,
    c: PropTypes.func.isRequired,
    checkoutEntryPoint: PropTypes.string.isRequired,
    checkoutInAmend: PropTypes.bool.isRequired,
    currentUrl: PropTypes.string.isRequired,
    defaultSlotsPath: PropTypes.string.isRequired,
    f: PropTypes.func.isRequired,
    l: PropTypes.func.isRequired,
    language: PropTypes.string.isRequired,
    openNavMenu: PropTypes.func.isRequired,
    order: PropTypes.object.isRequired,
    paymentOptionsLink: PropTypes.string.isRequired,
    selectTaxonomyItem: PropTypes.func.isRequired,
    showAmendChangeSlot: PropTypes.bool,
    t: PropTypes.func.isRequired,
    taxonomyFirstItem: PropTypes.object,
    timezone: PropTypes.string.isRequired,
    workingDays: PropTypes.number
  };

  constructor(props) {
    super(props);
  }

  getChevronLink({
    analyticsId,
    action,
    translationKey,
    href,
    onClick,
    dataAuto
  }) {
    const { t: translate } = this.props;
    const text = translate(translationKey);

    return (
      <ChevronLink
        key={analyticsId}
        className={'context-card-amend-order__option-link'}
        to={href}
        title={text}
        data-auto={dataAuto}
        onClick={e => {
          basicEvent(analyticsBus, {
            type: 'contextCard',
            value: analyticsId,
            action
          });

          if (typeof onClick === 'function') {
            onClick(e);
          }
        }}
      >
        {text}
      </ChevronLink>
    );
  }

  getAddItemsLink() {
    return this.getChevronLink({
      analyticsId: 'change items',
      action: 'now',
      href: this.props.l('/shop'),
      translationKey: 'home:amend-order.add-items-to-basket',
      dataAuto: 'amend-add-items-link',
      onClick: e => {
        e.stopPropagation();
        this.props.openNavMenu();
        this.props.selectTaxonomyItem(
          SUPERDEPARTMENT,
          this.props.taxonomyFirstItem,
          TABLET
        );
        e.preventDefault();
      }
    });
  }

  getChangeSlotLink() {
    return this.getChevronLink({
      analyticsId: 'change slot',
      action: 'delay',
      href: this.props.l(this.props.defaultSlotsPath),
      translationKey: 'home:amend-order.change-your-slot',
      dataAuto: 'amend-change-slot-link'
    });
  }

  handleAddCouponClick = e => {
    e && e.preventDefault();
    this.props.openModal(ECOUPON_MODAL);
  };

  getAddCouponsLink() {
    const { checkoutEntryPoint } = this.props;
    const linkData = {
      analyticsId: 'add coupons',
      action: 'delay',
      translationKey: 'home:amend-order.add-coupons-vouchers',
      dataAuto: 'add-vouchers-ecoupons'
    };

    linkData.href = getUrlWithJourneyParam(
      checkoutEntryPoint,
      AMEND_COUPONS_JOURNEY,
      [AMEND_COUPONS_JOURNEY]
    );

    return this.getChevronLink(linkData);
  }

  getChangePaymentDetailsLink() {
    const { paymentOptionsLink } = this.props;
    const linkData = {
      analyticsId: 'change payment',
      action: 'now',
      translationKey: 'home:amend-order.change-payment-details',
      dataAuto: 'amend-change-payment-link',
      href: paymentOptionsLink
    };

    return this.getChevronLink(linkData);
  }

  getBodyText() {
    const {
      t: translate,
      address,
      order,
      language,
      timezone,
      workingDays
    } = this.props;

    let translationKey = 'home:amend-order.context-card-details';
    let orderDate = workingDays;

    orderDate = moment(order.slot.start)
      .tz(timezone)
      .locale(language)
      .format('ll');

    return translate(translationKey, {
      address_name: address.name,
      order_date: orderDate
    });
  }

  render() {
    const { t: translate, c: config, showAmendChangeSlot } = this.props;
    const orderDetails = this.getBodyText();
    const showInfoMessage = config('showPriceInfoAmendContextCard');

    return (
      <div
        className="context-card-amend-order"
        data-auto="context-card-amend-order"
      >
        <h2 className="context-card-amend-order__title">
          {translate('home:amend-order.context-card-title')}
        </h2>
        <p>
          <SafelyOutputString>{orderDetails}</SafelyOutputString>
        </p>
        <div className="context-card-amend-order__options">
          <div className="context-card-amend-order__option left">
            {this.getAddItemsLink()}
            {showAmendChangeSlot && this.getChangeSlotLink()}
          </div>
          <div className={'context-card-amend-order__option right'}>
            {
              <>
                {this.getAddCouponsLink()}
                {config('changePaymentDetailsEnabled') &&
                  this.getChangePaymentDetailsLink()}
              </>
            }
          </div>
        </div>
        {showInfoMessage && (
          <StyledNotification
            title={translate('home:amend-order.prices-might-change')}
            showIcon={true}
          />
        )}
      </div>
    );
  }
}
