import React, { Component, useContext } from 'react';

const OrderContext = React.createContext();
export const useOrderContext = () => useContext(OrderContext);

class OrderContextProvider extends Component {
  state = {
    order: this.props.order
  };

  updateOrderContext = newOrder => {
    this.setState({ order: newOrder });
  };

  render() {
    const { children } = this.props;
    const { order } = this.state;

    return (
      <OrderContext.Provider
        value={{ order, setOrder: this.updateOrderContext }}
      >
        {children}
      </OrderContext.Provider>
    );
  }
}

export { OrderContextProvider, OrderContext };
