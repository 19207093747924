import React from 'react';
import { NotificationBannerWrapper } from './styled';
import { NotificationBanner, Panel } from '#/components/cms-driven';
import { renderTree } from '#/components/cms-driven/component-tree';

type OwnProps = {
  data: object;
};

const TopNotification: React.FC<OwnProps> = ({ data }: OwnProps) => {
  if (!data) {
    return null;
  }

  return <NotificationBannerWrapper>{renderTree({ NotificationBanner, Panel }, [data])}</NotificationBannerWrapper>;
};

export default TopNotification;
