import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled, { css, withTheme } from 'styled-components';
import Tooltip from '@ddsweb/popover';
import { Heading } from '@ddsweb/heading';
import Text from '@ddsweb/text';
import { colors } from '@ddsweb/selectors';
import { ThemeOverride } from '@ddsweb/helpers';
import { connect } from '#/lib/render/connect-deep-compare';
import helpers from '#/lib/decorators/helpers';
import analyticsBus from '#/analytics/analyticsBus';
import { basicEvent } from '#/analytics/types/basic';
import { CONTEXT_CARD, NOW } from '#/analytics/constants';
import {
  isNavMenuOpen,
  isHeaderSearchOpen,
  getHeaderMultiSearchLinkVisibility
} from '#/reducers/ui';
import { getSuggestionsVisibility, getSuggestions } from '#/reducers/search';
import {
  getIsMobile,
  getHasAcceptedCookiePolicy,
  getHasAcceptedAppBanner,
  getIsDesktop
} from '#/reducers/app';
import { getCID } from '#/reducers/user';
import { firstTimeShopper } from '#/constants/experiments';
import { setCookie } from '#/lib/cookie/cookie-utils';
import { debounce } from '#/utils/misc';

const mapStateToProps = state => ({
  isMobile: getIsMobile(state),
  isDesktop: getIsDesktop(state),
  isNavMenuOpen: isNavMenuOpen(state),
  isHeaderSearchOpen: isHeaderSearchOpen(state),
  isSearchSuggestionsClosed:
    !getSuggestions(state).length || !getSuggestionsVisibility(state), //getSuggestionsVisibility is true even when empty suggestions
  isMultiSearchLinkVisible: getHeaderMultiSearchLinkVisibility(state),
  hasAcceptedCookiePolicy: getHasAcceptedCookiePolicy(state),
  hasAcceptedAppBanner: getHasAcceptedAppBanner(state),
  customerId: getCID(state)
});

export class FirstTimeShopperTooltip extends Component {
  static propTypes = {
    customerId: PropTypes.string.isRequired,
    firstTimeShopperTooltipVariant: PropTypes.string.isRequired,
    hasAcceptedAppBanner: PropTypes.bool.isRequired,
    hasAcceptedCookiePolicy: PropTypes.bool.isRequired,
    id: PropTypes.string.isRequired,
    isDesktop: PropTypes.bool.isRequired,
    isHeaderSearchOpen: PropTypes.bool.isRequired,
    isMobile: PropTypes.bool.isRequired,
    isMultiSearchLinkVisible: PropTypes.bool.isRequired,
    isNavMenuOpen: PropTypes.bool.isRequired,
    isSearchSuggestionsClosed: PropTypes.bool.isRequired,
    t: PropTypes.func.isRequired,
    targetRef: PropTypes.object.isRequired
  };

  constructor(props) {
    super(props);
    const { t, firstTimeShopperTooltipVariant } = props;
    this.content = {
      title: t(`home:book-a-slot.first-time-shopper-tooltip-message.title`),
      body: t(
        `home:book-a-slot.first-time-shopper-tooltip-message.variant-${firstTimeShopperTooltipVariant}`
      )
    };
    this.accessibleLabel = `${this.content.title} ${this.content.body}`;
    this.replaceTooltip = this.replaceTooltip.bind(this);
  }

  state = {
    isOpen: true,
    refreshToken: 'init'
  };

  BodyTextStyled = styled(Text)`
    && {
      color: ${colors.inverse};
    }
  `;
  HeadingStyled = styled(Heading)`
    && {
      color: ${colors.inverse};
    }
  `;

  componentDidMount() {
    this.addWindowEventListener();
  }

  componentWillUnmount() {
    this.removeWindowEventListener();
    clearTimeout(this.timeout);
  }

  componentDidUpdate(prevProps) {
    const closedCookiePolicy =
      this.props.hasAcceptedCookiePolicy &&
      prevProps.hasAcceptedCookiePolicy !== this.props.hasAcceptedCookiePolicy;

    const closedAppBanner =
      prevProps.hasAcceptedAppBanner !== this.props.hasAcceptedAppBanner;

    const toggledHeaderSearchBar =
      prevProps.isHeaderSearchOpen !== this.props.isHeaderSearchOpen;

    const toggledMultiSearchLink =
      prevProps.isMultiSearchLinkVisible !==
      this.props.isMultiSearchLinkVisible;

    if (
      toggledMultiSearchLink ||
      closedCookiePolicy ||
      closedAppBanner ||
      toggledHeaderSearchBar
    ) {
      this.replaceTooltip();
    }
  }

  addWindowEventListener() {
    if (this.props.isDesktop) {
      window.addEventListener('resize', this.handleWindowResize);
    } else {
      window.addEventListener('orientationchange', this.replaceTooltip);
    }
  }

  removeWindowEventListener() {
    if (this.props.isDesktop) {
      window.removeEventListener('resize', this.handleWindowResize);
    } else {
      window.removeEventListener('orientationchange', this.replaceTooltip);
    }
  }

  handleWindowResize = debounce(() => {
    this.setState({
      refreshToken: `${Math.random()}`
    });
  }, 100);

  replaceTooltip() {
    this.setState({
      isOpen: false
    });
    clearTimeout(this.timeout);
    this.timeout = setTimeout(() => {
      this.setState({
        isOpen: true
      });
    }, 500);
  }

  emitFTSExperimentAnayticsEvent() {
    basicEvent(analyticsBus, {
      type: CONTEXT_CARD,
      value: 'tooltip:close',
      action: NOW
    });
  }

  onToolTipChange({ action }) {
    const closed = action === 'close';
    if (closed) {
      this.setState({
        isOpen: false
      });
      this.removeWindowEventListener();
      const { getCookieKey, cookieExpiry } = firstTimeShopper;
      this.emitFTSExperimentAnayticsEvent();
      setCookie(
        getCookieKey(this.props.customerId),
        'closed',
        cookieExpiry,
        '/groceries'
      );
    }
  }

  overrideTheme = props => theme => {
    const themeColors = theme.colors;

    return {
      colors: {
        ...themeColors,
        background: {
          ...themeColors.background,
          base: colors.info(props)
        }
      }
    };
  };

  render() {
    const {
      HeadingStyled,
      BodyTextStyled,
      content,
      accessibleLabel,
      props,
      state
    } = this;
    const {
      targetRef,
      id,
      isMobile,
      isNavMenuOpen,
      isSearchSuggestionsClosed
    } = props;

    if (!state.isOpen || isNavMenuOpen || !isSearchSuggestionsClosed)
      return null;

    const override = this.overrideTheme(props);

    return (
      <ThemeOverride overrides={override}>
        <Tooltip
          refreshToken={state.refreshToken}
          id={id}
          accessibleLabel={accessibleLabel}
          open
          persist
          targetRef={targetRef}
          position={isMobile ? 'top' : 'bottom'}
          onChange={e => this.onToolTipChange(e)}
          supressTargetFocusOnClose
          containerWidth={296}
          styles={css`
            background-color: ${colors.info};
            z-index: ${isMobile ? 11 : 13};
            .ddsweb-popover__pointer + button {
              background-color: ${colors.inverse};
            }
          `} /* z-index is 12 for left nav on tablets we want to stay above it
              but on mobiles below it while scrolling down */
        >
          <HeadingStyled headingLevel="5">{content.title}</HeadingStyled>
          <BodyTextStyled forwardedAs="p">{content.body}</BodyTextStyled>
        </Tooltip>
      </ThemeOverride>
    );
  }
}

export default connect(mapStateToProps)(
  helpers(['t'])(withTheme(FirstTimeShopperTooltip))
);
