import React from 'react';
import PropTypes from 'prop-types';
import SafelyOutputString from '#/components/safely-output-string';
import { SHOPPING_METHOD_PROP_TYPE } from '#/components/slots/prop-types';
import { isOnDemandShoppingMethod } from '#/lib/shopping-method-util';
import CountdownTimer from '#/components/shared/countdown-timer';
import { CALLBACK_KEYS } from '#/components/shared/countdown-timer/callback-keys';
import { CountdownTimeText } from '#/components/home/context-cards/styled';
import { connect } from 'react-redux';
import helpers from '#/lib/decorators/helpers';
import { getFormattedTime } from '#/components/shared/formatted-time/selector';

const mapStateToProps = (state, ownProps) => ({
  expiryTime: getFormattedTime(state, ownProps.expiry, ownProps.c)
});

const InfoMessage = ({
  timeLeft,
  expiry,
  t: translate,
  shoppingMethod,
  slotExpiredCB,
  expiryTime
}) => {
  let bottomText = '';
  const overrideMessage = isOnDemandShoppingMethod(shoppingMethod);
  const calDay = expiry.calendar(null, {
    sameDay: '[today]',
    nextDay: '[tomorrow]'
  });

  const zeroRemainingCB = () => {
    slotExpiredCB();
  };

  const getMessageWithCountdownTimer = () => {
    const prefixText = translate(
      'home:book-a-slot.slot-booked.checkout-by-ondemand-checkout-in'
    );
    const suffixText = translate(
      'home:book-a-slot.slot-booked.checkout-by-ondemand-product-prices-offers'
    );
    return (
      <>
        <span>{prefixText}</span>
        <CountdownTimeText>
          <CountdownTimer
            countdownEnd={expiry}
            cbKey={CALLBACK_KEYS.ONDEMAND_ZERO_REMAINING_HOMEPAGE}
            cb={zeroRemainingCB}
          />
        </CountdownTimeText>
        <span>. {suffixText}</span>
      </>
    );
  };

  const getBottomText = () => {
    if (timeLeft > 0) {
      if (calDay === 'today') {
        if (overrideMessage) {
          bottomText = getMessageWithCountdownTimer();
          return bottomText;
        } else {
          bottomText = translate(
            'home:book-a-slot.slot-booked.ckeckout-by-today',
            {
              check_out_by: expiryTime
            }
          );
        }
      } else if (calDay === 'tomorrow') {
        bottomText = translate(
          'home:book-a-slot.slot-booked.ckeckout-by-tomorrow',
          {
            check_out_by: expiryTime
          }
        );
      } else {
        bottomText = translate('home:book-a-slot.slot-booked.ckeckout-by', {
          check_out_on: expiry.format('DD-MM-YYYY'),
          check_out_by: expiryTime
        });
      }
    }
    return <SafelyOutputString>{bottomText}</SafelyOutputString>;
  };

  return (
    <p
      className="context-card-book-a-slot--info-message"
      data-auto="context-card-info-message"
    >
      {getBottomText()}
    </p>
  );
};

InfoMessage.propTypes = {
  expiry: PropTypes.object.isRequired,
  shoppingMethod: SHOPPING_METHOD_PROP_TYPE.isRequired,
  slotExpiredCB: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
  timeLeft: PropTypes.number.isRequired
};

export default helpers(['c'])(connect(mapStateToProps)(InfoMessage));
