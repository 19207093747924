import { logApmError } from '#/lib/apm';
import { clientGraphqlPost } from '#/lib/requests/client-graphql';
import graphqlQuery from '#/lib/requests/graphql-queries/mutate-fulfilment-event';
import { OrderFulfilmentEventStatus } from '#/constants/im-here';
import uuidv4 from 'uuid/v4';
import { MUTATE_ORDER_FULFILMENT } from '#/constants/request-names';

export type RequestConfig = {
  apiKey: string;
  endpoint: string;
  region: string;
  atrc: string;
};

type ErrorResponse = {
  status: number;
  message: string;
  parsedBody?: {
    errors?: Error[];
  };
};

type FulfilmentEventError = { code: string | number };

export type MutateOrderFulfilmentEventResponse = {
  data: {
    fulfilmentEvent: {
      status: string | null;
      errors: FulfilmentEventError[] | null;
    };
  };
  cache: {
    maxAge: number;
  };
};

export const mutateOrderFulfilmentEvent = async (
  payload: { [key: string]: string | undefined | null },
  { apiKey, endpoint, region, atrc }: RequestConfig,
): Promise<MutateOrderFulfilmentEventResponse | { error?: boolean }> => {
  const uuid = uuidv4();
  const traceId = `${atrc}:${uuid}`;
  const context = { 'x-apikey': apiKey, traceId, region };
  const queryVariables = {
    action: {
      collection: { locationUuid: payload.locationUuid, orderId: payload.orderId, action: payload.action },
    },
  };

  try {
    const requestName = MUTATE_ORDER_FULFILMENT;
    const response = await clientGraphqlPost(
      endpoint,
      context,
      graphqlQuery,
      queryVariables,
      atrc,
      requestName,
      traceId,
    );
    const {
      data: {
        fulfilmentEvent: { status, errors },
      },
    } = response;

    if (status !== OrderFulfilmentEventStatus.SUCCESS) {
      let message = `Order Fulfilment event Error - Message: GraphQL Mutation status is ${status}`;
      if (Array.isArray(errors) && errors.length > 0) {
        message += ', GraphQL Errors:';
        errors.forEach(({ code }: FulfilmentEventError) => {
          message += ` ${code}`;
        });
      }
      logApmError(new Error(message), { traceId });
    }
    return response;
  } catch (responseError) {
    const res = responseError as ErrorResponse;
    const error = res?.parsedBody?.errors?.[0];
    const statusCode = res?.status || 'unknown';
    const errorMessage = error?.message || res.message || 'unknown';
    const message = `Order Fulfilment event Error - Message: ${errorMessage}, Status: ${statusCode}`;

    logApmError(new Error(message), { traceId });
    return { error: true };
  }
};
