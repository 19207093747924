import { getResources } from '#/selectors/resources';
import { HOME_PAGE } from '#/constants/spa-resource';

const getHomePageResource = state => getResources(state)[HOME_PAGE];

export const getDcsHomePageUnorderedComponentByID = (componentId: string) => state => {
  const homePageResource = getHomePageResource(state);
  const unordered = homePageResource?.data.content?.unordered;
  if (!unordered) {
    return null;
  }
  return unordered[componentId] || null;
};

export const getContextCardData = (componentId: string) => state => {
  const contextCardData = getDcsHomePageUnorderedComponentByID(componentId)(state);

  if (!contextCardData) {
    return null;
  }

  return {
    ...contextCardData.props,
    ...contextCardData.children?.[0].props,
  };
};

export const getHomePageAnalyticsData = state => {
  const homePageResource = getHomePageResource(state);

  const unordered = homePageResource?.data.content?.unordered;

  if (!unordered) {
    return homePageResource?.data;
  }
  const content = homePageResource.data.content;

  return {
    ...homePageResource.data,
    content: {
      ...content,
      unordered: Object.values(unordered),
    },
  };
};
