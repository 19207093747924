import category from './category';
import source from './source';

export function getMessageData(region) {
  const messageData = {
    UK: {
      meta: ['category', 'source', 'signature', 'message'],
      content: [
        [
          category.ORDER_ANYTIME,
          source.TWITTER,
          '',
          'Order online anytime, anywhere. Then leave the rest to us. We’ll deliver to your door or car boot. Forgotten something? No problem. You can amend your order right up to 11.45pm the night before your slot. Shopping, sorted.'
        ],
        [
          category.TRIED_CLICK_AND_COLLECT,
          source.TWITTER,
          '',
          "With over 500 locations across the country to collect from, you'll find a convenient, great-value slot that fits around your schedule. When it's your slot time, we'll have your shop ready and waiting for you. So now you're in the know, why not give it a go?"
        ],
        [
          category.SHOPPING_PICKED_PERSONALLY,
          source.TWITTER,
          '',
          'Want your bananas extra green or smaller jacket spuds? Just let us know. Add a note when you check out and we’ll pass it on to our pickers.'
        ],
        [
          category.ORDER_HOMEWARE,
          source.TWITTER,
          '',
          'Did you know, you can order homeware, cookware, toys, computer games, and lots of other products with your weekly shop?'
        ]
      ]
    },
    IE: {
      meta: ['category', 'source', 'signature', 'message'],
      content: [
        [
          category.TRIED_CLICK_AND_COLLECT,
          source.TWITTER,
          '',
          'With locations available nationwide to collect from, you can book a convenient slot, at a time that suits you for free. At your selected time, simply pull up at the designated Click+Collect area and pick-up your shop. So now you’re in the know, why not give it a go?'
        ],
        [
          category.ORDER_HOMEWARE,
          source.TWITTER,
          '',
          'Did you know, you can order homeware, kitchen essentials, toys and more with your weekly shop?'
        ]
      ]
    }
  };

  return messageData[region];
}

export function getCategoryData(region) {
  const categoryData = {
    UK: {
      [category.CLICK_COLLECT]: {
        url: '/click-and-collect/',
        punchLine: 'Click. Collect. Devour.',
        moreInfo: 'More about Click+Collect'
      },
      [category.DELIVERY_SAVER]: {
        url: '/deliverysaver/',
        punchLine: 'A sweet deal on delivery.',
        moreInfo: 'More about Delivery Saver'
      },
      [category.ORDER_ANYTIME]: {
        url: null,
        punchLine: 'Shopping that works around you'
      },
      [category.TRIED_CLICK_AND_COLLECT]: {
        url: null,
        punchLine: 'Have you tried Click+Collect?'
      },
      [category.SHOPPING_PICKED_PERSONALLY]: {
        url: null,
        punchLine: 'Your shopping, picked personally'
      },
      [category.ORDER_HOMEWARE]: {
        url: '/groceries/en-GB/zone/Homeware',
        punchLine: 'Need a frying pan with your eggs?',
        moreInfo: 'See Home and Entertainment'
      }
    },
    IE: {
      [category.TRIED_CLICK_AND_COLLECT]: {
        url: null,
        punchLine: 'Have you tried Click+Collect?'
      },
      [category.ORDER_HOMEWARE]: {
        url: '/groceries/en-IE/shop/home-and-living/all',
        punchLine: 'Need a frying pan with your eggs?',
        moreInfo: 'Discover home and living'
      }
    }
  };

  return categoryData[region];
}

export const messagesMeta = ['category', 'source', 'signature', 'message'];

export const columnIndex = {
  category: messagesMeta.indexOf('category'),
  source: messagesMeta.indexOf('source'),
  signature: messagesMeta.indexOf('signature'),
  message: messagesMeta.indexOf('message')
};
