import { SUPPORTED_WISMO_TRACKING_STATUS } from '#/constants/order-statuses';

function getThreeSteps(shoppingMethod) {
  return [
    {
      title: `context-cards:${shoppingMethod}-wismo-stepper.steps.delivery-scheduled.progress-bar`
    },
    {
      title: `context-cards:${shoppingMethod}-wismo-stepper.steps.arrived-at-customer-location.progress-bar`
    },
    {
      title: `context-cards:${shoppingMethod}-wismo-stepper.steps.delivered.progress-bar`
    }
  ];
}

export const getSteps = (
  display3Steps = false,
  shoppingMethod,
  paymentIssue = false
) => {
  const steps = getThreeSteps(shoppingMethod);
  if (!display3Steps) {
    if (paymentIssue) {
      steps.unshift({
        title: `context-cards:${shoppingMethod}-wismo-stepper.steps.payment-issue.progress-bar`
      });
    } else {
      steps.unshift({
        title: `context-cards:${shoppingMethod}-wismo-stepper.steps.preparing-order.progress-bar`
      });
    }
  }
  return steps;
};

export const mapStatusToStepNumber = (
  status,
  display3Steps = false,
  paymentIssue = false
) => {
  if (paymentIssue) {
    return 1;
  }
  switch (status) {
    case SUPPORTED_WISMO_TRACKING_STATUS.NOT_AVAILABLE:
      return 1;
    case SUPPORTED_WISMO_TRACKING_STATUS.PREPARING_ORDER:
      return display3Steps ? null : 1;
    case SUPPORTED_WISMO_TRACKING_STATUS.DELIVERY_SCHEDULED:
      return display3Steps ? 1 : 2;
    case SUPPORTED_WISMO_TRACKING_STATUS.ARRIVED_AT_CUSTOMER_LOCATION:
      return display3Steps ? 2 : 3;
    case SUPPORTED_WISMO_TRACKING_STATUS.DELIVERED:
      return display3Steps ? 3 : 4;
    default:
      return null;
  }
};
