import React, { PureComponent } from 'react';
import { SectionMessage } from '../../cms-driven/section-message';
import Link from '../../link-check-spa';
import { emitRegisterAnalytics } from '#/analytics/helpers/register';
import { getLoginUrl, getRegisterUrl } from '#/reducers/app';
import helpers from '#/lib/decorators/helpers';
import { connect } from '#/lib/render/connect-deep-compare';
import PropTypes from 'prop-types';

const mapStateToProps = state => ({
  loginUrl: getLoginUrl(state),
  registrationUrl: getRegisterUrl(state)
});
@helpers(['t', 'c'])
@connect(mapStateToProps)
export default class SignInRegisterNew extends PureComponent {
  static propTypes = {
    c: PropTypes.func.isRequired,
    loginUrl: PropTypes.string.isRequired,
    registrationUrl: PropTypes.string.isRequired,
    t: PropTypes.func.isRequired
  };

  handleClick = value => () => {
    emitRegisterAnalytics(value);
  };

  render() {
    const { t, c, loginUrl, registrationUrl } = this.props;
    return (
      <div className="signin-register">
        <h2 className="signin-register--header">
          {t('home:greeting-signed-out')}
        </h2>
        <div className="signin-register--signin">
          <div className="signin-register--section-message">
            <SectionMessage
              headingTag="h3"
              size="medium"
              message={t('home:already-a-customer')}
            />
          </div>
          <div
            className="signin-register--signin-button"
            data-auto="hp-signin-btn"
          >
            <Link className="button button-primary" to={loginUrl}>
              {t('common:sign-in')}
            </Link>
          </div>
        </div>
        <div className="signin-register--register">
          <div className="signin-register--section-message">
            <SectionMessage
              headingTag="h3"
              size="medium"
              message={t('home:new-to', {
                context: c('brand')
              })}
            />
          </div>
          <div
            className="signin-register--register-button"
            data-auto="hp-register-btn"
          >
            <Link
              className="button button-secondary"
              to={registrationUrl}
              onClick={this.handleClick('Context Card')}
            >
              {t('common:register')}
            </Link>
          </div>
        </div>
      </div>
    );
  }
}
