export const ARRIVED = 'ARRIVED';
export enum OrderFulfilmentEventStatus {
  SUCCESS = 'OK',
  FAILED = 'FAILED',
}
export enum ImHereActionType {
  DISPLAY_IMHERE_MODAL = 'DISPLAY_IMHERE_MODAL',
  HIDE_IMHERE_MODAL = 'HIDE_IMHERE_MODAL',
  DISPLAY_LOADER = 'DISPLAY_LOADER',
  HIDE_LOADER = 'HIDE_LOADER',
  HIDE_BUTTON = 'HIDE_BUTTON',
}
