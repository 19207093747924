
  import '@babel/polyfill';
  import 'isomorphic-fetch';
  import render from '../lib/render/client-spa';
  import Home from '../components/pages/home';
  import { addToCache } from './lazy-component';

  // This tells Webpack the correct path to load bundles from.
  // Different environments and regions use different paths.
  // In development environments the Webpack devserver should be used instead (default behaviour).
  if (process.env.NODE_ENV !== 'development') {
    __webpack_public_path__ = document.documentElement.getAttribute('data-base-static-url') + '/';
  }

  addToCache('Home', Home);
  window.isSPA = true;

  render();
