import React, { createRef } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import moment from 'moment-timezone';
import { connect } from '#/lib/render/connect-deep-compare';
import helpers from '#/lib/decorators/helpers';
import { getTimezoneAndLocaleAppliedMoment } from '#/lib/date-helpers';
import { exposeActiveTestData } from '#/lib/optimizely-manager';
import BrowseStoreButton from '#/components/shared/browse-store';
import SimpleButton from '#/components/shared/simple-button';
import UserGreeting from '#/components/home/user-greeting';
import BookASlotContextCard from '#/components/home/book-a-slot-context-card';
import CancelledContextCard from '#/components/home/cancelled-context-card';
import AmendOrderContextCard from '#/components/home/amend-order-context-card';
import MultipleOrdersNotice from '#/components/orders/multiple-orders-notice';
import {
  NAV_ITEM_HEIGHT,
  NAV_LIST_COLLAPSED_VERTICAL_PADDING
} from '#/constants/ui';
import { ON_DEMAND } from '#/constants/shopping-methods';
import { CANCELLED } from '#/constants/order-statuses';
import { getNavList } from '#/reducers/taxonomy';
import {
  getIsMobile,
  getLanguage,
  getTimezone,
  getCutsMustard,
  getDeviceType
} from '#/reducers/app';
import { getIsFirstTimeShopper } from '#/reducers/user';
import { getWismoConfiguration } from '#/selectors/order-list-details';
import { getDcsHomePageUnorderedComponentByID } from '#/selectors/home-page';
import analyticsBus from '#/analytics/analyticsBus';
import { getFavoritesLanguageLink } from '#/utils/favorite-utils';
import { TOP_NOTIFICATION } from './constants';
import TopNotification from './top-notification';
import FavNavLinkTooltipContainer from '#/experiments/oop-1263/components';
import { getShowFavNavLinkTooltip } from '#/experiments/oop-1263/selectors';
import WismoStarRating from '../context-cards/wismo/shared/wismo-star-rating';
import Text from '@ddsweb/text';
import { OrderContext } from '#/context/order-context-provider';
import { InPageMessaging } from '@ddsweb/messaging';
import Heading from '@ddsweb/heading';
import {
  getIsAmongSlotBookingVariants,
  getIsNoFavsVariant
} from '#/experiments/oop-1978/selectors';
import BookAnotherSlot from '#/experiments/oop-1978/components/book-another-slot';
import {
  BOOK_ANOTHER_SLOT_ORDER_STATUSES,
  CONTEXT_CARD_STATUSES
} from '#/experiments/oop-1978/constants';

const mapStateToProps = (state, ownProps) => ({
  favoritesLink: getFavoritesLanguageLink(state, '/favorites'),
  isMobile: getIsMobile(state),
  navItems: getNavList(state),
  topNotification: getDcsHomePageUnorderedComponentByID(TOP_NOTIFICATION)(
    state
  ),
  cutsMustard: getCutsMustard(state),
  deviceType: getDeviceType(state),
  isFirstTimeShopper: getIsFirstTimeShopper(state),
  wismoConfiguration: getWismoConfiguration(state),
  renderFavTooltip: getShowFavNavLinkTooltip(state),
  language: getLanguage(state),
  timezone: getTimezone(state),
  showBookASlotButtons: getIsAmongSlotBookingVariants(state),
  showFavButtons: !getIsNoFavsVariant(state),
  isHorizontalTaxonomyEnabled: ownProps.c('enableHorizontalTaxanomy')
});

@helpers(['c', 't'])
@connect(mapStateToProps)
export default class BannerSignedIn extends React.Component {
  static propTypes = {
    address: PropTypes.object.isRequired,
    c: PropTypes.func.isRequired,
    favoritesLink: PropTypes.string.isRequired,
    isAmendBasket: PropTypes.bool.isRequired,
    isMobile: PropTypes.bool.isRequired,
    navItems: PropTypes.array.isRequired,
    renderFavTooltip: PropTypes.bool.isRequired,
    slot: PropTypes.object.isRequired,
    t: PropTypes.func.isRequired,
    topNotification: PropTypes.object,
    trolleyItems: PropTypes.array.isRequired,
    user: PropTypes.object.isRequired,
    welcomeTranslations: PropTypes.object.isRequired,
    wismoConfiguration: PropTypes.object
  };

  constructor(props) {
    super(props);
    this.targetRef = createRef();
  }

  static contextType = OrderContext;

  componentDidMount() {
    const { wismoConfiguration } = this.props;

    const { experiment, variation, optimizelyAttrs: attributes } =
      wismoConfiguration || {};
    exposeActiveTestData({
      experiment,
      variation,
      attributes
    });
  }

  getMainBannerStyles() {
    return {
      minHeight: `${this.props.navItems.length * NAV_ITEM_HEIGHT +
        NAV_LIST_COLLAPSED_VERTICAL_PADDING}px`
    };
  }

  shouldShowMultipleOrdersNotice() {
    const { order } = this.context;
    const {
      isAmendBasket,
      trolleyItems,
      slot,
      showBookASlotButtons
    } = this.props;
    return (
      order && // there's a recent order
      !isAmendBasket && // the basket is NOT in amend mode
      trolleyItems.length === 0 && // the trolley has 0 items in it
      !slot && // there is no booked slot
      !showBookASlotButtons // the 'book another slot' button from oop-1978 experiment is not displayed
    );
  }

  shouldShowFavAndBrowseStoreCTA() {
    const { recentOrder, slot, showFavButtons } = this.props;

    return (
      (recentOrder &&
        BOOK_ANOTHER_SLOT_ORDER_STATUSES.includes(recentOrder?.status)) || // there's an upcoming order
      slot?.slotId || // there's a booked slot
      showFavButtons // the variations from oop-1978 which disables the button are not active
    );
  }

  favoritesLinkClickHandler = () => {
    analyticsBus().emit('UIEventBasicEvent', {
      action: 'delay',
      type: 'home',
      value: 'shop favourites'
    });
  };

  getTooltipContent = (domRef, boundingRef) => (
    <FavNavLinkTooltipContainer
      id="fav-nav-link-tooltip-mobile"
      targetRef={domRef}
      boundingRef={boundingRef}
      tooltipRootID="browse-my-favorites"
      observerId="content"
      mobileOnly
    />
  );

  getDomRef = ref => {
    this.targetRef.current = ref;
  };

  formatDate = date => {
    return getTimezoneAndLocaleAppliedMoment(
      date,
      this.props.timezone,
      this.props.language
    );
  };

  deliveryDayHasPassed(deliverySlot) {
    const today = moment()
      .utc()
      .tz(this.props.timezone);

    const slotEnd = this.formatDate(deliverySlot);
    const isPastDeliveryDay = today.isAfter(slotEnd, 'day');

    return isPastDeliveryDay;
  }

  getCustomerVariables() {
    const {
      cutsMustard,
      deviceType,
      isFirstTimeShopper,
      storeId,
      shoppingMethod,
      c: config
    } = this.props;

    const opinionLabRefererUrl =
      shoppingMethod === ON_DEMAND
        ? config('opinionLab:refererUrls:ondemandwismo')
        : config('opinionLab:refererUrls:wismo');

    return {
      cutsMustard: String(cutsMustard),
      deviceType: deviceType,
      isFirstTimeShopper: String(isFirstTimeShopper),
      storeId: storeId,
      url: opinionLabRefererUrl
    };
  }

  render() {
    const {
      isAmendBasket,
      c: config,
      t: translate,
      user,
      welcomeTranslations,
      favoritesLink,
      topNotification,
      address,
      renderFavTooltip,
      showBookASlotButtons,
      isHorizontalTaxonomyEnabled
    } = this.props;

    const { order } = this.context;
    const orderAddress = order?.address || address;
    const showCancelledContextCard =
      order?.status === CANCELLED &&
      !this.deliveryDayHasPassed(order?.slot.end);
    const showAmendOrderContextCard =
      isAmendBasket && config('showHomeAmendOrderContextCard');

    let ContextCard;
    let starRating = null;
    const updatePaymentError = order?.updatePaymentError === true;

    const updatePaymentDetailsError = (
      <InPageMessaging
        className="main-banner--payment-cta-error-inpage"
        title={
          <Heading headingLevel="5">
            {translate(
              'context-cards:wismo-context-card-update-payment-inpage-error-title'
            )}
          </Heading>
        }
        variant="error"
      >
        <Text className="main-banner--payment-cta-error-inpage-body">
          {translate(
            'context-cards:wismo-context-card-update-payment-inpage-error-body'
          )}
        </Text>
      </InPageMessaging>
    );

    if (showCancelledContextCard) {
      analyticsBus().emit('UIEventBasicEvent', {
        action: 'now',
        type: 'slots',
        value: `contextcard:${CONTEXT_CARD_STATUSES.CANCELLED_ORDER}:impression`
      });
      starRating = (
        <WismoStarRating
          shoppingMethod={order.shoppingMethod}
          customerVariables={this.getCustomerVariables()}
          showWismoStepper={false}
        />
      );
    }

    if (showAmendOrderContextCard) {
      ContextCard = AmendOrderContextCard;
    } else {
      ContextCard = BookASlotContextCard;
    }

    let slotBookingContextCard = null;
    if (order && BOOK_ANOTHER_SLOT_ORDER_STATUSES.includes(order?.status)) {
      slotBookingContextCard = (
        <BookAnotherSlot shoppingMethod={order.shoppingMethod} />
      );
    }

    const classes = classnames('main-banner main-banner--signed-in', {
      'main-banner--overflow': !renderFavTooltip,
      'main-banner--hidden-taxonomy': isHorizontalTaxonomyEnabled
    });
    return (
      <div className={classes}>
        <div
          style={this.getMainBannerStyles()}
          className="hidden-small-medium"
        />
        <div className="main-banner--content main-banner--content-signed-in">
          {topNotification && <TopNotification data={topNotification} />}
          <div className="main-banner--greeting-wrapper">
            <UserGreeting
              user={user}
              welcomeTranslations={welcomeTranslations}
            />
          </div>
          <div className="main-banner--context-card">
            {showCancelledContextCard ? (
              <section className="context-card-book-a-slot">
                <CancelledContextCard
                  className="context-card-book-a-slot__cancelled"
                  starRating={starRating}
                />
              </section>
            ) : (
              <>
                {updatePaymentError && updatePaymentDetailsError}
                <ContextCard address={orderAddress} order={order} />
              </>
            )}
          </div>

          {this.shouldShowMultipleOrdersNotice() && <MultipleOrdersNotice />}

          {showBookASlotButtons && slotBookingContextCard}

          {this.shouldShowFavAndBrowseStoreCTA() && (
            <div className="main-banner--browse-wrapper">
              <BrowseStoreButton />
              <div className="browse-my-favorites" id="browse-my-favorites">
                <SimpleButton
                  onClick={this.favoritesLinkClickHandler}
                  href={favoritesLink}
                  buttonText={translate(
                    'home:new-user-banner.browse-favorites'
                  )}
                  type="secondary"
                  buttonClass="browse-favorites browse-favorites--button"
                  domRef={this.getDomRef}
                />
                {renderFavTooltip && this.getTooltipContent(this.targetRef)}
              </div>
            </div>
          )}
        </div>
      </div>
    );
  }
}
