import styled from 'styled-components';
import { spacing } from '@ddsweb/selectors';

export const StyledBookSlotButtonWrapper = styled.div`
  padding: ${spacing.sm};

  //override context card default style
  .context-card-book-a-slot > &:first-child {
    border-radius: 0;
  }
`;
