import React from 'react';
import PropTypes from 'prop-types';

const DeliveryDate = ({ date }) => (
  <p className="date-holder">
    <span className="date-holder--weekday">
      {date.format('ddd').toUpperCase()}
    </span>
    <span className="date-holder--day-number">{date.format('DD')}</span>
    <span className="date-holder--month-number">
      {date.format('MMM').toUpperCase()}
    </span>
  </p>
);

DeliveryDate.propTypes = {
  date: PropTypes.object.isRequired
};

export default DeliveryDate;
