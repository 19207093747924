import styled from 'styled-components';
import Button from '@beans/button';
import { media } from '@beans/foundation';
import { spacing, fontSize } from '@beans/selectors';

export const StyledButton = styled(Button)`
  border-radius: ${fontSize.md};
  ${media.belowTablet`
    margin-top: ${spacing.md};
    margin-bottom: ${spacing.xx};
   `};
`;

export const LoadingSpinner = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
`;
