import React from 'react';
import PropTypes from 'prop-types';

const Icon = ({ children }) => (
  <div className="context-card__icon-holder">
    <div className="context-card__icon-circle">{children}</div>
  </div>
);

Icon.propTypes = {
  children: PropTypes.node
};

export default Icon;
