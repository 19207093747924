import React from 'react';
import PropTypes from 'prop-types';
import { findMessageByIndex } from '../../../lib/propositional-message';
import Link from '../../link-check-spa';
import helpers from '../../../lib/decorators/helpers';
import SafelyOutputString from '#/components/safely-output-string';

const PropositionalMessage = ({ messageIndex, c: config }) => {
  const twitterTab = 'propositionalTab';
  const region = config('REGION');
  const message = findMessageByIndex(messageIndex, {
    target: twitterTab,
    title: '',
    region
  });

  return (
    <aside className="propositional-message">
      <div className="propositional-message--footer">
        <h5>{message.punchLine}</h5>
        {message.url && (
          <p>
            <Link
              to={message.url}
              target={twitterTab}
              rel="noreferrer noopener"
              title=""
            >
              {message.moreInfo}
            </Link>
          </p>
        )}
      </div>
      <blockquote className="propositional-message--content">
        <p>
          <SafelyOutputString>{message.html}</SafelyOutputString>
        </p>
        <p>
          <em>{message.signature}</em>
        </p>
      </blockquote>
    </aside>
  );
};

PropositionalMessage.propTypes = {
  c: PropTypes.func.isRequired,
  messageIndex: PropTypes.number,
  t: PropTypes.func.isRequired
};

PropositionalMessage.defaultProps = {
  messageIndex: 0
};

export default helpers(['t', 'c'])(PropositionalMessage);
