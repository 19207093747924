import React from 'react';
import PropTypes from 'prop-types';
import { connect } from '#/lib/render/connect-deep-compare';
import helpers from '#/lib/decorators/helpers';
import { openNavMenu } from '#/actions/ui-action-creators';
import Link from '#/components/link-check-spa';
import analyticsBus from '#/analytics/analyticsBus';

const BrowseStoreButton = props => {
  const text = props.t('home:new-user-banner.browse-the-store');
  const classes = 'browse-store';

  const clickHandler = evt => {
    evt.preventDefault();
    evt.stopPropagation();

    props.openNavMenu();
    analyticsBus().emit('UIEventBasicEvent', {
      action: 'now',
      type: 'home',
      value: 'browse the store'
    });
  };

  return (
    <div className={classes}>
      <Link
        className="browse-store--button browse-store--browse-button button button-secondary"
        to={props.l('/shop')}
        onClick={clickHandler}
      >
        {text}
      </Link>
    </div>
  );
};

BrowseStoreButton.propTypes = {
  l: PropTypes.func.isRequired,
  openNavMenu: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired
};

const mapDispatchToProps = {
  openNavMenu
};

export default connect(
  null,
  mapDispatchToProps
)(helpers(['l', 't'])(BrowseStoreButton));
