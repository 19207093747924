import styled from 'styled-components';
import { colors, spacing, fontSize } from '@ddsweb/selectors';
import { mediumMin } from '#/constants/viewports';

const Heading = styled.h1`
  font-size: ${spacing.xl};
  padding-top: ${spacing.xx};
  color: ${colors.inverse};
  @media (max-width: 767px) {
    padding: 6px 0; /* Right left padding on small needs to go, so that the text correctly aligns.*/
  }

  @media (min-width: ${mediumMin}) {
    padding-left: 0;
    font-size: ${fontSize.xl};
  }
`;

Heading.displayName = 'Heading';

const GreetingMessage = styled.span`
  display: block;

  // used for oop-1978 experiment
  .heading--in-line & {
    display: inline;
  }
`;

GreetingMessage.displayName = 'GreetingMessage';

const GreetingName = styled.span`
  padding-top: 6px;
  font-family: 'TESCO Modern', Arial, sans-serif;
  font-weight: bold;
  display: block;

  @media (min-width: ${mediumMin}) {
    padding-top: 11px;
  }

  // used for oop-1978 experiment
  .heading--in-line & {
    display: inline;
    padding: 0;

    &::before {
      content: ' ';
    }
  }
`;

GreetingName.displayName = 'GreetingName';

export { Heading, GreetingMessage, GreetingName };
