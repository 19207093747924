import React from 'react';
import helpers from '#/lib/decorators/helpers';
import { connect } from '#/lib/render/connect-deep-compare';
import { TFeatureFunc, TTranslateFunc } from '#/lib/records/helpers.defs';
import { getDefaultSlotsPath } from '#/reducers/slot';
import { TLanguageLink } from '#/components/slots/delivery-content-section/index.defs';
import { StyledBookAnotherSlot, StyledBookAnotherSlotWrapper } from './styled';
import analyticsBus from '#/analytics/analyticsBus';

export type TOwnProps = {
  defaultSlotsPath: string;
  f: TFeatureFunc;
  l: TLanguageLink;
  t: TTranslateFunc;
};

export type TBookAnotherSlotProps = {
  shoppingMethod: string;
};

type TStepsTemplateProps = TOwnProps & TBookAnotherSlotProps;

const mapStateToProps = (
  state: Store,
  { f }: TOwnProps,
): {
  defaultSlotsPath: string;
} => ({
  defaultSlotsPath: getDefaultSlotsPath(f)(state),
});

export const BookAnotherSlot: React.FC<TStepsTemplateProps> = (props: TStepsTemplateProps): JSX.Element => {
  const { shoppingMethod, t: translate, l: language, defaultSlotsPath } = props;

  return (
    <StyledBookAnotherSlotWrapper>
      <StyledBookAnotherSlot
        onClick={(): void => {
          analyticsBus().emit('UIEventBasicEvent', {
            action: 'delay',
            type: 'slots',
            value: `${shoppingMethod}:book another slot`,
          });
        }}
        to={language(defaultSlotsPath)}
        className="button button-secondary"
      >
        {translate('common:pages.book-another-slot')}
      </StyledBookAnotherSlot>
    </StyledBookAnotherSlotWrapper>
  );
};

export default helpers(['f', 'l', 't'])(connect(mapStateToProps)(BookAnotherSlot));
