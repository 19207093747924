import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { ResponsiveImage as RImg } from '../../cms-driven';
import Link from '../../link-check-spa';
import helpers from '../../../lib/decorators/helpers';

@helpers(['c', 't'])
export default class DeliveryDescription extends Component {
  static propTypes = {
    analyticsProps: PropTypes.object,
    c: PropTypes.func.isRequired,
    deliveryMeta: PropTypes.object.isRequired,
    onClick: PropTypes.func,
    t: PropTypes.func.isRequired
  };

  constructor(props) {
    super();
    this.clickHandler =
      typeof props.onClick === 'function'
        ? props.onClick.bind(null, props.analyticsProps)
        : undefined;
  }

  getLinkProps() {
    const { deliveryMeta, t: translate } = this.props;
    return {
      to: deliveryMeta['delivery-description.href'],
      onClick: this.clickHandler,
      title: translate('common:new-window'),
      target: '_blank',
      rel: 'noopener noreferrer'
    };
  }

  renderContent() {
    const { deliveryMeta, c: config } = this.props;

    const image = deliveryMeta['delivery-description.picture'];
    const pingImage = deliveryMeta['delivery-description.ping.picture'];
    const pictureBreakpoints = [
      {
        imageWidth: 200,
        screenWidth: 0
      },
      {
        imageWidth: 270,
        screenWidth: config('responsive:largeMin')
      },
      {
        imageWidth: 370,
        screenWidth: config('responsive:extraLargeMin')
      }
    ];
    const alternativeDesign = config('deliveryDescriptionAlternativeDesign');
    const doNotShowPicture = config('deliveryDescriptionDoNotShowPicture');

    const learnMore = deliveryMeta['delivery-description.learn-more'];
    return (
      <>
        {!doNotShowPicture && (
          <div className="delivery-desc--picture-wrapper">
            {image && (
              <RImg
                alt=""
                className="delivery-desc--picture"
                src={image}
                breakpoints={pictureBreakpoints}
                maxWidth={370}
              />
            )}
            {pingImage && (
              <RImg
                alt=""
                className="delivery-desc--picture"
                src={pingImage}
                maxWidth={370}
              />
            )}
          </div>
        )}
        <div className="delivery-desc--message">
          <div className="delivery-desc--line1 delivery-desc--message-bold">
            {deliveryMeta['delivery-description.delivered-by-tesco']}
          </div>
          <div className="delivery-desc--line2">
            <span>{deliveryMeta['delivery-description.line1.next-day']}</span>
            <span>{deliveryMeta['delivery-description.for-little']}</span>
            <span className="delivery-desc--message-red">
              {deliveryMeta['min-price']}
              <span className="currency" />
            </span>
          </div>
          <div className="delivery-desc--line3">
            <span>
              {deliveryMeta['delivery-description.line2.click-collect']}
            </span>
            {deliveryMeta['delivery-description.line2.for']}
            <span className="delivery-desc--message-red">
              {deliveryMeta['delivery-description.line2.free']}
            </span>
          </div>
          {!alternativeDesign ? (
            <div className="delivery-desc--line4">{learnMore}</div>
          ) : (
            <Link className="delivery-desc--line4" {...this.getLinkProps()}>
              {learnMore}
            </Link>
          )}
        </div>
      </>
    );
  }

  render() {
    const { c: config } = this.props;

    const alternativeDesign = config('deliveryDescriptionAlternativeDesign');

    return (
      <>
        {!alternativeDesign ? (
          <Link className={'delivery-desc'} {...this.getLinkProps()}>
            {this.renderContent()}
          </Link>
        ) : (
          <div
            className={classnames('delivery-desc', 'delivery-desc-alternative')}
          >
            {this.renderContent()}
          </div>
        )}
      </>
    );
  }
}
