import { getFeatureVariableStringFromState } from '#/lib/optimizely-manager';
import { shouldDisplay3StepsConfig } from '../constants';

export const shouldDisplay3Steps = state => {
  const { useCustomerId, featureKey, variationKey } = shouldDisplay3StepsConfig;
  const attributes = {};
  const isEnabled = getFeatureVariableStringFromState(
    state,
    featureKey,
    variationKey,
    {
      useCustomerId,
      attributes
    }
  );
  return isEnabled === 'true';
};
