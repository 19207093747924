import React from 'react';
import helpers from '#/lib/decorators/helpers';
import DeliveryHeading from '#/components/home/context-cards/wismo/shared/delivery-heading';
import DeliveryStatus from '#/components/home/context-cards/wismo/shared/delivery-status';
import { emitWismoEvent } from '#/components/home/context-cards/wismo/shared/analytics';
import { SUPPORTED_WISMO_TRACKING_STATUS } from '#/constants/order-statuses';
import { SlotTimeInline } from '#/components/home/context-cards/wismo/shared/styled';
import { DELIVERY } from '#/constants/shopping-methods';
import DriverOnMapLayout from '../../shared/driver-on-map/layout';
import { useOrderContext } from '#/context/order-context-provider';

type Props = {
  t: (key: string, options?: object) => string;
  starRating: React.Component;
  display3Steps: boolean;
};

export const PreparingOrder: React.FC<Props> = ({ t: translate, starRating, display3Steps }: Props) => {
  const { order } = useOrderContext();
  const orderNo = order.orderNo;
  const postcode = order.address.postcode;
  const slotStart = order.slotStart;
  const slotEnd = order.slotEnd;
  const deliveryHeading = <DeliveryHeading postcode={postcode} />;
  const primaryDeliveryStatus = (
    <>
      {translate('context-cards:wismo-stepper-today')}
      {', '}
      <SlotTimeInline start={slotStart} end={slotEnd} alt={true} tagName="span" />
    </>
  );
  const secondaryDeliveryStatus = translate('context-cards:wismo-stepper-check-update');
  const deliveryStatus = (
    <DeliveryStatus primaryDeliveryStatus={primaryDeliveryStatus} secondaryDeliveryStatus={secondaryDeliveryStatus} />
  );

  React.useEffect(
    () => emitWismoEvent(DELIVERY, { status: SUPPORTED_WISMO_TRACKING_STATUS.PREPARING_ORDER }, orderNo),
    [],
  );

  return (
    <DriverOnMapLayout
      deliveryHeading={deliveryHeading}
      deliveryStatus={deliveryStatus}
      starRating={starRating}
      display3Steps={display3Steps}
    />
  );
};

export default helpers(['t'])(PreparingOrder);
