import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { CAROUSEL_FAVORITES_IDENTIFIER } from '#/analytics/constants';
import { CAROUSEL_TILE } from '#/constants/analytics';
import Carousel from '#/components/carousel';
import { BEANS } from '#/constants/tile-types';
import helpers from '#/lib/decorators/helpers';
import ProductTile from '#/components/products/product-tile';
import SectionDividerWidget from '#/components/home/section-divider-widget';
import WidgetContainer from '#/components/widget-container';
import { FLEXI } from '#/constants/tile-types';
import CarouselTile from '#/components/product-tiles/vertical-tile/variant/carousel-tile';

@helpers(['c', 't', 'f'])
export default class FavoritesWidget extends PureComponent {
  static propTypes = {
    favoritesItems: PropTypes.object.isRequired,
    isAmendBasket: PropTypes.bool.isRequired,
    t: PropTypes.func
  };

  render() {
    let gridPos = 0;
    const {
      favoritesItems,
      isAmendBasket,
      productTileType,
      t: translate,
      c: config,
      f: feature
    } = this.props;

    const carouselContent = [];
    if (productTileType === BEANS) {
      favoritesItems.forEach((item, id) => {
        const productTile = (
          <CarouselTile
            config={config}
            feature={feature}
            identifier={CAROUSEL_FAVORITES_IDENTIFIER}
            item={item}
            itemIndex={gridPos}
            key={id}
            showRestOfShelfLink
            showWriteAReviewLink
            tile={CAROUSEL_TILE}
            translate={translate}
          />
        );

        gridPos++;

        carouselContent.push(productTile);
      });
    } else {
      favoritesItems.forEach((item, id) => {
        const productTile = (
          <ProductTile
            key={id}
            type={FLEXI}
            identifier={CAROUSEL_FAVORITES_IDENTIFIER}
            isAmendBasket={isAmendBasket}
            item={item}
            itemIndex={gridPos}
            listData={{}}
            tile={CAROUSEL_TILE}
          />
        );

        gridPos++;

        carouselContent.push(productTile);
      });
    }

    return (
      <div className="favorites-widget">
        <WidgetContainer widgetName="section-message">
          <div id="favorites-widget--section-divider">
            <SectionDividerWidget
              title={this.props.t('home:items-from-favorites')}
              link={'/favorites'}
            />
          </div>
        </WidgetContainer>
        <Carousel profilerKey="homepage_favourites">{carouselContent}</Carousel>
      </div>
    );
  }
}
