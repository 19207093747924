import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import helpers from '#/lib/decorators/helpers';
import { connect } from '#/lib/render/connect-deep-compare';
import SignInRegister from '#/components/home/signin-register';
import BrowseStoreButton from '#/components/shared/browse-store';
import UserGreeting from '#/components/home/user-greeting';
import DeliveryDescription from '#/components/home/delivery-description';
import PropositionalMessage from '#/components/home/propositional-message';
import {
  NAV_ITEM_HEIGHT,
  NAV_LIST_COLLAPSED_VERTICAL_PADDING
} from '#/constants/ui';
import { getNavList } from '#/reducers/taxonomy';
import { getIsMobile } from '#/reducers/app';
import { getDcsHomePageUnorderedComponentByID } from '#/selectors/home-page';
import { TOP_NOTIFICATION } from './constants';
import TopNotification from './top-notification';

const mapStateToProps = (state, ownProps) => ({
  isMobile: getIsMobile(state),
  navItems: getNavList(state),
  topNotification: getDcsHomePageUnorderedComponentByID(TOP_NOTIFICATION)(
    state
  ),
  isHorizontalTaxonomyEnabled: ownProps.c('enableHorizontalTaxanomy')
});

@helpers(['c'])
@connect(mapStateToProps)
export default class BannerSignedOut extends React.Component {
  static propTypes = {
    analyticsProps: PropTypes.object,
    c: PropTypes.func.isRequired,
    deliveryMeta: PropTypes.object.isRequired,
    isMobile: PropTypes.bool.isRequired,
    navItems: PropTypes.array.isRequired,
    propositionalMessageIndex: PropTypes.number.isRequired,
    propositionClickHandler: PropTypes.func,
    topNotification: PropTypes.object,
    user: PropTypes.shape({
      displayName: PropTypes.string,
      isAuthenticated: PropTypes.bool.isRequired,
      isRegistered: PropTypes.bool.isRequired
    }).isRequired,
    welcomeTranslations: PropTypes.object.isRequired
  };

  getMainBannerStyles() {
    return {
      minHeight: `${this.props.navItems.length * NAV_ITEM_HEIGHT +
        NAV_LIST_COLLAPSED_VERTICAL_PADDING}px`
    };
  }

  render() {
    const {
      user,
      welcomeTranslations,
      topNotification,
      isHorizontalTaxonomyEnabled
    } = this.props;
    const classes = classnames('main-banner main-banner--signed-out', {
      'main-banner--hidden-taxonomy': isHorizontalTaxonomyEnabled
    });
    return (
      <div className={classes}>
        <div
          style={this.getMainBannerStyles()}
          className="hidden-small-medium"
        />
        <div className="main-banner--content main-banner--content-signed-out">
          {topNotification && <TopNotification data={topNotification} />}
          <div className="main-banner--greeting-wrapper">
            <UserGreeting
              user={user}
              welcomeTranslations={welcomeTranslations}
            />
          </div>
          <div className="main-banner--row-1">
            <div className="main-banner--sign-in-wrapper">
              <SignInRegister />
            </div>
            <div className="main-banner--delivery-wrapper">
              {this.getAdvertPanel()}
            </div>
            <div className="main-banner--browse-wrapper">
              <BrowseStoreButton />
            </div>
          </div>
        </div>
      </div>
    );
  }

  getAdvertPanel() {
    const {
      c: config,
      propositionalMessageIndex,
      analyticsProps,
      deliveryMeta,
      propositionClickHandler
    } = this.props;

    if (config('propositionalMessageEnabled')) {
      return <PropositionalMessage messageIndex={propositionalMessageIndex} />;
    } else {
      return (
        <DeliveryDescription
          analyticsProps={analyticsProps}
          deliveryMeta={deliveryMeta}
          onClick={propositionClickHandler}
        />
      );
    }
  }
}
