import React from 'react';
import { Moment } from 'moment';
import { compose } from 'react-recompose';
import { connect } from '#/lib/render/connect-deep-compare';
import helpers from '#/lib/decorators/helpers';
import DeliveryHeading from '#/components/home/context-cards/wismo/shared/delivery-heading';
import DeliveryStatus from '#/components/home/context-cards/wismo/shared/delivery-status';
import BookSlotLink from '#/components/home/context-cards/wismo/shared/book-slot-link';
import { emitWismoEvent } from '#/components/home/context-cards/wismo/shared/analytics';
import { SUPPORTED_WISMO_TRACKING_STATUS } from '#/constants/order-statuses';
import { DELIVERY } from '#/constants/shopping-methods';
import DriverOnMapLayout from '../../shared/driver-on-map/layout';
import { useOrderContext } from '#/context/order-context-provider';
import { getIsAmongSlotBookingVariants } from '#/experiments/oop-1978/selectors';

type TProps = {
  t: (key: string, options?: object) => string;
  orderNo: string;
  postcode: string;
  slotStart: Moment;
  slotEnd: Moment;
  starRating: React.Component;
  display3Steps: boolean;
};
type TOwnState = {
  showBookASlotButton: boolean;
};

const mapStateToProps = (state: Store): TOwnState => ({
  showBookASlotButton: !getIsAmongSlotBookingVariants(state),
});

type TDeliveredProps = TOwnState & TProps;

export const Delivered: React.FC<TDeliveredProps> = ({
  t: translate,
  starRating,
  display3Steps,
  showBookASlotButton,
}: TDeliveredProps) => {
  const { order } = useOrderContext();
  const orderNo = order.orderNo;
  const postcode = order.address?.postcode;
  const slotStart = order.slotStart;
  const slotEnd = order.slotEnd;
  const deliveryHeading = <DeliveryHeading postcode={postcode} slotStart={slotStart} slotEnd={slotEnd} />;

  const primaryDeliveryStatus = translate('context-cards:wismo-stepper-delivered');

  const secondaryDeliveryStatus = (
    <>
      {translate('context-cards:wismo-stepper-thank-you')}
      {showBookASlotButton && <BookSlotLink shoppingMethod={DELIVERY} />}
    </>
  );

  const deliveryStatus = (
    <DeliveryStatus primaryDeliveryStatus={primaryDeliveryStatus} secondaryDeliveryStatus={secondaryDeliveryStatus} />
  );

  React.useEffect(() => emitWismoEvent(DELIVERY, { status: SUPPORTED_WISMO_TRACKING_STATUS.DELIVERED }, orderNo), []);

  return (
    <DriverOnMapLayout
      deliveryHeading={deliveryHeading}
      deliveryStatus={deliveryStatus}
      starRating={starRating}
      display3Steps={display3Steps}
    />
  );
};

const enhance: Function = compose(connect(mapStateToProps), helpers(['t']));

export default enhance(Delivered);
