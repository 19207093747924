import styled from 'styled-components';
import Icon from '@ddsweb/icon';
import { colors } from '@ddsweb/selectors';
import { WISMO_PUNCTUALITY } from '#/constants/order-statuses';

export const PunctualityIcon = styled(Icon)`
  margin-right: 6px;

  &&& circle {
    fill: ${props =>
      props.punctuality === WISMO_PUNCTUALITY.DELAYED && props.stops > 0 ? colors.warning : colors.info};
  }
`;
PunctualityIcon.displayName = 'HomeDeliveryWismoStepperPunctualityIcon';
