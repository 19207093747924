import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import Icon from '@ddsweb/icon';
import { connect } from '#/lib/render/connect-deep-compare';
import helpers from '#/lib/decorators/helpers';
import { getTimezoneAndLocaleAppliedMoment } from '#/lib/date-helpers';
import { isOnDemandShoppingMethod } from '#/lib/shopping-method-util';
import { getDistanceBetweenCoordinates } from '#/lib/map-helper';
import {
  getLanguage,
  getTimezone,
  getCutsMustard,
  getDeviceType,
  getApigeeMangoEndpoint,
  getApigeeMangoApiKey,
  getAppRegion
} from '#/reducers/app';
import { getIsFirstTimeShopper } from '#/reducers/user';
import MainText from '#/components/home/context-cards/main-text';
import IconRight from '#/components/home/context-cards/icon';
import DeliverySlotTime from '#/components/home/context-cards/delivery-slot-time';
import WismoStarRating from '#/components/home/context-cards/wismo/shared/wismo-star-rating';
import analyticsBus from '#/analytics/analyticsBus';
import { basicEvent } from '#/analytics/types/basic';
import { NOW, CONTEXT_CARD_IMPRESSION } from '#/analytics/constants';
import { ON_DEMAND } from '#/constants/shopping-methods';
import {
  WISMO_PUNCTUALITY,
  SUPPORTED_WISMO_TRACKING_STATUS
} from '#/constants/order-statuses';
import HomeDeliveryWismoStepper from './components';
import OnDemandWismoCard from './on-demand';
import getDeliveryTrackingInfo from '#/lib/requests/get-delivery-tracking';
import { getAtrc } from '#/reducers/user';
import { logCustomEvent } from '#/lib/apm';
import { shouldDisplay3Steps } from '#/experiments/oop-1628/selectors';
import { OrderContext } from '../../../../context/order-context-provider';

const mapStateToProps = state => ({
  language: getLanguage(state),
  timezone: getTimezone(state),
  cutsMustard: getCutsMustard(state),
  deviceType: getDeviceType(state),
  isFirstTimeShopper: getIsFirstTimeShopper(state),
  endpoint: getApigeeMangoEndpoint(state),
  apiKey: getApigeeMangoApiKey(state),
  region: getAppRegion(state),
  atrc: getAtrc(state),
  display3Steps: shouldDisplay3Steps(state)
});
export class WismoContextCard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pollingFrequency: undefined
    };
  }

  static contextType = OrderContext;

  updateDeliveryTrackingInfo = async () => {
    const { order, setOrder } = this.context;
    const deliveryTrackingRequestData = {
      ...this.props,
      wismoSlot: order.wismoSlot
    };
    let mocksEnabled = false;

    if (typeof window !== 'undefined') {
      const wismoStatus = window?.sessionStorage?.getItem('wismo-test-status');
      if (wismoStatus) {
        mocksEnabled = JSON.parse(wismoStatus);
      }
    }
    //avoid making delivery tracking call if no tripId
    if (
      !(
        deliveryTrackingRequestData.wismoSlot?.tripTrackingId && !mocksEnabled
      ) ||
      this.props.region !== 'UK'
    ) {
      return;
    }
    const response = await getDeliveryTrackingInfo(deliveryTrackingRequestData);
    if (response) {
      const newOrder = {
        ...order,
        wismoSlot: response.wismoSlot
      };
      setOrder(newOrder);
      this.setState({
        pollingFrequency: response.pollingFrequency
      });
    }
  };

  formatDate = date =>
    getTimezoneAndLocaleAppliedMoment(
      date,
      this.props.timezone,
      this.props.language
    );

  componentDidMount = () => {
    const { orderNo, slot, showWismoStepper } = this.props;
    if (!showWismoStepper) {
      const { wismoSlot } = this.context.order;
      let wismoSlotStart = '';
      let wismoSlotEnd = '';
      let value = 'wismo';

      if (wismoSlot?.deliveryWindow) {
        wismoSlotStart = wismoSlot.deliveryWindow.start;
        wismoSlotEnd = wismoSlot.deliveryWindow.end;
      }

      if (wismoSlot?.currentTrackingStatus?.punctuality) {
        value = `wismo ${wismoSlot.currentTrackingStatus.punctuality.toLowerCase()}`;
      }

      basicEvent(analyticsBus, {
        type: CONTEXT_CARD_IMPRESSION,
        value,
        action: NOW,
        orderNo,
        slotTime: `${slot.start} - ${slot.end}`,
        wismoTime:
          wismoSlotStart && wismoSlotEnd
            ? `${wismoSlotStart} - ${wismoSlotEnd}`
            : ''
      });
    }
    // call this first time to get the tracking details and set that into the state
    this.updateDeliveryTrackingInfo();
  };

  startPollingForTrackingInfo = () => {
    const { pollingFrequency } = this.state;
    const { wismoSlot } = this.context.order;
    const {
      currentTrackingStatus: { status }
    } = wismoSlot;

    if (pollingFrequency) {
      if (
        [SUPPORTED_WISMO_TRACKING_STATUS.DELIVERY_SCHEDULED].includes(
          wismoSlot?.currentTrackingStatus?.status
        ) &&
        (!wismoSlot?.currentTrackingStatus?.geoLocation ||
          !wismoSlot?.destination?.geoLocation)
      ) {
        return;
      }

      if (
        status !== SUPPORTED_WISMO_TRACKING_STATUS.DELIVERED &&
        status !== SUPPORTED_WISMO_TRACKING_STATUS.CANCELLED
      ) {
        this.timeoutID = setTimeout(
          this.updateDeliveryTrackingInfo,
          Math.max(pollingFrequency, 30) * 1000
        );
      }
    }
  };

  stopPollingForTrackingInfo = () => {
    if (this.timeoutID) {
      clearTimeout(this.timeoutID);
    }
  };

  componentDidUpdate() {
    this.stopPollingForTrackingInfo();
    this.startPollingForTrackingInfo();
  }

  componentWillUnmount = () => {
    this.stopPollingForTrackingInfo();
  };

  getDeliveryData() {
    const { wismoSlot } = this.context.order;

    let deliveryStart = '';
    let deliveryEnd = '';
    let isWarning = false;

    if (wismoSlot?.deliveryWindow && wismoSlot?.currentTrackingStatus) {
      let status = wismoSlot.currentTrackingStatus.punctuality;
      deliveryStart = this.formatDate(wismoSlot.deliveryWindow.start);
      deliveryEnd = this.formatDate(wismoSlot.deliveryWindow.end);
      isWarning = status === WISMO_PUNCTUALITY.DELAYED;
    }

    return {
      deliveryStart,
      deliveryEnd,
      isWarning
    };
  }

  shouldShowMap() {
    const { pollingFrequency } = this.state;
    const { wismoSlot } = this.context.order;

    const stopsWhenMapHidden = 4;
    const validDistanceBetweenCoordinatesInMiles = 2;

    const distanceBetweenGeoCoordinates = getDistanceBetweenCoordinates(
      wismoSlot?.currentTrackingStatus?.geoLocation,
      wismoSlot?.destination?.geoLocation
    );

    const shouldShowMap =
      distanceBetweenGeoCoordinates <= validDistanceBetweenCoordinatesInMiles &&
      wismoSlot?.currentTrackingStatus?.stopsUntilDelivery <=
        stopsWhenMapHidden &&
      wismoSlot?.destination?.geoLocation &&
      wismoSlot?.currentTrackingStatus?.geoLocation &&
      pollingFrequency &&
      [SUPPORTED_WISMO_TRACKING_STATUS.DELIVERY_SCHEDULED].includes(
        wismoSlot?.currentTrackingStatus?.status
      );

    logCustomEvent('PostOrderTracking', {
      stops_until_delivery:
        wismoSlot?.currentTrackingStatus?.stopsUntilDelivery,
      has_home_geo_location: !!wismoSlot?.destination?.geoLocation,
      has_driver_geo_location: !!wismoSlot?.currentTrackingStatus?.geoLocation,
      order_status: wismoSlot?.currentTrackingStatus?.status,
      polling_frequency: pollingFrequency,
      is_showing_map: !!shouldShowMap,
      shopping_method: this.props.shoppingMethod,
      trip_tracking_id: wismoSlot?.tripTrackingId,
      distance_between_geo_coordinates: distanceBetweenGeoCoordinates
    });

    return !!shouldShowMap;
  }

  getCustomerVariables() {
    const {
      cutsMustard,
      deviceType,
      isFirstTimeShopper,
      locationUuid,
      shoppingMethod,
      c: config
    } = this.props;

    const opinionLabRefererUrl =
      shoppingMethod === ON_DEMAND
        ? config('opinionLab:refererUrls:ondemandwismo')
        : config('opinionLab:refererUrls:wismo');

    return {
      cutsMustard: String(cutsMustard),
      deviceType: deviceType,
      isFirstTimeShopper: String(isFirstTimeShopper),
      locationUuid: locationUuid,
      url: opinionLabRefererUrl
    };
  }

  getWismoCard() {
    const { deliveryStart, deliveryEnd, isWarning } = this.getDeliveryData();
    const {
      t: translate,
      showWismoStepper,
      shoppingMethod,
      address,
      orderNo,
      slot,
      display3Steps
    } = this.props;
    const { wismoSlot } = this.context.order;
    const { status, geoLocation } = wismoSlot.currentTrackingStatus;

    const { postcode, name: addressName } = address;
    const slotStart = this.formatDate(slot.start);
    const slotEnd = this.formatDate(slot.end);

    const starRating = (
      <WismoStarRating
        shoppingMethod={shoppingMethod}
        currentTrackingStatus={wismoSlot.currentTrackingStatus}
        customerVariables={this.getCustomerVariables()}
        showWismoStepper={showWismoStepper}
      />
    );
    // To remove 'showWismoStepper' flag once HomeDelivery WismoStepper is productionized

    if (showWismoStepper) {
      if (isOnDemandShoppingMethod(shoppingMethod)) {
        return (
          <OnDemandWismoCard
            orderNo={orderNo}
            addressName={addressName}
            postcode={postcode}
            status={status}
            deliveryStart={deliveryStart}
            deliveryEnd={deliveryEnd}
            slotStart={slotStart}
            shoppingMethod={shoppingMethod}
            slotEnd={slotEnd}
            starRating={starRating}
            showMap={this.shouldShowMap()}
            homeGeoLocation={wismoSlot?.destination?.geoLocation}
            driverGeoLocation={geoLocation}
          />
        );
      }

      return (
        <>
          <HomeDeliveryWismoStepper
            starRating={starRating}
            showMap={this.shouldShowMap()}
            display3Steps={display3Steps}
          />
        </>
      );
    }

    return (
      <>
        <div
          className={classnames('context-card-wismo__top', {
            'context-card-wismo--info': !isWarning
          })}
        >
          <IconRight>
            <div className="context-card-wismo__icon">
              {translate('common:today')}
            </div>
          </IconRight>
          {isWarning ? (
            <span className="icon-api-warning"> </span>
          ) : (
            <div className="context-card-wismo__icon-info">
              <Icon graphic="info" inverse={true} />
            </div>
          )}
          <div
            className={classnames('context-card-wismo__title', {
              'context-card-wismo__title-info': !isWarning
            })}
            data-auto="context-card-wismo-title"
          >
            <h2
              className="context-card-wismo__title-text"
              data-auto="context-card-wismo-title-text"
            >
              {translate('context-cards:wismo-context-card-title')}
            </h2>
            <DeliverySlotTime
              start={deliveryStart}
              end={deliveryEnd}
              alt={true}
            />
            {isWarning && (
              <h2 data-auto="context-card-wismo-update-message">
                {translate('context-cards:wismo-context-card-update-message')}
              </h2>
            )}
          </div>
        </div>
        <div
          className="context-card-wismo__main"
          data-auto="context-card-wismo-main"
        >
          <MainText
            address={this.props.address}
            start={deliveryStart}
            end={deliveryEnd}
            guidePrice={this.props.guidePrice}
            alt={true}
            showTime={false}
          />
        </div>
        <div
          className="context-card-wismo__star-rating"
          data-auto="context-card-wismo-star-rating"
        >
          {starRating}
        </div>
      </>
    );
  }

  render() {
    return (
      <div
        className="context-card-book-a-slot-order-due"
        data-auto="context-card-wismo"
      >
        {this.getWismoCard()}
      </div>
    );
  }
}

WismoContextCard.propTypes = {
  address: PropTypes.object.isRequired,
  guidePrice: PropTypes.number.isRequired,
  language: PropTypes.string.isRequired,
  locationUuid: PropTypes.string,
  orderNo: PropTypes.string,
  shoppingMethod: PropTypes.string.isRequired,
  showWismoStepper: PropTypes.bool,
  slot: PropTypes.shape({
    end: PropTypes.string,
    start: PropTypes.string
  }),
  t: PropTypes.func,
  timezone: PropTypes.string,
  wismoSlot: PropTypes.shape({
    deliveryWindow: PropTypes.shape({
      end: PropTypes.string.isRequired,
      start: PropTypes.string.isRequired
    }),
    currentTrackingStatus: PropTypes.shape({
      punctuality: PropTypes.oneOf([
        WISMO_PUNCTUALITY.EARLY,
        WISMO_PUNCTUALITY.DELAYED,
        WISMO_PUNCTUALITY.ON_TIME
      ]),
      status: PropTypes.oneOf([
        SUPPORTED_WISMO_TRACKING_STATUS.PREPARING_ORDER,
        SUPPORTED_WISMO_TRACKING_STATUS.DELIVERY_SCHEDULED,
        SUPPORTED_WISMO_TRACKING_STATUS.ARRIVED_AT_CUSTOMER_LOCATION,
        SUPPORTED_WISMO_TRACKING_STATUS.DELIVERED,
        SUPPORTED_WISMO_TRACKING_STATUS.NOT_AVAILABLE,
        SUPPORTED_WISMO_TRACKING_STATUS.CANCELLED
      ]),
      stopsUntilDelivery: PropTypes.number
    })
  })
};

WismoContextCard.defaultProps = {
  showWismoStepper: false
};

export default connect(mapStateToProps)(helpers(['t', 'c'])(WismoContextCard));
