import React from 'react';
import analyticsBus from '#/analytics/analyticsBus';
import { basicEvent } from '#/analytics/types/basic';
import { CONTEXT_CARD, DELAY, BOOK_ANOTHER_SLOT } from '#/analytics/constants';
import ChevronLink from '../../link/chevron-link';
import helpers from '#/lib/decorators/helpers';
import { connect } from '#/lib/render/connect-deep-compare';
import { compose } from 'react-recompose';
import { getDefaultSlotsPath } from '#/reducers/slot';

type TOwnProps = {
  defaultSlotsPath: string;
  f: (featureKey?: string | string[] | undefined) => string;
  l: Function;
  t: (key: string, data?: object) => string;
};

const mapStateToProps = (
  state: Store,
  { f }: TOwnProps,
): {
  defaultSlotsPath: string;
} => ({
  defaultSlotsPath: getDefaultSlotsPath(f)(state),
});
const connector = connect(mapStateToProps);
type TProps = TOwnProps & ReturnType<typeof mapStateToProps>;

const emitBookASlotAnalyticsEvent = (): void => {
  basicEvent(analyticsBus, {
    type: CONTEXT_CARD,
    value: BOOK_ANOTHER_SLOT,
    action: DELAY,
  });
};

const MultipleOrdersNotice: React.FC<TProps> = (props: TProps) => {
  const bookASlotHref = props.l(props.defaultSlotsPath);
  const bookAnotherSlotText = props.t('common:pages.book-another-slot');
  return (
    <div className="multiple-orders-notice">
      {props.t('common:pages.multiple-order-notice')}
      &nbsp;
      <ChevronLink to={bookASlotHref} className="multiple-orders-notice__link" onClick={emitBookASlotAnalyticsEvent}>
        {bookAnotherSlotText}
      </ChevronLink>
    </div>
  );
};
const enhance = compose<TProps, TOwnProps>(helpers(['f', 'l', 't']), connector);

export default enhance(MultipleOrdersNotice);
