import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from '#/lib/render/connect-deep-compare';
import helpers from '#/lib/decorators/helpers';
import MainText from './main-text';
import { DELIVERY, COLLECTION, ON_DEMAND } from '#/constants/shopping-methods';
import styled from 'styled-components';
import { Heading } from '@ddsweb/heading';
import { spacing } from '@ddsweb/selectors';
import { getTimezoneAndLocaleAppliedMoment } from '#/lib/date-helpers';
import analyticsBus from '#/analytics/analyticsBus';
import { getLanguage, getTimezone } from '#/reducers/app';
import { SHOPPING_METHOD_PROP_TYPE } from '#/components/slots/prop-types';
import ImHereButton from '#/components/home/context-cards/im-here-button';
import { getImHereStatus, imHereStatusValue } from './im-here-button/selector';
import InteractiveStarRatingBasic from '#/components/star-rating/interactive-star-rating/starRatingBasic';

const StyledStarRatingContainer = styled.div`
  margin: ${spacing.sm} 0px;
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const mapStateToProps = (state, ownProps) => ({
  language: getLanguage(state),
  timezone: getTimezone(state),
  imHereStatus: getImHereStatus(
    state,
    ownProps.locationUuid,
    ownProps.orderNo,
    ownProps.shoppingMethod,
    ownProps.slot
  )
});

@connect(mapStateToProps)
@helpers(['t', 'c'])
class OrderDueContextCard extends Component {
  formatDate = date =>
    getTimezoneAndLocaleAppliedMoment(
      date,
      this.props.timezone,
      this.props.language
    );

  componentDidMount() {
    const { orderId } = this.props;
    const { start, end } = this.props.slot;

    const payload = {
      type: 'context card impression',
      value: 'active order',
      action: 'now',
      orderId,
      slotTime: `${start} - ${end}`
    };

    analyticsBus().emit('UIEventBasicEvent', payload);
  }

  getStarRatingProps() {
    const { c: config, t: translate } = this.props;
    const textRoot =
      'home:book-a-slot.order-due.collection.imHere.star-rating-feedback';
    const legendText = translate(`${textRoot}.legend-text`);
    const legendAriaLabel = translate(`${textRoot}.legend-aria-label`);
    const refererUrl = config('opinionLab:refererUrls:imHereButton');

    return {
      legendAriaLabel,
      legendText,
      refererUrl,
      noRatingCopy: legendAriaLabel
    };
  }

  render() {
    const {
      slot,
      t: translate,
      shoppingMethod,
      address,
      guidePrice,
      c: config,
      orderNo,
      locationUuid,
      imHereStatus
    } = this.props;

    const titleKeys = {
      [ON_DEMAND]: 'home:book-a-slot.order-due.delivery.title',
      [DELIVERY]: 'home:book-a-slot.order-due.delivery.title',
      [COLLECTION]: 'home:book-a-slot.order-due.collection.title'
    };

    const showImHereButtonSurvey = config('showImHereButtonSurvey');
    const start = this.formatDate(slot.start);
    const end = this.formatDate(slot.end);

    let contextCardTitle = translate(titleKeys[shoppingMethod]);
    let starRatingProps = null;

    if (imHereStatus === imHereStatusValue.RECEIVED) {
      contextCardTitle = translate(
        'home:book-a-slot.order-due.collection.received-title'
      );
    }

    if (showImHereButtonSurvey) {
      starRatingProps = this.getStarRatingProps();
    }

    return (
      <div
        className="context-card-book-a-slot-order-due"
        data-auto="context-card-book-a-slot-order-due"
        role="status"
      >
        <div className="context-card-book-a-slot__top">
          <h2
            className="context-card-book-a-slot__title"
            data-auto="context-card-book-a-slot-order-due-title"
          >
            {contextCardTitle}
          </h2>
          <MainText
            address={address}
            displayAsGuidePrice={config('displayAsGuidePrice')}
            showDeliverySlotWindow={true}
            start={start}
            end={end}
            guidePrice={guidePrice}
            alt={true}
          />
          {imHereStatus === imHereStatusValue.AVAILABLE && (
            <ImHereButton
              orderNo={orderNo}
              locationUuid={locationUuid}
              slotStart={slot.start}
              slotEnd={slot.end}
            />
          )}
          {showImHereButtonSurvey &&
            imHereStatus === imHereStatusValue.RECEIVED && (
              <StyledStarRatingContainer>
                <Heading
                  aria-label={starRatingProps.legendAriaLabel}
                  visualSize="headline4"
                >
                  {starRatingProps.legendText}
                </Heading>
                <InteractiveStarRatingBasic {...starRatingProps} />
              </StyledStarRatingContainer>
            )}
        </div>
      </div>
    );
  }
}

OrderDueContextCard.propTypes = {
  address: PropTypes.object.isRequired,
  guidePrice: PropTypes.number.isRequired,
  locationUuid: PropTypes.string.isRequired,
  orderId: PropTypes.string.isRequired,
  orderNo: PropTypes.string.isRequired,
  shoppingMethod: SHOPPING_METHOD_PROP_TYPE.isRequired,
  slot: PropTypes.shape({
    end: PropTypes.string,
    start: PropTypes.string
  }).isRequired
};

export default OrderDueContextCard;
