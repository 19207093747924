import analyticsBus from '#/analytics/analyticsBus';
import { basicEvent } from '#/analytics/types/basic';
import { WISMO_PUNCTUALITY, SUPPORTED_WISMO_TRACKING_STATUS } from '#/constants/order-statuses';
import { ShoppingMethod } from '#/constants/shopping-methods';
import {
  NOW,
  DELAY,
  AMEND_CUT_OFF,
  ACTIVE,
  ACTIVE_LATE,
  ACTIVE_NEXT_STOP,
  ACTIVE_ARRIVED,
  ACTIVE_ARRIVED_EARLY,
  ACTIVE_DELIVERED,
  NOT_AVAILABLE,
  CANCELLED,
  BOOK_ANOTHER_SLOT,
  WISMO_EVENT_TYPE,
  NOT_AVAILABLE_ERROR,
} from '#/analytics/constants';
import { emitViewErrorDataEvent } from '#/analytics/types/error-data';

type TCurrentTrackingStatus = {
  status: string;
  punctuality?: string;
  stopsUntilDelivery?: number;
};

function getEventValueFromTrackingStatus(currentTrackingStatus: TCurrentTrackingStatus) {
  let value;
  const { status, punctuality, stopsUntilDelivery } = currentTrackingStatus;

  switch (status) {
    case SUPPORTED_WISMO_TRACKING_STATUS.PREPARING_ORDER:
      value = AMEND_CUT_OFF;
      break;

    case SUPPORTED_WISMO_TRACKING_STATUS.DELIVERY_SCHEDULED: {
      if (stopsUntilDelivery === 0) {
        value = ACTIVE_NEXT_STOP;
      } else {
        switch (punctuality) {
          case WISMO_PUNCTUALITY.ON_TIME:
          case WISMO_PUNCTUALITY.EARLY:
            value = ACTIVE;
            break;

          case WISMO_PUNCTUALITY.DELAYED:
            value = ACTIVE_LATE;
            break;

          default:
            value = ACTIVE;
        }
      }
      break;
    }

    case SUPPORTED_WISMO_TRACKING_STATUS.ARRIVED_AT_CUSTOMER_LOCATION: {
      switch (punctuality) {
        case WISMO_PUNCTUALITY.ON_TIME:
        case WISMO_PUNCTUALITY.DELAYED:
          value = ACTIVE_ARRIVED;
          break;

        case WISMO_PUNCTUALITY.EARLY:
          value = ACTIVE_ARRIVED_EARLY;
          break;

        default:
          value = ACTIVE_ARRIVED;
      }
      break;
    }

    case SUPPORTED_WISMO_TRACKING_STATUS.DELIVERED:
      value = ACTIVE_DELIVERED;
      break;

    case SUPPORTED_WISMO_TRACKING_STATUS.CANCELLED:
      value = CANCELLED;
      break;

    default:
      value = NOT_AVAILABLE;
  }

  return value;
}

export function emitWismoEvent(shoppingMethod: ShoppingMethod, status: TCurrentTrackingStatus, orderNo: string) {
  const trackingInfo = status;
  if (trackingInfo.status === SUPPORTED_WISMO_TRACKING_STATUS.NOT_AVAILABLE) {
    const text = NOT_AVAILABLE_ERROR;
    emitViewErrorDataEvent({ code: '', text });
    return;
  }

  const value = getEventValueFromTrackingStatus(status);
  const type = WISMO_EVENT_TYPE[shoppingMethod];
  basicEvent(analyticsBus, {
    type,
    value,
    action: NOW,
    orderNo,
  });
}

export function emitStarRatingEvent(
  starRating: string,
  currentTrackingStatus: TCurrentTrackingStatus,
  shoppingMethod: ShoppingMethod,
) {
  const value = `${getEventValueFromTrackingStatus(currentTrackingStatus)}:${starRating}`;
  const type = WISMO_EVENT_TYPE[shoppingMethod];
  basicEvent(analyticsBus, {
    type,
    value,
    action: NOW,
  });
}

export function emitBookAnotherSlotEvent(shoppingMethod: ShoppingMethod) {
  const type = WISMO_EVENT_TYPE[shoppingMethod];
  basicEvent(analyticsBus, {
    type,
    value: BOOK_ANOTHER_SLOT,
    action: DELAY,
  });
}
