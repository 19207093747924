import React from 'react';
import helpers from '#/lib/decorators/helpers';
import DeliveryHeading from '#/components/home/context-cards/wismo/shared/delivery-heading';
import { emitWismoEvent } from '#/components/home/context-cards/wismo/shared/analytics';
import { SUPPORTED_PAYMENT_RETRY_OPTION } from '#/constants/order-statuses';
import { DELIVERY } from '#/constants/shopping-methods';
import DriverOnMapLayout from '../../shared/driver-on-map/layout';
import DeliveryStatus from '../../shared/delivery-status';
import { SlotTimeInline } from '../../shared/styled';
import { VisuallyHide } from '../../shared/delivery-heading/styled';
import { Postcode } from '../arrived-at-location/styled';
import { useOrderContext } from '#/context/order-context-provider';

type Props = {
  t: (key: string, options?: object) => string;
  starRating?: React.Component;
};

export const PaymentFailed: React.FC<Props> = ({ t: translate, starRating }: Props) => {
  const { order } = useOrderContext();
  const orderNo = order.orderNo;
  const status = order.wismoSlot?.currentTrackingStatus?.status;
  const retryExpired = order.payment?.retryOption === SUPPORTED_PAYMENT_RETRY_OPTION.EXPIRED;
  const postcode = order.address.postcode;
  const slotStart = order.slotStart;
  const slotEnd = order.slotEnd;
  const retryOption = order.payment.retryOption;
  const deliveryHeading = (
    <DeliveryHeading ccAddressName={order.address.name} postcode={postcode} slotStart={slotStart} slotEnd={slotEnd} />
  );
  const primaryDeliveryStatus = <>{translate('context-cards:wismo-context-card-payment-issue')}</>;
  const secondaryDeliveryStatus = (
    <>
      {`${translate(`context-cards:wismo-context-card-booked-for`)} `}
      {slotStart.format('ddd D MMM')}
      {', '}
      <SlotTimeInline start={slotStart} end={slotEnd} alt={true} tagName="span" />
      {', '}
      <VisuallyHide>{translate('common:to')}</VisuallyHide>
      <Postcode>{postcode}</Postcode>
    </>
  );

  const deliveryStatus = (
    <DeliveryStatus
      primaryDeliveryStatus={primaryDeliveryStatus}
      secondaryDeliveryStatus={retryExpired ? secondaryDeliveryStatus : undefined}
      paymentIssue={retryOption}
    />
  );

  React.useEffect(() => emitWismoEvent(DELIVERY, { status: status }, orderNo), []);

  return (
    <DriverOnMapLayout
      deliveryHeading={deliveryHeading}
      deliveryStatus={deliveryStatus}
      starRating={retryExpired ? undefined : starRating}
    />
  );
};

export default helpers(['t'])(PaymentFailed);
