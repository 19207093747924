import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { connect } from '#/lib/render/connect-deep-compare';
import {
  getCurrentUrl,
  getIsMobile,
  getAppRegion,
  getLanguage,
  getTimezone
} from '#/reducers/app';
import {
  getUser,
  getIsUserRegistered,
  getIsUserAuthenticated
} from '#/reducers/user';
import {
  getLastSelectedSlot,
  getIsTrolleyUpdating,
  getItems,
  getIsAmendBasket,
  getDeliveryLocation
} from '#/selectors/trolley';
import { getHomePageResource } from '#/selectors/resources';
import {
  getContextCardData,
  getHomePageAnalyticsData,
  getDcsHomePageUnorderedComponentByID
} from '#/selectors/home-page';
import HomeContainer from '#/components/home/home-container';
import { DfpContainer } from '#/components/cms-driven';
import AnalyticsContentEvent from '#/components/analytics/content-event';
import MainBannerSignedIn from '#/components/home/main-banner/main-banner-signed-in';
import MainBannerSignedOut from '#/components/home/main-banner/main-banner-signed-out';
import helpers from '#/lib/decorators/helpers';
import dfpTargetingCreator from '#/lib/dfp-targeting-creator';
import { exposeActiveTestDataBatch } from '#/lib/optimizely-manager';
import {
  BACKGROUND_IMAGE,
  CONTEXT_CARD,
  BANNER_IMAGE
} from '#/components/home/main-banner/constants';
import BackgroundImage from './background-image';
import { HomepageHeader } from './styled';
import { emitContentInteractOp } from '#/analytics/bertie/events';
import MultiSearchTooltipContainer from '#/experiments/oop-1824/components/mult-search-tooltip-container';
import { getIsNotificationBannerEnabled } from '#/experiments/oop-1824/selectors';
import { getRecentOrder } from '#/selectors/order-list-details';
import { OrderContextProvider } from '#/context/order-context-provider';
import { getTimezoneAndLocaleAppliedMoment } from '#/lib/date-helpers';

export const mapStateToProps = state => {
  const isUserAuthenticated = getIsUserAuthenticated(state);
  const isAmendBasket = getIsAmendBasket(state);
  const backgroundImageData = getDcsHomePageUnorderedComponentByID(
    BACKGROUND_IMAGE
  )(state);

  return {
    address: getDeliveryLocation(state),
    analyticsData: getHomePageAnalyticsData(state),
    backgroundImageData,
    bookedSlot: getLastSelectedSlot(state),
    homePageResource: getHomePageResource(state),
    isAmendBasket,
    isUserAuthenticated,
    isUserRegistered: getIsUserRegistered(state),
    trolleyItems: getItems(state),
    trolleyUpdating: getIsTrolleyUpdating(state),
    user: getUser(state),
    currentUrl: getCurrentUrl(state),
    isMobile: getIsMobile(state),
    region: getAppRegion(state),
    contextCard: getContextCardData(CONTEXT_CARD)(state),
    isNotificationBannerEnabled: getIsNotificationBannerEnabled(state),
    recentOrder: getRecentOrder(state),
    language: getLanguage(state),
    timezone: getTimezone(state)
  };
};

@helpers(['asset', 't'])
@connect(mapStateToProps)
export default class Home extends Component {
  static propTypes = {
    address: PropTypes.object,
    asset: PropTypes.func.isRequired,
    bookedSlot: PropTypes.object,
    cmsData: PropTypes.object,
    currentUrl: PropTypes.string,
    favoritesVariant: PropTypes.string,
    homePageResource: PropTypes.object.isRequired,
    isAmendBasket: PropTypes.bool.isRequired,
    isMobile: PropTypes.bool.isRequired,
    isNotificationBannerEnabled: PropTypes.bool.isRequired,
    isUserAuthenticated: PropTypes.bool,
    isUserRegistered: PropTypes.bool,
    language: PropTypes.string,
    recentOrder: PropTypes.object,
    region: PropTypes.string.isRequired,
    timezone: PropTypes.string,
    trolleyItems: PropTypes.array.isRequired,
    trolleyUpdating: PropTypes.bool.isRequired,
    user: PropTypes.object
  };

  componentClickHandler = componentData => {
    const {
      contentId,
      title: campaignTitle,
      posInPanel: posInModule,
      placement: modulePosition,
      catId,
      segments: contentSegment,
      ruleId,
      trackingId
    } = componentData;

    emitContentInteractOp({
      displayArea: 'm-of',
      pageType: 'home',
      panel: [
        {
          campaignId: trackingId || (contentId ? contentId.trim() : catId),
          campaignTitle,
          trackingId,
          contentSegment,
          posInModule,
          ruleId
        }
      ],
      modulePosition
    });
  };

  getWelcomeTranslations() {
    const { t: translate, isUserAuthenticated } = this.props;

    return {
      'welcome-message': isUserAuthenticated
        ? translate('home:welcome-message')
        : translate('home:welcome-message-signed-out'),
      'username-message': translate('home:welcome-name')
    };
  }

  formatDate = date =>
    getTimezoneAndLocaleAppliedMoment(
      date,
      this.props.timezone,
      this.props.language
    );

  getHeader({ homeConfig, propositionalMessageIndex, ...otherProps }) {
    const {
      isUserAuthenticated,
      isAmendBasket,
      trolleyItems,
      bookedSlot,
      address,
      user,
      recentOrder
    } = this.props;
    const deliveryMeta = homeConfig || {};
    const welcome = this.getWelcomeTranslations();
    const contextValue = {
      ...recentOrder,
      slotStart: this.formatDate(recentOrder.slot?.start),
      slotEnd: this.formatDate(recentOrder.slot?.end),
      deliveryStart: this.formatDate(
        recentOrder.wismoSlot?.deliveryWindow?.start
      ),
      deliveryEnd: this.formatDate(recentOrder.wismoSlot?.deliveryWindow?.end)
    };

    return isUserAuthenticated ? (
      <OrderContextProvider order={contextValue}>
        <MainBannerSignedIn
          isAmendBasket={isAmendBasket}
          trolleyItems={trolleyItems}
          slot={bookedSlot}
          address={address}
          user={user}
          welcomeTranslations={welcome}
        />
      </OrderContextProvider>
    ) : (
      <MainBannerSignedOut
        analyticsProps={otherProps}
        deliveryMeta={deliveryMeta}
        propositionClickHandler={this.componentClickHandler}
        propositionalMessageIndex={propositionalMessageIndex}
        user={user}
        welcomeTranslations={welcome}
      />
    );
  }

  componentDidMount() {
    const {
      homePageResource: { data: { experiments } = {} }
    } = this.props;
    exposeActiveTestDataBatch(experiments);
  }

  render() {
    const {
      analyticsData,
      asset,
      backgroundImageData,
      homePageResource,
      isAmendBasket,
      user,
      isUserAuthenticated,
      isMobile,
      region,
      contextCard,
      isNotificationBannerEnabled
    } = this.props;

    const { main } = homePageResource.data.content;
    const content = (
      <HomeContainer
        isAmendBasket={isAmendBasket}
        cmsData={main}
        shared={{
          componentClickHandler: this.componentClickHandler
        }}
      />
    );

    const targeting = dfpTargetingCreator({ user });

    const header = this.getHeader(contextCard);
    const showMiniTrolley = isUserAuthenticated && !isMobile;

    return (
      <div
        className={classnames('home-page--contents-inner-wrapper', {
          'logged-in': isUserAuthenticated
        })}
      >
        {isNotificationBannerEnabled && <MultiSearchTooltipContainer />}
        <HomepageHeader region={region}>
          {
            <BackgroundImage
              data={backgroundImageData}
              fallbackImageSrc={asset(BANNER_IMAGE)}
            />
          }
          {header}
        </HomepageHeader>
        <div className="home-page">
          <div
            className={classnames('home-page__assets', {
              'has-trolley': showMiniTrolley
            })}
          >
            <div className="home__content-area">
              <DfpContainer pageId="homepage" targeting={targeting} />
              {content}
              <AnalyticsContentEvent
                data={analyticsData}
                pageName="home"
                sectionOrder={['main', 'unordered']}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}
