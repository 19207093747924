import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import FormattedSlotTime from '#/components/shared/formatted-time/formattedSlotTime';

class DeliverySlotTime extends Component {
  static propTypes = {
    alt: PropTypes.bool,
    className: PropTypes.string,
    end: PropTypes.object,
    start: PropTypes.object,
    tagName: PropTypes.oneOf(['p', 'span'])
  };

  static defaultProps = {
    tagName: 'p'
  };

  renderTime() {
    const { start, end } = this.props;

    return <FormattedSlotTime startTime={start} endTime={end} />;
  }

  render() {
    const TagName = this.props.tagName;
    return (
      <TagName className={this.props.className}>
        {!this.props.alt && (
          <span
            className="context-card-book-a-slot__day"
            data-auto="context-card-book-a-slot__day"
          >{`${this.props.start.format('DD MMM YYYY')}:`}</span>
        )}
        <span
          className={classnames('context-card-book-a-slot__time', {
            'context-card-book-a-slot__time--alt': this.props.alt
          })}
          data-auto="context-card-book-a-slot__time"
        >
          {this.renderTime()}
        </span>
      </TagName>
    );
  }
}

export default DeliverySlotTime;
