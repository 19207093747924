import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import helpers from '#/lib/decorators/helpers';
import Price from '#/components/shared/price';
import DeliverySlotTime from './delivery-slot-time';
import { getIsSameDay } from '#/lib/slot/slot-utils';
import { connect } from 'react-redux';
import {
  isOnDemandShoppingMethod,
  getOnDemandDynamicDeliveryData
} from '#/lib/shopping-method-util';
import {
  getOnDemandDynamicDeliveryTime,
  getFulfilmentEstimatedTime
} from '#/selectors/slot';

const mapStateToProps = state => ({
  onDemandDynamicDeliveryTime: getOnDemandDynamicDeliveryTime(state),
  fulfilmentEstimatedArrivalTime: getFulfilmentEstimatedTime(state)
});

@connect(mapStateToProps)
@helpers(['t', 'c'])
class MainText extends Component {
  static propTypes = {
    address: PropTypes.object,
    alt: PropTypes.bool,
    c: PropTypes.func.isRequired,
    deliveryBy: PropTypes.shape({
      day: PropTypes.string.isRequired,
      month: PropTypes.string.isRequired
    }),
    displayAsGuidePrice: PropTypes.bool,
    end: PropTypes.object,
    fulfilmentEstimatedArrivalTime: PropTypes.shape({
      isDynamicDeliveryTimeAvailable: PropTypes.bool.isRequired,
      value: PropTypes.number,
      unit: PropTypes.string,
      range: PropTypes.shape({
        min: PropTypes.number,
        max: PropTypes.number
      })
    }),
    guidePrice: PropTypes.number,
    onDemandDynamicDeliveryTime: PropTypes.shape({
      isDynamicDeliveryTimeAvailable: PropTypes.bool.isRequired,
      value: PropTypes.number,
      unit: PropTypes.string,
      range: PropTypes.shape({
        min: PropTypes.number,
        max: PropTypes.number
      })
    }),
    orderPlacedOn: PropTypes.string,
    shoppingMethod: PropTypes.string,
    showDeliverySlotWindow: PropTypes.bool,
    showDeliveryType: PropTypes.bool,
    showTime: PropTypes.bool,
    slotMessage: PropTypes.string,
    start: PropTypes.object,
    t: PropTypes.func.isRequired
  };

  renderGuidePrice() {
    const {
      t: translate,
      displayAsGuidePrice,
      guidePrice,
      c: config,
      alt
    } = this.props;
    const labelKey = displayAsGuidePrice
      ? 'common:guide-price'
      : 'common:price';

    return (
      guidePrice && (
        <p className="context-card-book-a-slot__price">
          <span className="context-card-book-a-slot__guide-label">{`${translate(
            labelKey
          )}:`}</span>
          <Price
            {...config('currency')}
            className="context-card-book-a-slot__guide-price"
            enableMaxLimit={true}
            value={guidePrice}
            compactMode={alt}
          />
        </p>
      )
    );
  }

  renderAddress() {
    const {
      address,
      address: { name: addressName }
    } = this.props;

    return (
      address && (
        <p
          aria-label={`Delivery post code ${addressName} `}
          className="home-page__text-wrap-two-line context-card-book-a-slot__address"
        >
          {addressName}
        </p>
      )
    );
  }

  renderOrderDate() {
    const {
      orderDate,
      t: translate,
      showTime,
      alt,
      start,
      end,
      fulfilmentEstimatedArrivalTime,
      showDeliverySlotWindow,
      showDeliveryType,
      shoppingMethod,
      deliveryBy,
      onDemandDynamicDeliveryTime,
      slotMessage
    } = this.props;
    const isOnDemandShopping = isOnDemandShoppingMethod(shoppingMethod);

    const { isDynamicDeliveryTimeAvailable } = onDemandDynamicDeliveryTime;

    const dynamicDeliveryTimeObj = isDynamicDeliveryTimeAvailable
      ? onDemandDynamicDeliveryTime
      : fulfilmentEstimatedArrivalTime;

    const {
      onDemandDynamicDeliverySuffix,
      unit,
      min,
      max,
      showDynamicTime
    } = getOnDemandDynamicDeliveryData(
      isOnDemandShopping,
      true,
      dynamicDeliveryTimeObj
    );

    if (slotMessage) {
      return (
        <p
          className="context-card-book-a-slot__time"
          data-auto="context-card-book-a-slot__time"
        >
          {slotMessage}
        </p>
      );
    }

    if (showDeliveryType) {
      const deliveryTime = getIsSameDay({ start })
        ? translate('common:today')
        : start.format('DD MMM YYYY');

      const contextCardDeliveryTime = showDynamicTime ? (
        <span className="context-card-book-a-slot__time">
          {translate(
            `home:book-a-slot.${shoppingMethod}.delivery-time${onDemandDynamicDeliverySuffix}`,
            {
              unit,
              min,
              max
            }
          )}
        </span>
      ) : (
        <span className="context-card-book-a-slot__time">{`${deliveryTime}, ${translate(
          `home:book-a-slot.${shoppingMethod}.delivery-time`
        )}`}</span>
      );

      return (
        <p>
          <span
            className="context-card-book-a-slot__day"
            data-auto="context-card-book-a-slot__day"
          >
            {contextCardDeliveryTime}
          </span>
        </p>
      );
    }

    if (showDeliverySlotWindow) {
      return showTime && <DeliverySlotTime alt={alt} start={start} end={end} />;
    }

    if (deliveryBy) {
      return (
        <p>
          {translate('home:book-a-slot.slot-booked.delivery-by', {
            day: deliveryBy.day,
            month: deliveryBy.month
          })}
        </p>
      );
    }

    return (
      <p>
        {translate('orders:common.created-on')} <strong>{orderDate}</strong>
      </p>
    );
  }

  render() {
    const { alt } = this.props;

    return (
      <div
        className={classnames('context-card-book-a-slot__text', {
          'context-card-book-a-slot__text--alt': alt
        })}
      >
        {this.renderOrderDate()}
        {!alt && this.renderAddress()}
        {this.renderGuidePrice()}
        {alt && this.renderAddress()}
      </div>
    );
  }
}

MainText.defaultProps = {
  showTime: true,
  displayAsGuidePrice: true,
  showDeliverySlotWindow: true
};

export default MainText;
