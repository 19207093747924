import styled, { css } from 'styled-components';
import DeliverySlotTime from '#/components/home/context-cards/delivery-slot-time';

export const SlotTimeInline = styled(DeliverySlotTime)`
  && span {
    font: inherit;
    text-transform: lowercase;
    ${(props: { paymentIssue: boolean }): string | void => {
      if (props.paymentIssue) {
        return css`
          font-weight: bold;
        `;
      }
    }};
  }
`;
SlotTimeInline.displayName = 'WismoStepperSlotTimeInline';
