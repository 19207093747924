import React, { PureComponent } from 'react';
import WidgetContainer from '#/components/widget-container';
import FavoritesWidget from '#/components/home/favorites-widget';
import { BEANS } from '#/constants/tile-types';
import { connect } from '#/lib/render/connect-deep-compare';
import { getFavCarouselItems, getFavExperiments } from '#/reducers/results';
import { getIsAmendBasket } from '#/selectors/trolley';
import { fetchFavoritesCarouselItems } from '#/actions/results-actions';
import LazyComponent from '#/components/shared/lazy-component';
import AnalyticsRenderedProductsEvent from '#/components/analytics/rendered-products-event';
import { exposeActiveTestDataBatch } from '#/lib/optimizely-manager';
import { getIsUserAuthenticated } from '#/reducers/user';

interface FavoritesWidgetWrapperMstp {
  experiments: ReturnType<typeof getFavExperiments>;
  favoritesItems: ReturnType<typeof getFavCarouselItems>;
  isAmendBasket: ReturnType<typeof getIsAmendBasket>;
  fetchFavsCarouselItemEnabled: boolean;
}

interface FavoritesWidgetWrapperMdtp {
  fetchFavoritesCarouselItems: typeof fetchFavoritesCarouselItems;
}

type FavoritesWidgetWrapperProps = FavoritesWidgetWrapperMstp &
  FavoritesWidgetWrapperMdtp & {
    listData: { page: string };
  };

const mapStateToProps = (state: Store): FavoritesWidgetWrapperMstp => ({
  experiments: getFavExperiments(state),
  favoritesItems: getFavCarouselItems(state),
  isAmendBasket: getIsAmendBasket(state),
  fetchFavsCarouselItemEnabled: getIsUserAuthenticated(state),
});

const mapDispatchToProps: FavoritesWidgetWrapperMdtp = {
  fetchFavoritesCarouselItems,
};

@connect(mapStateToProps, mapDispatchToProps)
class FavoritesWidgetWrapper extends PureComponent<FavoritesWidgetWrapperProps> {
  dataRequested: boolean;
  hasFiredAnalytics: boolean;

  constructor(props: FavoritesWidgetWrapperProps) {
    super(props);
    this.dataRequested = false;
    this.hasFiredAnalytics = false;
  }

  renderFavoritesWidget(): JSX.Element {
    return (
      <WidgetContainer widgetName="favorites-carousel">
        <FavoritesWidget
          favoritesItems={this.props.favoritesItems}
          isAmendBasket={this.props.isAmendBasket}
          productTileType={BEANS}
        />
        <AnalyticsRenderedProductsEvent
          items={this.props.favoritesItems}
          pageType="home"
          displayArea="csl"
          componentName="favourites"
        />
      </WidgetContainer>
    );
  }

  renderLazyComponent(): JSX.Element {
    return <LazyComponent target=".favorites-carousel" fetchData={this.fetchData} />;
  }

  fetchData = (): void => {
    this.dataRequested = true;
    const { fetchFavsCarouselItemEnabled, fetchFavoritesCarouselItems } = this.props;

    if (fetchFavsCarouselItemEnabled) {
      fetchFavoritesCarouselItems();
    }
  };

  fireAnalytics(): void {
    exposeActiveTestDataBatch(this.props.experiments);
    this.hasFiredAnalytics = true;
  }

  render(): JSX.Element {
    const { favoritesItems } = this.props;
    const hasFavorites = favoritesItems && favoritesItems.length > 0;
    const hasMinFavorites = favoritesItems && favoritesItems.length >= 3;
    const shouldFireAnalytics = hasMinFavorites && !this.hasFiredAnalytics;
    if (shouldFireAnalytics) {
      this.fireAnalytics();
    }
    return (
      <div className="favorites-carousel">
        {!hasFavorites && !this.dataRequested && this.renderLazyComponent()}
        {hasMinFavorites && this.renderFavoritesWidget()}
      </div>
    );
  }
}

export default FavoritesWidgetWrapper;
