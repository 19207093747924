import { clientGraphqlPost } from './client-graphql';
import uuidv4 from 'uuid/v4';
import { logApmError } from '#/lib/apm';
import { GET_ORDER_PAYMENT_RETRY_OPTION } from '#/constants/request-names';

const GET_ORDER_PAYMENT_RETRY_DETAILS = `
  query GetOrder($orderId: ID) {
    order(orderId: $orderId) {
      fulfilment {
        payments {
          ... on CardPaymentDetailsType {
            retryOption
          }
        }
      }
    }
  }
  `;

export default async orderPaymentRequestData => {
  const { atrc, apiKey, region, endpoint, orderId } = orderPaymentRequestData;
  const uuid = uuidv4();
  const traceId = `${atrc}:${uuid}`;
  const context = { 'x-apikey': apiKey, traceId, region };
  const queryVariables = {
    orderId: orderId
  };
  let response = null;

  try {
    const requestName = GET_ORDER_PAYMENT_RETRY_OPTION;

    response = await clientGraphqlPost(
      endpoint,
      context,
      GET_ORDER_PAYMENT_RETRY_DETAILS,
      queryVariables,
      atrc,
      requestName,
      traceId
    );

    const { data } = await response;
    const orderDetails = data.order;
    return {
      retryOption: orderDetails.fulfilment.payments[0]?.retryOption
    };
  } catch (error) {
    const errorObj = error?.parsedBody;
    const statusCode = error?.status || error?.errors?.[0]?.status || 'unknown';
    const errorMessage =
      error?.message ||
      errorObj?.message ||
      errorObj?.errors?.[0]?.message ||
      'unknown';
    const message = `Failed Payments Error - Failed to retrieve retryOption. Message: ${errorMessage}, Status: ${statusCode}`;
    logApmError(new Error(message), { traceId });
    return null;
  }
};
