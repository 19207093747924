import React from 'react';
import { compose } from 'react-recompose';
import { Moment } from 'moment';
import { ConnectedProps } from 'react-redux';
import { connect } from '#/lib/render/connect-deep-compare';
import helpers from '#/lib/decorators/helpers';
import { getWismoOrderAddressName } from '#/selectors/order-list-details';
import { SlotTimeInline } from '#/components/home/context-cards/wismo/shared/styled';
import { SUPPORTED_PAYMENT_RETRY_OPTION } from '#/constants/order-statuses';
import { useOrderContext } from '#/context/order-context-provider';
import { COLLECTION } from '#/constants/shopping-methods';
import { Wrapper, SlotTime, AddressName, Postcode, VisuallyHide, Messaging, MessagingText } from './styled';

const mapStateToProps = (state: Store) => ({
  wismoAddressName: getWismoOrderAddressName(state),
});

const connector = connect(mapStateToProps);

type THelpersProps = {
  t: (key: string, options?: object) => string;
};

type TOwnProps = {
  deliveryAddressName?: string;
  ccAddressName?: string;
  postcode?: string;
  slotStart?: Moment;
  slotEnd?: Moment;
  messaging: string;
  messageType: string;
};

type TProps = TOwnProps & THelpersProps & ConnectedProps<typeof connector>;

export const DeliveryHeading: React.FC<TProps> = ({
  t: translate,
  wismoAddressName,
  ccAddressName,
  deliveryAddressName,
  postcode,
  slotStart,
  slotEnd,
  messaging,
  messageType,
}): JSX.Element => {
  const { order } = useOrderContext();
  const retryEnabled = order.payment?.retryOption === SUPPORTED_PAYMENT_RETRY_OPTION.ENABLED;
  const shoppingMethod = order.shoppingMethod;
  const retryEnabledCollection = shoppingMethod === COLLECTION && retryEnabled;
  const headingLevel = retryEnabledCollection ? '4' : '5';
  const addressName = deliveryAddressName || wismoAddressName || ccAddressName;
  const message = messaging ? (
    <Messaging variant={messageType}>
      <MessagingText>{messaging}</MessagingText>
    </Messaging>
  ) : null;

  const slotTimeJsx = (): JSX.Element => {
    return (
      <SlotTime>
        {`${translate('context-cards:wismo-stepper-today')}, `}
        <SlotTimeInline start={slotStart} end={slotEnd} alt={true} tagName="span" />
      </SlotTime>
    );
  };

  const postcodeJsx = (): JSX.Element => {
    return (
      <>
        <VisuallyHide>{translate('common:to')} </VisuallyHide>
        <Postcode>{postcode}</Postcode>
      </>
    );
  };

  let deliveryHeading;

  if (slotStart) {
    if (postcode) {
      deliveryHeading = (
        <>
          {slotTimeJsx()}
          {', '}
          {postcodeJsx()}
        </>
      );
    } else {
      deliveryHeading = slotTimeJsx();
    }
  } else if (postcode) {
    deliveryHeading = postcodeJsx();
  } else {
    deliveryHeading = null;
  }

  return (
    <Wrapper headingLevel={headingLevel} retryEnabledCollection={retryEnabledCollection}>
      {message}
      {retryEnabledCollection ? (
        translate('home:order-collection')
      ) : (
        <>
          {`${translate(`context-cards:${order.shoppingMethod}-wismo-stepper.title`)} `}
          <>
            <AddressName>{`${addressName}, `}</AddressName>
            {deliveryHeading}
          </>
        </>
      )}
    </Wrapper>
  );
};

const enhance: Function = compose(helpers(['t']), connector);

export default enhance(DeliveryHeading);
