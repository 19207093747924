import React from 'react';
import helpers from '#/lib/decorators/helpers';
import DeliveryHeading from '#/components/home/context-cards/wismo/shared/delivery-heading';
import DeliveryStatus from '#/components/home/context-cards/wismo/shared/delivery-status';
import { emitWismoEvent } from '#/components/home/context-cards/wismo/shared/analytics';
import { WISMO_PUNCTUALITY, SUPPORTED_WISMO_TRACKING_STATUS } from '#/constants/order-statuses';
import { Postcode } from './styled';
import { DELIVERY } from '#/constants/shopping-methods';
import DriverOnMapLayout from '../../shared/driver-on-map/layout';
import { useOrderContext } from '#/context/order-context-provider';

type Props = {
  t: (key: string, options?: object) => string;
  starRating: React.Component;
  display3Steps: boolean;
};

export const ArrivedAtLocation: React.FC<Props> = ({ t: translate, starRating, display3Steps }: Props) => {
  const { order } = useOrderContext();
  const orderNo = order.orderNo;
  const punctuality = order.wismoSlot?.currentTrackingStatus?.punctuality;
  const postcode = order.address?.postcode;
  const slotStart = order.slotStart;
  const slotEnd = order.slotEnd;
  let deliveryHeading;
  let primaryDeliveryStatus;
  let secondaryDeliveryStatus;

  switch (punctuality) {
    case WISMO_PUNCTUALITY.EARLY:
      deliveryHeading = <DeliveryHeading postcode={postcode} slotStart={slotStart} slotEnd={slotEnd} />;
      primaryDeliveryStatus = translate('context-cards:wismo-stepper.arrived-at-location.early.primary');
      secondaryDeliveryStatus = translate('context-cards:wismo-stepper.arrived-at-location.early.secondary');
      break;

    case WISMO_PUNCTUALITY.ON_TIME:
    case WISMO_PUNCTUALITY.DELAYED:
    default:
      deliveryHeading = <DeliveryHeading slotStart={slotStart} slotEnd={slotEnd} />;
      primaryDeliveryStatus = (
        <>
          {translate('context-cards:wismo-stepper.arrived-at-location.on-time.primary')} <Postcode>{postcode}</Postcode>
        </>
      );
      secondaryDeliveryStatus = translate('context-cards:wismo-stepper.arrived-at-location.on-time.secondary');
  }

  const deliveryStatus = (
    <DeliveryStatus primaryDeliveryStatus={primaryDeliveryStatus} secondaryDeliveryStatus={secondaryDeliveryStatus} />
  );

  React.useEffect(
    () =>
      emitWismoEvent(
        DELIVERY,
        { status: SUPPORTED_WISMO_TRACKING_STATUS.ARRIVED_AT_CUSTOMER_LOCATION, punctuality },
        orderNo,
      ),
    [],
  );

  return (
    <DriverOnMapLayout
      deliveryHeading={deliveryHeading}
      deliveryStatus={deliveryStatus}
      starRating={starRating}
      display3Steps={display3Steps}
    />
  );
};

export default helpers(['t'])(ArrivedAtLocation);
