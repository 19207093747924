import React, { useRef } from 'react';
import helpers from '#/lib/decorators/helpers';
import SafeForm from '#/components/shared/safe-form';
import { connect } from '#/lib/render/connect-deep-compare';
import { getLanguage, getApigeeMangoApiKey, getApigeeMangoEndpoint, getAppRegion } from '#/reducers/app';
import { getAtrc } from '#/reducers/user';
import { useOrderContext } from '#/context/order-context-provider';
import getOrderPaymentRetryDetails from '#/lib/requests/get-order-payment-retry-details';
import { SUPPORTED_PAYMENT_RETRY_OPTION } from '#/constants/order-statuses';
import Button from '@ddsweb/button';

const PAYMENT_RETRY = 'PAYMENT_RETRY';
const HOME = 'HOME';

const mapStateToProps = (state: Store): object => ({
  locale: getLanguage(state),
  endpoint: getApigeeMangoEndpoint(state),
  apiKey: getApigeeMangoApiKey(state),
  region: getAppRegion(state),
  atrc: getAtrc(state),
});

type TOwnProps = {
  t: (key: string, options?: { [key: string]: string }) => string;
  className: string;
  loading: boolean;
  locale: string;
  paymentOptionsURL: string;
  endpoint: string;
  apiKey: string;
  region: string;
  atrc: string;
};

type TProps = TOwnProps & ReturnType<typeof mapStateToProps>;

export function UpdatePaymentDetailsButton({
  endpoint,
  apiKey,
  region,
  atrc,
  locale,
  className,
  t: translate,
}: TProps): JSX.Element {
  const { order, setOrder } = useOrderContext();
  const [isSubmitting, setIsSubmitting] = React.useState(false);

  const formId = `update-payment-button-form-${order.orderNo}`;
  const myRef = useRef<HTMLInputElement>(null);
  const submitter = <input ref={myRef} type="submit" />;
  const updatePaymentDetailsForm = (
    <SafeForm
      action={`/checkout/api/groceries/payment-options?locale=${locale}&groceries=true`}
      method="POST"
      className="update-payment-form"
      id={formId}
      data-testid={formId}
    >
      {submitter}
      <input type="hidden" name="action" value={PAYMENT_RETRY} />
      <input type="hidden" name="returnLocation" value={HOME} />
      <input type="hidden" name="orderId" value={order.id} />
      <input type="hidden" name="paymentId" value={order.payment?.paymentId} />
      <input type="hidden" name="totalPrice" value={order.payment?.amount} />
    </SafeForm>
  );

  async function handleClick(): Promise<void> {
    setIsSubmitting(true);
    const deliveryTrackingRequestData = {
      apiKey: apiKey,
      region: region,
      atrc: atrc,
      endpoint: endpoint,
      orderId: order.id,
    };
    const response = await getOrderPaymentRetryDetails(deliveryTrackingRequestData);
    const paymentRetryDetails = response;
    const retryEnabled = paymentRetryDetails?.retryOption === SUPPORTED_PAYMENT_RETRY_OPTION.ENABLED;
    let updatedOrder;

    if (retryEnabled) {
      // proceed to submit the form & execute the post request
      updatedOrder = {
        ...order,
        updatePaymentError: false,
      };
      setOrder(updatedOrder);
      myRef.current?.click();
    } else if (response === null) {
      // re-renders the context card with an in-page error message
      updatedOrder = {
        ...order,
        updatePaymentError: true,
      };
      setIsSubmitting(false);
      setOrder(updatedOrder);
    } else {
      // re-renders the context card based on the newly updated retryOption
      updatedOrder = {
        ...order,
        payment: paymentRetryDetails !== null ? paymentRetryDetails : { ...order.payment },
      };
      setIsSubmitting(false);
      setOrder(updatedOrder);
    }
  }

  return (
    <>
      <Button className={className} onClick={handleClick} type="button" variant="primary" isLoading={isSubmitting}>
        {translate('context-cards:wismo-context-card-update-payment')}
      </Button>
      {updatePaymentDetailsForm}
    </>
  );
}

export default connect(mapStateToProps)(helpers(['t'])(UpdatePaymentDetailsButton));
