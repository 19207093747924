import React from 'react';
import Map from '#/components/map';
import styled from 'styled-components';
import { isOnDemandShoppingMethod } from '#/lib/shopping-method-util';

const Container = styled.div`
  width: 100%;
  height: 100%;
  & .map__wrapper {
    border: none;
    border-radius: unset;
  }
`;

const DriverOnMap = ({
  homeGeoLocation,
  driverGeoLocation,
  shoppingMethod
}) => {
  const onReadyHandler = ({ target }) => {
    target.mapAttributes.setBestView(target.map, [
      homeGeoLocation,
      driverGeoLocation
    ]);
  };
  return (
    <Container className="map-container">
      <Map
        onReady={onReadyHandler}
        onResize={onReadyHandler}
        center={homeGeoLocation}
        points={[
          {
            latitude: homeGeoLocation?.latitude,
            longitude: homeGeoLocation?.longitude,
            type: 'home'
          },
          {
            latitude: driverGeoLocation?.latitude,
            longitude: driverGeoLocation?.longitude,
            type: isOnDemandShoppingMethod(shoppingMethod) ? 'driver' : 'truck'
          }
        ]}
        disablePanning={true}
        disableTouchInput={true}
        disableZooming={true}
      />
    </Container>
  );
};

export default DriverOnMap;
