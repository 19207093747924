import React from 'react';
import { Image, Panel } from '#/components/cms-driven';
import { renderTree } from '#/components/cms-driven/component-tree';
import { BannerBackgroundImage, BackgroundOverlay } from '#/components/pages/home/styled';

type TData = {
  props: {
    id: string;
    ordered: boolean;
  };
  children: object[];
  type: string;
};

type OwnProps = {
  data: TData;
  fallbackImageSrc: string;
};

const BackgroundImagePanel: React.FC<OwnProps> = ({ data, fallbackImageSrc }: OwnProps) => {
  if (!data) {
    return (
      <BannerBackgroundImage path={fallbackImageSrc}>
        <BackgroundOverlay />
      </BannerBackgroundImage>
    );
  }

  return (
    <BannerBackgroundImage>
      {renderTree({ Image, Panel }, [data])}
      <BackgroundOverlay />
    </BannerBackgroundImage>
  );
};

export default BackgroundImagePanel;
