import { SUPPORTED_WISMO_TRACKING_STATUS } from '#/constants/order-statuses';

export const deliveryTrackingMocks = {
  PREPARINGORDER: {
    data: {
      deliveryTracking: {
        tripTrackingId: null,
        deliveryWindow: {
          start: '2022-11-01T09:30:00Z',
          end: '2022-11-01T10:00:00+00:00'
        },
        currentTrackingStatus: {
          status: SUPPORTED_WISMO_TRACKING_STATUS.PREPARING_ORDER
        },
        destination: {
          geoLocation: {
            latitude: 51.44931,
            longitude: -0.07473
          }
        }
      }
    },
    cache: {
      maxAge: 0
    }
  },
  DELIVERYSCHEDULED: {
    data: {
      deliveryTracking: {
        tripTrackingId: 'xxx',
        deliveryWindow: {
          start: '2022-11-01T09:30:00Z',
          end: '2022-11-01T10:00:00+00:00'
        },
        currentTrackingStatus: {
          status: SUPPORTED_WISMO_TRACKING_STATUS.DELIVERY_SCHEDULED,
          punctuality: 'ON_TIME',
          geoLocation: {
            longitude: -0.07573,
            latitude: 51.45031
          },
          stopsUntilDelivery: 1
        },
        destination: {
          geoLocation: {
            latitude: 51.44931,
            longitude: -0.07473
          }
        }
      }
    },
    cache: {
      maxAge: 30
    }
  },
  ARRIVED: {
    data: {
      deliveryTracking: {
        tripTrackingId: 'xxx',
        deliveryWindow: {
          start: '2022-11-01T09:30:00Z',
          end: '2022-11-01T10:00:00+00:00'
        },
        currentTrackingStatus: {
          status: SUPPORTED_WISMO_TRACKING_STATUS.ARRIVED_AT_CUSTOMER_LOCATION,
          punctuality: 'ON_TIME',
          geoLocation: {
            longitude: -0.07573,
            latitude: 51.45031
          },
          stopsUntilDelivery: 1
        },
        destination: {
          geoLocation: {
            longitude: -0.07573,
            latitude: 51.45031
          }
        }
      }
    },
    cache: {
      maxAge: 30
    }
  },
  DELIVERED: {
    data: {
      deliveryTracking: {
        deliveryWindow: {
          start: '2022-11-01T09:30:00Z',
          end: '2022-11-01T10:00:00+00:00'
        },
        currentTrackingStatus: {
          status: SUPPORTED_WISMO_TRACKING_STATUS.DELIVERED,
          punctuality: 'ON_TIME',
          geoLocation: {
            longitude: -0.07573,
            latitude: 51.45031
          },
          stopsUntilDelivery: 1
        },
        destination: {
          geoLocation: {
            latitude: 51.44931,
            longitude: -0.07473
          }
        }
      }
    },
    cache: {
      maxAge: 0
    }
  },
  CANCELLED: {
    data: {
      deliveryTracking: {
        tripTrackingId: null,
        deliveryWindow: {
          start: '2022-11-01T09:30:00Z',
          end: '2022-11-01T10:00:00+00:00'
        },
        currentTrackingStatus: {
          status: SUPPORTED_WISMO_TRACKING_STATUS.CANCELLED
        },
        destination: {
          geoLocation: {
            latitude: 51.44931,
            longitude: -0.07473
          }
        }
      }
    },
    cache: {
      maxAge: 0
    }
  }
};
