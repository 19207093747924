import React from 'react';
import PropTypes from 'prop-types';
import * as components from '#/components/cms-driven';
import { treeMap } from '#/lib/dcs/tree-utils';
import FavoritesWidgetWrapper from './favorites-widget-wrapper';
import RecommenderWrapper from '#/components/recommender';
import { HOME_DISPLAY_AREA } from '#/constants/recommendations';
import {
  RECOMMENDERS_CAROUSEL,
  PLACEHOLDER
} from '#/constants/dcs-widget-types';
import { FAVOURITES_CAROUSEL } from '#/constants/favorites';
import helpers from '#/lib/decorators/helpers';

function getPanelPosition(cmsData) {
  const index = cmsData.findIndex(panel => {
    if (panel && panel.children.length) {
      const firstChild = panel.children[0];

      return (
        firstChild.type === PLACEHOLDER &&
        firstChild.props.componentName === RECOMMENDERS_CAROUSEL
      );
    }

    return null;
  });

  return index === -1 ? 1 : index + 1;
}

const HomeContainer = ({ cmsData, isAmendBasket, ...otherProps }) => {
  const { c: config } = otherProps;

  const expandPlaceholder = (placeholder, showTescoRecommendation) => {
    const expandedComponent = Object.assign({}, placeholder);
    const componentName = expandedComponent.props.componentName;

    expandedComponent.type = componentName;

    if (componentName === FAVOURITES_CAROUSEL) {
      expandedComponent.props = {
        catId: 'Favorites',
        title: 'Favorites',
        isAmendBasket,
        listData: { page: 'Home' }
      };
    } else if (
      showTescoRecommendation &&
      componentName === RECOMMENDERS_CAROUSEL
    ) {
      expandedComponent.props = {
        page: 'home',
        configKey: 'home_page',
        displayArea: HOME_DISPLAY_AREA,
        panelPos: getPanelPosition(cmsData)
      };
    }

    return expandedComponent;
  };

  const showTescoRecommendation = config('recommenders:enabled');
  const cmsExpanded = treeMap(component => {
    if (component.type === 'Placeholder') {
      return expandPlaceholder(component, showTescoRecommendation);
    }

    return component;
  }, cmsData);

  components.FavouritesCarousel = FavoritesWidgetWrapper;

  if (showTescoRecommendation) {
    components.RecommendersCarousel = RecommenderWrapper;
  }

  return (
    <div className="home">
      <components.ComponentTree
        components={components}
        data={cmsExpanded}
        {...otherProps}
      />
    </div>
  );
};

HomeContainer.propTypes = {
  c: PropTypes.func.isRequired,
  cmsData: PropTypes.array,
  isAmendBasket: PropTypes.bool
};

export default helpers(['c'])(HomeContainer);
