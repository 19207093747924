import { getFeatureVariableStringFromState } from '#/lib/optimizely-manager';
import { bookSlotsHomepage } from '#/experiments/oop-1978/constants';

const {
  useCustomerId,
  variationKey,
  variants: {
    default: defaultVariant,
    singleBookSlotCTA: singleBookSlotCTAVariant,
    singleBookSlotCTANoFavs: singleBookSlotCTANoFavsVariant,
  },
  featureKey,
} = bookSlotsHomepage;

const getVariant = (state: Store): string =>
  getFeatureVariableStringFromState(state, featureKey, variationKey, { useCustomerId }) || defaultVariant;

export const getIsAmongSlotBookingVariants = (state: Store): boolean =>
  [singleBookSlotCTAVariant, singleBookSlotCTANoFavsVariant].includes(getVariant(state));

export const getIsNoFavsVariant = (state: Store): boolean => singleBookSlotCTANoFavsVariant === getVariant(state);
