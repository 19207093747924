import { ImHereActionType } from '#/constants/im-here';

interface ImHereAction {
  type: ImHereActionType;
}
export interface InitialState {
  displayButton: boolean;
  displaySpinner: boolean;
  displayImHereModal: boolean;
}
export const initialState = {
  displayButton: true,
  displaySpinner: false,
  displayImHereModal: false,
};

export default function(state: InitialState = initialState, action: ImHereAction): InitialState {
  switch (action.type) {
    case ImHereActionType.DISPLAY_IMHERE_MODAL:
      return {
        ...state,
        displayImHereModal: true,
      };
    case ImHereActionType.HIDE_IMHERE_MODAL:
      return {
        ...state,
        displayImHereModal: false,
      };
    case ImHereActionType.DISPLAY_LOADER:
      return {
        ...state,
        displayImHereModal: false,
        displaySpinner: true,
      };
    case ImHereActionType.HIDE_LOADER:
      return {
        ...state,
        displayImHereModal: false,
        displaySpinner: false,
      };
    case ImHereActionType.HIDE_BUTTON:
      return {
        ...state,
        displayImHereModal: false,
        displayButton: false,
      };
    default:
      return state;
  }
}
