import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from '#/lib/render/connect-deep-compare';
import helpers from '#/lib/decorators/helpers';
import { addModalToUrl } from '#/lib/url/modal-utils';
import { getTimezoneAndLocaleAppliedMoment } from '#/lib/date-helpers';
import { openModal } from '#/actions/ui-action-creators';
import analyticsBus from '#/analytics/analyticsBus';
import { basicEvent } from '#/analytics/types/basic';
import SaveChangesButton from '#/components/buttons/save-changes-button';
import Link from '#/components/link';
import SafelyOutputString from '#/components/safely-output-string';
import {
  getCurrentUrl,
  getLanguage,
  getLanguageLink,
  getTimezone
} from '#/reducers/app';
import { getDefaultSlotsPath } from '#/reducers/slot';
import { ECOUPON_MODAL } from '#/constants/modal-names';
import Icon from './icon';
import DeliveryDate from './delivery-date';
import MainText from './main-text';

const mapStateToProps = (state, { c: config, f: feature }) => ({
  currentUrl: getCurrentUrl(state),
  displayAsGuidePrice: config('displayAsGuidePrice'),
  language: getLanguage(state),
  timezone: getTimezone(state),
  showChangeYourSlotLink: feature('allowAmendSlot'),
  slotsLanguageLink: getLanguageLink(state, getDefaultSlotsPath(feature)(state))
});

@helpers(['t', 'c', 'f'])
@connect(mapStateToProps, { openModal })
export default class OrderAmendedContextCard extends Component {
  static propTypes = {
    c: PropTypes.func.isRequired,
    currentUrl: PropTypes.string,
    displayAsGuidePrice: PropTypes.bool.isRequired,
    hasTrolleyItems: PropTypes.bool.isRequired,
    language: PropTypes.string.isRequired,
    openModal: PropTypes.func,
    order: PropTypes.shape({
      address: PropTypes.object.isRequired,
      guidePrice: PropTypes.number.isRequired,
      orderPlacedOn: PropTypes.string,
      expiry: PropTypes.string.isRequired,
      slot: PropTypes.shape({
        start: PropTypes.string.isRequired,
        end: PropTypes.string.isRequired
      })
    }),
    showChangeYourSlotLink: PropTypes.bool,
    slotsLanguageLink: PropTypes.string.isRequired,
    t: PropTypes.func,
    timezone: PropTypes.string.isRequired
  };

  handleClick = e => {
    e && e.preventDefault();
    this.props.openModal(ECOUPON_MODAL);
    basicEvent(analyticsBus, {
      type: 'contextCard',
      value: 'add ecoupons'
    });
  };

  formatDate = date =>
    getTimezoneAndLocaleAppliedMoment(
      date,
      this.props.timezone,
      this.props.language
    );

  render() {
    const {
      order: { slot, expiry, address, guidePrice, orderPlacedOn },
      displayAsGuidePrice,
      t: translate,
      hasTrolleyItems,
      currentUrl,
      showChangeYourSlotLink,
      slotsLanguageLink
    } = this.props;
    const start = this.formatDate(slot.start);
    const end = this.formatDate(slot.end);
    const amendExpiry = this.formatDate(expiry).format('dddd DD MMMM, HH:mm');
    const infoHTMLText = translate('home:book-a-slot.order-amended.details', {
      save_by_date_time: amendExpiry
    });

    return (
      <div
        className="context-card-book-a-slot--order-amended"
        data-auto="context-card-amend-order"
      >
        <div className="context-card-book-a-slot__top">
          <Icon>
            <DeliveryDate date={end} />
          </Icon>
          <h2 className="context-card-book-a-slot__title">
            {translate('home:book-a-slot.order-amended.title')}
          </h2>
          <MainText
            address={address}
            displayAsGuidePrice={displayAsGuidePrice}
            end={end}
            guidePrice={guidePrice}
            orderPlacedOn={orderPlacedOn}
            start={start}
          />
          <div className="action">
            <SaveChangesButton
              buttonText={translate(
                'home:book-a-slot.order-amended.save-changes'
              )}
              disabled={!hasTrolleyItems}
            />
            {showChangeYourSlotLink ? (
              <Link
                className="context-card__change-your-slot hidden-small-medium"
                href={slotsLanguageLink}
                data-auto="amend-change-slot-link"
              >
                {translate('home:amend-order.change-your-slot')}
                <span className="context-card-book-a-slot__change-your-slot-link icon-chevron_right" />
              </Link>
            ) : (
              <Link
                className="context-card__add-ecoupon hidden-small-medium"
                href={addModalToUrl(currentUrl, ECOUPON_MODAL)}
                onClick={this.handleClick}
              >
                {translate('orders:amend.add-ecoupons-vouchers')}
                <span className="context-card-book-a-slot__add-ecoupon-link icon-chevron_right" />
              </Link>
            )}
          </div>
        </div>
        {!showChangeYourSlotLink && (
          <div className="context-card-book-a-slot--bottom">
            <SafelyOutputString>{infoHTMLText}</SafelyOutputString>
          </div>
        )}
      </div>
    );
  }
}
