import React from 'react';
import Button from '@ddsweb/button';
import { ConnectedProps } from 'react-redux';
import { compose } from 'react-recompose';
import { connect } from '#/lib/render/connect-deep-compare';
import helpers from '#/lib/decorators/helpers';
import { ButtonContainer, Content, StyledHeading, StyledModal } from './styled';
import { TTranslateFn } from '#/components/slots/delivery-content-section/index.defs';
import { ImHereActionType, OrderFulfilmentEventStatus, ARRIVED } from '#/constants/im-here';
import {
  mutateOrderFulfilmentEvent,
  MutateOrderFulfilmentEventResponse,
} from '#/lib/requests/fulfilment/mutate-order-fulfilment-event';
import { updateOrderListImHereArrived } from '#/actions/order-list-details-action-creators';
import { sessionStore } from '#/lib/data-store/client-store.js';
import { getImHereButtonSessionKeyTemplate } from '#/components/home/context-cards/im-here-button/selector';
import { getApigeeMangoEndpoint, getApigeeMangoApiKey, getAppRegion } from '#/reducers/app';
import { openModal } from '#/actions/ui-action-creators';
import { CONNECTION_TIMEOUT_MODAL } from '#/constants/modal-names';
import { getAtrc } from '#/reducers/user';
import analyticsBus from '#/analytics/analyticsBus';
import { SLOT_BOOKED_ANALYTICS_METHOD } from '#/components/home/context-cards/im-here-button/constants';

type HelperProps = {
  t: TTranslateFn;
};

type Props = {
  closeModal: () => void;
  confirmHandler: (type: ImHereActionType, callback?: (errorHandler: (error: Error) => void) => void) => void;
  orderNo: string;
  locationUuid: string;
  slotStart: string;
  slotEnd: string;
};

type TPayload = {
  locationUuid?: string;
  orderId?: string;
  action: string;
};

const mapDispatchToProps = {
  updateOrderListImHereArrived,
  openModal,
};

const mapStateToProps = (state: Store): { endpoint: string; apiKey: string; region: string; atrc: string } => ({
  endpoint: getApigeeMangoEndpoint(state),
  apiKey: getApigeeMangoApiKey(state),
  region: getAppRegion(state),
  atrc: getAtrc(state),
});

const connector = connect(mapStateToProps, mapDispatchToProps);

export type ImHereModalProps = HelperProps & ConnectedProps<typeof connector> & Props;

const ImHereModal = (props: ImHereModalProps): JSX.Element => {
  const {
    t: translate,
    closeModal,
    orderNo,
    locationUuid,
    confirmHandler,
    updateOrderListImHereArrived,
    openModal,
    endpoint,
    apiKey,
    region,
    atrc,
    slotStart,
    slotEnd,
  } = props;

  const changeHandler = (_: void, event: React.MouseEvent<HTMLButtonElement>): void => {
    onClose(event);
  };

  const onClose = (event: React.MouseEvent<HTMLButtonElement>): void => {
    event.preventDefault();
    closeModal();
  };

  const markArrivalKeyInSession = (): void => {
    const currentOrderKey: string = getImHereButtonSessionKeyTemplate(locationUuid, orderNo);
    const loggedOrderKey: string = sessionStore?.get(currentOrderKey);

    if (!loggedOrderKey) {
      sessionStore?.set(currentOrderKey, 'true');
    }
  };

  const confirmImHere = (payload: TPayload) => async (e: React.SyntheticEvent): Promise<void> => {
    e.preventDefault();

    analyticsBus().emit('UIEventSlotBooked', {
      start: slotStart,
      end: slotEnd,
      method: SLOT_BOOKED_ANALYTICS_METHOD,
    });

    confirmHandler(ImHereActionType.DISPLAY_LOADER);

    try {
      const response = await mutateOrderFulfilmentEvent(payload, { apiKey, endpoint, region, atrc });
      const {
        data: {
          fulfilmentEvent: { status },
        },
      } = response as MutateOrderFulfilmentEventResponse;
      if (status === OrderFulfilmentEventStatus.SUCCESS) {
        confirmHandler(ImHereActionType.HIDE_BUTTON);
        markArrivalKeyInSession();
        updateOrderListImHereArrived(orderNo);
      } else {
        confirmHandler(ImHereActionType.HIDE_LOADER);
        openModal(CONNECTION_TIMEOUT_MODAL);
      }
    } catch (error) {
      confirmHandler(ImHereActionType.HIDE_LOADER, throwAsyncError => {
        throwAsyncError(error as Error);
      });
    }
  };

  const payload = {
    locationUuid: locationUuid,
    orderId: orderNo,
    action: ARRIVED,
  };

  return (
    <StyledModal id="im-here-modal" open ssr onChange={changeHandler}>
      <Content>
        <StyledHeading>{translate('modals:im-here.title')}</StyledHeading>
      </Content>
      <ButtonContainer className="button__actions">
        <Button variant={'secondary'} onClick={onClose} data-auto="close-modal-button">
          {translate('modals:im-here.button.cancel')}
        </Button>
        <Button data-auto="im-here-confirm-button" onClick={confirmImHere(payload)}>
          {translate('modals:im-here.button.yes')}
        </Button>
      </ButtonContainer>
    </StyledModal>
  );
};

const enhance = compose<ImHereModalProps, Props>(helpers(['c', 't']), connector);

export default enhance(ImHereModal);
