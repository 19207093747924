import React from 'react';
import Link from '@beans/link';
import helpers from '#/lib/decorators/helpers';
import DeliveryHeading from '#/components/home/context-cards/wismo/shared/delivery-heading';
import DeliveryStatus from '#/components/home/context-cards/wismo/shared/delivery-status';
import { emitWismoEvent } from '#/components/home/context-cards/wismo/shared/analytics';
import { SUPPORTED_WISMO_TRACKING_STATUS } from '#/constants/order-statuses';
import { SlotTimeInline } from '#/components/home/context-cards/wismo/shared/styled';
import { DELIVERY } from '#/constants/shopping-methods';
import DriverOnMapLayout from '#/components/home/context-cards/wismo/shared/driver-on-map/layout';
import { basicEvent } from '#/analytics/types/basic';
import analyticsBus from '#/analytics/analyticsBus';
import { DELAY } from '#/analytics/constants';
import { useOrderContext } from '#/context/order-context-provider';

type Props = {
  t: (key: string, options?: object) => string;
  l: Function;
  starRating: React.Component;
};

export const emitAnalyticsEvent = (): void =>
  basicEvent(analyticsBus, {
    type: 'wismo',
    value: 'cancelled order modal:reorder',
    action: DELAY,
  });

const CancelledContextCard: React.FC<Props> = ({ t: translate, l: languageLink, starRating }: Props) => {
  const { order } = useOrderContext();
  const orderNo = order.orderNo;
  const name = order.address.name;
  const postcode = order.address.postcode;
  const slotStart = order.slotStart;
  const slotEnd = order.slotEnd;
  const deliveryHeading = (
    <DeliveryHeading
      deliveryAddressName={name}
      postcode={postcode}
      messaging={translate('context-cards:wismo-stepper.cancelled.header-message')}
      messageType="error"
    />
  );

  const primaryDeliveryStatus = translate('context-cards:wismo-stepper.cancelled.primary');
  const secondaryDeliveryStatus = (
    <>
      {translate('context-cards:wismo-stepper.cancelled.booked-for')}{' '}
      <SlotTimeInline start={slotStart} end={slotEnd} alt={true} tagName="span" />
      {', '}
      {postcode}
    </>
  );
  const placeOrderAgainLink = (
    <Link
      href={languageLink(`/orders/${orderNo}`)}
      onClick={emitAnalyticsEvent}
      icon={{
        graphic: 'forwardLink',
        position: {
          global: 'right',
        },
      }}
      variant="standalone"
    >
      {translate('context-cards:wismo-stepper.cancelled.place-order-again')}
    </Link>
  );

  const deliveryStatus = (
    <DeliveryStatus
      primaryDeliveryStatus={primaryDeliveryStatus}
      secondaryDeliveryStatus={secondaryDeliveryStatus}
      thirdDeliveryStatus={placeOrderAgainLink}
    />
  );

  React.useEffect(() => emitWismoEvent(DELIVERY, { status: SUPPORTED_WISMO_TRACKING_STATUS.CANCELLED }, orderNo), []);

  return (
    <DriverOnMapLayout deliveryHeading={deliveryHeading} deliveryStatus={deliveryStatus} starRating={starRating} />
  );
};

export default helpers(['t', 'l'])(CancelledContextCard);
