import styled from 'styled-components';
import { Heading } from '@ddsweb/heading';
import { Text } from '@ddsweb/text';
import { InLineMessaging } from '@ddsweb/messaging';
import { visuallyHide } from '@ddsweb/foundation';
import { spacing, fontSize, colors } from '@ddsweb/selectors';

export const Wrapper = styled(Heading)`
  && {
    margin-bottom: ${(props: { retryEnabledCollection: boolean }): string =>
      props.retryEnabledCollection ? ((spacing.sm as unknown) as string) : ((spacing.xs as unknown) as string)};
  }
`;
Wrapper.displayName = 'WismoStepperDeliveryHeadingWrapper';

export const SlotTime = styled.span`
  font-weight: normal;
`;
SlotTime.displayName = 'WismoStepperSlotTime';

export const AddressName = styled.span`
  font-weight: normal;
`;
AddressName.displayName = 'WismoStepperAddressName';

export const Postcode = styled.span`
  font-weight: normal;
  text-transform: uppercase;
`;
Postcode.displayName = 'WismoStepperPostcode';

export const VisuallyHide = styled.span`
  ${visuallyHide};
`;

export const Messaging = styled(InLineMessaging)`
  margin-bottom: ${spacing.md};
`;

export const MessagingText = styled(Text)`
  font-size: ${fontSize.xx};
  color: ${colors.text};
`;
