import { SUPPORTED_PAYMENT_RETRY_OPTION } from '#/constants/order-statuses';
import React from 'react';
import { PrimaryDeliveryStatus, SecondaryDeliveryStatus, ThirdDeliveryStatus } from './styled';

type Props = {
  primaryDeliveryStatus: string | JSX.Element;
  secondaryDeliveryStatus?: string | JSX.Element;
  thirdDeliveryStatus?: string | JSX.Element;
  paymentIssue?: SUPPORTED_PAYMENT_RETRY_OPTION;
};

const DeliveryStatus: React.FC<Props> = ({
  primaryDeliveryStatus,
  secondaryDeliveryStatus,
  thirdDeliveryStatus,
  paymentIssue,
}: Props) => {
  return (
    <>
      <PrimaryDeliveryStatus inheritColor size="large" forwardedAs="p" paymentIssue={paymentIssue}>
        {primaryDeliveryStatus}
      </PrimaryDeliveryStatus>
      {secondaryDeliveryStatus && (
        <SecondaryDeliveryStatus forwardedAs="p" paymentIssue={paymentIssue}>
          {secondaryDeliveryStatus}
        </SecondaryDeliveryStatus>
      )}
      {thirdDeliveryStatus && <ThirdDeliveryStatus>{thirdDeliveryStatus}</ThirdDeliveryStatus>}
    </>
  );
};

export default DeliveryStatus;
