import { Moment } from 'moment';
import classnames from 'classnames';
import React, { FunctionComponent } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { compose } from 'react-recompose';
import Button from '#/components/home/context-cards/button';
import Icon from '#/components/home/context-cards/icon';
import MainText from '#/components/home/context-cards/main-text';
import helpers from '#/lib/decorators/helpers';
import { getLanguage, getLanguageLink } from '#/reducers/app';
import { getDefaultSlotsPath } from '#/reducers/slot';
import { TrolleyState } from '#/custom-typings/redux-store/trolley.defs';
import { getTrolleyShoppingMethod } from '#/selectors/trolley';
import { isOnDemandShoppingMethod } from '#/lib/shopping-method-util';

const mapStateToProps = (state, { f }) => {
  const shoppingMethod = getTrolleyShoppingMethod(state);
  const isOnDemandDelivery = isOnDemandShoppingMethod(shoppingMethod);

  return {
    defaultSlotsPath: getLanguageLink(state, getDefaultSlotsPath(f)(state)),
    language: getLanguage(state),
    shoppingMethod,
    overrideSlotExpiredMessage: isOnDemandDelivery,
    showBasketInfo: isOnDemandDelivery,
  };
};

const connector = connect(mapStateToProps);

type OwnProps = {
  deliveryBy?: { day: string; month: string };
  start?: Moment;
  end?: Moment;
  address: TrolleyState['deliveryAddress'];
  shoppingMethod: string;
  overrideSlotExpiredMessage: boolean;
  showBasketInfo: boolean;
};
type HelperProps = { f: Function; t: Function };
export type Props = OwnProps & HelperProps & ConnectedProps<typeof connector>;

const SlotExpiredContextCardComponent: FunctionComponent<Props> = ({
  deliveryBy,
  start,
  end,
  address,
  t: translate,
  defaultSlotsPath,
  language,
  shoppingMethod,
  showBasketInfo,
  overrideSlotExpiredMessage,
}) => (
  <div className="context-card-book-a-slot--expired">
    <div className="context-card-book-a-slot__top">
      <Icon>
        <div className="icon-bookaslot-01-white" />
      </Icon>
      <h2 className="context-card-book-a-slot__title" data-auto="context-card-expired-title">
        {overrideSlotExpiredMessage
          ? translate(`home:book-a-slot.slot-booked.slot-expired-${shoppingMethod}`)
          : translate('home:book-a-slot.slot-booked.slot-expired')}
      </h2>
      {deliveryBy ? (
        <MainText
          address={address}
          deliveryBy={deliveryBy}
          showDeliverySlotWindow={false}
          showDeliveryType={overrideSlotExpiredMessage}
          shoppingMethod={shoppingMethod}
        />
      ) : (
        <MainText
          address={address}
          start={start}
          end={end}
          showDeliveryType={overrideSlotExpiredMessage}
          shoppingMethod={shoppingMethod}
        />
      )}
      <Button longText={language === 'cs-CZ'} label={translate('slots:common.book-a-new-slot')} to={defaultSlotsPath} />
    </div>
    <div
      className={classnames('context-card-book-a-slot--bottom', {
        'basket--info': showBasketInfo,
      })}
    >
      {showBasketInfo && (
        <p className="context-card-book-a-slot--info-message">
          {translate('home:book-a-slot.slot-booked.items-in-basket-info')}
        </p>
      )}
    </div>
  </div>
);

const enhance = compose<Props, OwnProps>(helpers(['t', 'f']), connector);

export const SlotExpiredContextCard = enhance(SlotExpiredContextCardComponent);
