import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Link from '#/components/link-check-spa';

/** @type {React.ComponentType<any>} */
const Button = React.forwardRef((props, ref) => (
  <div className="action">
    <Link
      data-auto="action-button"
      aria-describedby={props.ariaDescribedBy}
      domRef={ref}
      className={classNames({
        button: true,
        'button-primary': true,
        'long-text': props.longText
      })}
      to={props.to}
      onClick={props.onClick}
    >
      {props.label}
    </Link>
  </div>
));

Button.propTypes = {
  ariaDescribedBy: PropTypes.string,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  longText: PropTypes.bool,
  onClick: PropTypes.func,
  to: PropTypes.string
};

export default Button;
