import generateRandomNumber from './generate-random-number';
import {
  columnIndex,
  getMessageData,
  getCategoryData
} from './propositional-messages';
import source from './source';
import parseTweet from './parse-tweet';

export function generateRandomIndex(region) {
  const messageData = getMessageData(region);
  const msgDataLen = messageData?.content?.length || 0;

  return msgDataLen > 0 ? generateRandomNumber(msgDataLen) : 0;
}

export function findMessageByIndex(index = 0, linkOptions) {
  const region = linkOptions.region;
  const messageData = getMessageData(region);
  const row = messageData.content[index];
  const categoryData = getCategoryData(region);
  const sourceId = row[columnIndex.source];
  const signature = row[columnIndex.signature];
  const categoryId = row[columnIndex.category];
  const category = categoryData[categoryId];
  const { moreInfo, punchLine, url } = category;

  const html =
    sourceId === source.TWITTER
      ? parseTweet(row[columnIndex.message], linkOptions)
      : row[columnIndex.message];

  return { html, moreInfo, punchLine, signature, url };
}
