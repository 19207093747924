import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

/*
 * Handles the width of widgets on the home page
 * Decides the number of columns each widget should take up
 */

class WidgetContainer extends PureComponent {
  static propTypes = {
    children: PropTypes.node,
    doubleLarge: PropTypes.bool,
    doubleMedium: PropTypes.bool,
    element: PropTypes.string,
    widgetName: PropTypes.string.isRequired
  };
  render() {
    const doubleClasses = classnames({
      'double-medium': this.props.doubleMedium,
      'double-large': this.props.doubleLarge
    });

    const Element = this.props.element ? this.props.element : 'div';

    return (
      <Element
        className={`widget-container widget-container--${this.props.widgetName} ${doubleClasses}`}
      >
        <div className="widget-container--item">{this.props.children}</div>
      </Element>
    );
  }
}

export default WidgetContainer;
