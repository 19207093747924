import React from 'react';
import helpers from '#/lib/decorators/helpers';
import { emitWismoEvent } from '#/components/home/context-cards/wismo/shared/analytics';
import DeliveryHeading from '#/components/home/context-cards/wismo/shared/delivery-heading';
import DeliveryStatus from '#/components/home/context-cards/wismo/shared/delivery-status';
import { SlotTimeInline } from '#/components/home/context-cards/wismo/shared/styled';
import { WISMO_PUNCTUALITY, SUPPORTED_WISMO_TRACKING_STATUS } from '#/constants/order-statuses';
import { DELIVERY } from '#/constants/shopping-methods';
import { PunctualityIcon } from './styled';
import DriverOnMapLayout from '../../shared/driver-on-map/layout';
import { useOrderContext } from '#/context/order-context-provider';

type Props = {
  t: (key: string, options?: object) => string;
  showMap?: boolean;
  homeGeoLocation?: {
    latitude?: string;
    longitude?: string;
  };
  driverGeoLocation?: {
    latitude?: string;
    longitude?: string;
  };
  starRating: React.Component;
  display3Steps: boolean;
};

export const DeliveryScheduled: React.FC<Props> = ({ t: translate, showMap, starRating, display3Steps }: Props) => {
  const { order } = useOrderContext();
  const orderNo = order.orderNo;
  const stops = order.wismoSlot?.currentTrackingStatus?.stopsUntilDelivery;
  const punctuality = order.wismoSlot?.currentTrackingStatus?.punctuality;
  const deliveryStart = order.deliveryStart;
  const deliveryEnd = order.deliveryEnd;
  const postcode = order.address.postcode;
  const slotStart = order.slotStart;
  const slotEnd = order.slotEnd;
  const deliveryHeading = <DeliveryHeading postcode={postcode} slotStart={slotStart} slotEnd={slotEnd} />;

  const primaryDeliveryStatus = (
    <>
      <PunctualityIcon graphic="indicator" size="xs" punctuality={punctuality} stops={stops} />
      {stops > 0
        ? translate('context-cards:wismo-stepper-stop', { smart_count: stops })
        : translate('context-cards:wismo-stepper-next-stop')}
      {deliveryStart && deliveryEnd && (
        <>
          {`, ${translate('context-cards:wismo-stepper-estimated-arrival')} `}
          <SlotTimeInline start={deliveryStart} end={deliveryEnd} alt={true} tagName="span" />
        </>
      )}
    </>
  );

  let secondaryDeliveryStatus;

  if (stops > 0) {
    switch (punctuality) {
      case WISMO_PUNCTUALITY.DELAYED:
        secondaryDeliveryStatus = translate('context-cards:wismo-stepper-schedule-late');
        break;

      case WISMO_PUNCTUALITY.ON_TIME:
      case WISMO_PUNCTUALITY.EARLY:
      default:
        secondaryDeliveryStatus = translate('context-cards:wismo-stepper-driver-on-the-way');
    }
  } else {
    secondaryDeliveryStatus = translate('context-cards:wismo-stepper-nearly-arrived');
  }

  const deliveryStatus = (
    <DeliveryStatus primaryDeliveryStatus={primaryDeliveryStatus} secondaryDeliveryStatus={secondaryDeliveryStatus} />
  );

  React.useEffect(
    () =>
      emitWismoEvent(
        DELIVERY,
        { status: SUPPORTED_WISMO_TRACKING_STATUS.DELIVERY_SCHEDULED, punctuality, stopsUntilDelivery: stops },
        orderNo,
      ),
    [],
  );

  return (
    <DriverOnMapLayout
      deliveryHeading={deliveryHeading}
      deliveryStatus={deliveryStatus}
      showMap={showMap}
      starRating={starRating}
      display3Steps={display3Steps}
    />
  );
};

export default helpers(['t'])(DeliveryScheduled);
