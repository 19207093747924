import styled from 'styled-components';
import { spacing } from '@beans/selectors';
import Link from '#/components/link-check-spa';

export const StyledBookAnotherSlotWrapper = styled.div`
  margin: calc(${spacing.sm} / 2);
`;

export const StyledBookAnotherSlot = styled(Link)`
  margin: 0;

  &.button {
    border: 0;
  }
`;
