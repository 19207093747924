import React from 'react';
import { StyledButton, LoadingSpinner } from '#/components/home/context-cards/im-here-button/styled';
import helpers from '#/lib/decorators/helpers';
import { TTranslateFn } from '#/components/slots/delivery-content-section/index.defs';
import ImHereModal from '#/components/modals/im-here-modal';
import imhereReducer, { initialState } from '#/components/home/context-cards/im-here-button/im-here-reducer';
import Spinner from '@ddsweb/spinner';
import { ImHereActionType } from '#/constants/im-here';
import { useAsyncError } from '#/utils/useAsyncError';

type HelperProps = {
  t: TTranslateFn;
};
type Props = {
  orderNo: string;
  locationUuid: string;
  slotStart: string;
  slotEnd: string;
};

export type ImHereButtonProps = HelperProps & Props;

const ImHereButton = helpers(['t'])((props: ImHereButtonProps) => {
  const { t: translate, orderNo, locationUuid, slotStart, slotEnd } = props;
  const [state, dispatch] = React.useReducer(imhereReducer, initialState);
  const throwAsyncError = useAsyncError();
  const handleClose = (): void => dispatch({ type: ImHereActionType.HIDE_IMHERE_MODAL });

  const displayImHereModal = (e: React.MouseEvent<HTMLButtonElement>): void => {
    e.stopPropagation();
    dispatch({ type: ImHereActionType.DISPLAY_IMHERE_MODAL });
  };

  const handleConfirm = (action: ImHereActionType, callback?: (errorHandler: (error: Error) => void) => void): void => {
    dispatch({ type: action });
    if (callback) {
      callback(throwAsyncError);
    }
  };

  return (
    <>
      {state.displayButton && (
        <StyledButton data-auto="im-here-button" block onClick={displayImHereModal}>
          {state.displaySpinner ? (
            <LoadingSpinner>
              <Spinner accessibleLabel={translate('orders:orders-ddl.please-wait')} size="xx" />
            </LoadingSpinner>
          ) : (
            translate('home:book-a-slot.order-placed.im-here')
          )}
        </StyledButton>
      )}
      {state.displayImHereModal && (
        <ImHereModal
          orderNo={orderNo}
          locationUuid={locationUuid}
          closeModal={handleClose}
          confirmHandler={handleConfirm}
          slotStart={slotStart}
          slotEnd={slotEnd}
        />
      )}
    </>
  );
});

export default ImHereButton;
