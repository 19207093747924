import { CREATED, ORDER_DUE, ORDER_DUE_WITH_WISMO, PENDING, RECEIVED } from '#/constants/order-statuses';

export const bookSlotsHomepage = {
  key: '1978',
  featureKey: 'ghs-all_book-multiple-slots-homepage-mweb',
  variationKey: 'experience',
  variants: {
    default: 'default',
    singleBookSlotCTA: 'Single-CTA+BookSlot',
    singleBookSlotCTANoFavs: 'Single-CTA+BookSlot+RemoveBrowseFavs',
  },
  useCustomerId: true,
};

export const BOOK_ANOTHER_SLOT_ORDER_STATUSES = [RECEIVED, CREATED, PENDING, ORDER_DUE_WITH_WISMO, ORDER_DUE];

export const CONTEXT_CARD_STATUSES = {
  UPCOMING_ORDER: 'upcomingorder',
  WISMO: 'wismo',
  DAY_OF_COLLECTION: 'dayofcollection',
  CANCELLED_ORDER: 'cancelledorder',
  PAYMENT_FAILURE: 'paymentfailure',
};
