import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import helpers from '#/lib/decorators/helpers';
import Icon from './icon';
import DeliveryDate from './delivery-date';
import MainText from './main-text';
import ReservedButton from './reserved-button';
import InfoMessage from './info-message';
import { getTimezoneAndLocaleAppliedMoment } from '#/lib/date-helpers';
import { connect } from '#/lib/render/connect-deep-compare';
import { getCurrentUrl, getLanguage, getTimezone } from '#/reducers/app';
import { SlotExpiredContextCard } from '../slot-expired-context-card';
import { isOnDemandShoppingMethod } from '#/lib/shopping-method-util';
import { getTrolleyShoppingMethod } from '#/selectors/trolley';
import { SHOPPING_METHOD_PROP_TYPE } from '#/components/slots/prop-types';

export const mapStateToProps = state => {
  const trolleyShoppingMethod = getTrolleyShoppingMethod(state);
  const isOnDemandDelivery = isOnDemandShoppingMethod(trolleyShoppingMethod);

  return {
    currentUrl: getCurrentUrl(state),
    language: getLanguage(state),
    timezone: getTimezone(state),
    trolleyShoppingMethod,
    showExpiringState: !isOnDemandDelivery,
    overrideReservationMessage: isOnDemandDelivery
  };
};

@helpers(['l', 't', 'c'])
@connect(mapStateToProps)
export default class SlotReservedContextCard extends Component {
  static propTypes = {
    address: PropTypes.object.isRequired,
    c: PropTypes.func,
    currentUrl: PropTypes.string.isRequired,
    l: PropTypes.func,
    language: PropTypes.string.isRequired,
    order: PropTypes.shape({
      cannotPurchaseTrolley: PropTypes.bool.isRequired,
      expiry: PropTypes.string.isRequired,
      isEmpty: PropTypes.bool.isRequired,
      slot: PropTypes.shape({
        start: PropTypes.string.isRequired,
        end: PropTypes.string.isRequired
      })
    }),
    overrideReservationMessage: PropTypes.bool.isRequired,
    showExpiringState: PropTypes.bool.isRequired,
    t: PropTypes.func,
    timezone: PropTypes.string,
    trolleyShoppingMethod: SHOPPING_METHOD_PROP_TYPE.isRequired
  };

  constructor(props) {
    super(props);

    this.state = {
      isSlotExpired: false
    };
  }

  formatDate = date =>
    getTimezoneAndLocaleAppliedMoment(
      date,
      this.props.timezone,
      this.props.language
    );

  getSlotReservedText() {
    const {
      trolleyShoppingMethod,
      t: translate,
      overrideReservationMessage
    } = this.props;

    if (overrideReservationMessage) {
      return translate(
        `home:book-a-slot.slot-booked.slot-reserved-${trolleyShoppingMethod}`
      );
    }
    return translate('home:book-a-slot.slot-booked.slot-reserved');
  }

  slotReservedCard = (isExpiring, timeLeft, start, end, expiry) => {
    const {
      address,
      currentUrl,
      l: languageLink,
      order: { isEmpty, cannotPurchaseTrolley },
      overrideReservationMessage,
      t: translate,
      trolleyShoppingMethod
    } = this.props;
    const slotExpiredCB = () => {
      this.setState({ isSlotExpired: true });
    };

    const title = isExpiring
      ? translate('home:book-a-slot.slot-booked.slot-expiring')
      : this.getSlotReservedText();

    return (
      <div
        className={classnames({
          'context-card-book-a-slot--reserved': !isExpiring,
          'context-card-book-a-slot--expiring': isExpiring
        })}
        data-auto="slot-booked-context-card"
      >
        <div className="context-card-book-a-slot__top">
          <Icon>
            <DeliveryDate date={end} />
          </Icon>
          <h2
            className="context-card-book-a-slot__title"
            data-auto="context-card-reserved-title"
          >
            {title}
          </h2>
          <MainText
            address={address}
            start={start}
            end={end}
            shoppingMethod={trolleyShoppingMethod}
            showDeliveryType={overrideReservationMessage}
          />
          <ReservedButton
            currentUrl={currentUrl}
            isEmpty={isEmpty}
            cannotPurchaseTrolley={cannotPurchaseTrolley}
            l={languageLink}
            t={translate}
          />
        </div>

        <div className="context-card-book-a-slot--bottom">
          <InfoMessage
            expiry={expiry}
            t={translate}
            timeLeft={timeLeft}
            shoppingMethod={trolleyShoppingMethod}
            slotExpiredCB={slotExpiredCB}
          />
        </div>
      </div>
    );
  };

  render() {
    const {
      address,
      c: config,
      order: { expiry, slot },
      showExpiringState
    } = this.props;
    const start = this.formatDate(slot.start);
    const end = this.formatDate(slot.end);
    const expiryTime = this.formatDate(expiry);
    const now = this.formatDate();

    const timeLeft = expiryTime.diff(now, 'seconds');
    const isExpiring =
      showExpiringState &&
      timeLeft < config('minutesBeforeSlotExpiryWarning') * 60;

    return timeLeft > 0 ? (
      this.slotReservedCard(isExpiring, timeLeft, start, end, expiryTime)
    ) : (
      <SlotExpiredContextCard start={start} end={end} address={address} />
    );
  }
}
