import React from 'react';
import { compose } from 'react-recompose';
import { createSPALink } from '@ddsweb/helpers';
import helpers from '#/lib/decorators/helpers';
import { connect } from '#/lib/render/connect-deep-compare';
import { getLanguageLink } from '#/reducers/app';
import { getDefaultSlotsPath } from '#/reducers/slot';
import Link from '#/components/link-check-spa';
import { SlotLink } from './styled';
import { emitBookAnotherSlotEvent } from '#/components/home/context-cards/wismo/shared/analytics';
import { ShoppingMethod } from '#/constants/shopping-methods';

type TProps = {
  t: (key: string, options?: object) => string;
  bookSlotPath: string;
  shoppingMethod: ShoppingMethod;
};

export const BookSlotLink: React.FC<TProps> = ({ t: translate, bookSlotPath, shoppingMethod }) => {
  const bookSlotText = translate('common:pages.book-another-slot');

  const SpaLink = createSPALink(Link, props => ({
    onClick: props.onClick,
    to: props.href,
  }));

  return (
    <SlotLink
      emphasized
      href={bookSlotPath}
      icon={{ graphic: 'forwardLink', position: { global: 'right' } }}
      variant="standalone"
      spaLink={SpaLink}
      onClick={() => emitBookAnotherSlotEvent(shoppingMethod)}
    >
      {bookSlotText}
    </SlotLink>
  );
};

const mapStateToProps = (state, { f: feature }) => ({
  bookSlotPath: getLanguageLink(state, getDefaultSlotsPath(feature)(state)),
});

const enhance: Function = compose(helpers(['t', 'f']), connect(mapStateToProps));

export default enhance(BookSlotLink);
