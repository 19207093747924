/* eslint-disable @typescript-eslint/explicit-function-return-type */
import styled, { css } from 'styled-components';
import { Panel, BackgroundPanel, media } from '@ddsweb/foundation';
import { base, spacing, colors, fontSize } from '@ddsweb/selectors';
import { SUPPORTED_PAYMENT_RETRY_OPTION } from '#/constants/order-statuses';
import { COLLECTION, DELIVERY } from '#/constants/shopping-methods';

function checkForRetryEnabled(retryOption: SUPPORTED_PAYMENT_RETRY_OPTION) {
  if (retryOption === SUPPORTED_PAYMENT_RETRY_OPTION.ENABLED) {
    return true;
  }
  return false;
}

export const Layout = styled(Panel)`
  border-radius: 0;
  && {
    border: 0;
    padding: ${spacing.sm} ${spacing.sm} ${spacing.md};
  }

  & .payment-failed-error-message {
    color: ${colors.text};
    font-size: ${fontSize.xs};
  }
  & .payment-failed-inline-message {
    margin-bottom: ${spacing.sm};
  }
`;
Layout.displayName = 'WismoStepperLayout';

export const DeliveryStatusLayout = styled(BackgroundPanel)`
  display: grid;
  grid-template-columns: auto 1fr;
  grid-template-areas:
    'scooter status'
    'progress progress';

  ${(props: { paymentIssue: SUPPORTED_PAYMENT_RETRY_OPTION | null }): string | void =>
    props.paymentIssue
      ? css`
          padding: ${spacing.md} ${spacing.xs};
          ${media.aboveMobileLarge`
            padding: ${spacing.md} ${spacing.xl};
            & .vehicle-icon {
              align-self: center;
            };
          `};
        `
      : css`
          padding: ${spacing.xl} ${spacing.sm};
          ${media.aboveTabletLarge`
            padding: ${spacing.xxxl} ${spacing.xl};
          `};
        `};

  border: ${base.outline};
`;
DeliveryStatusLayout.displayName = 'WismoStepperDeliveryStatusLayout';

const WrapperWithMapStyles = css`
  & [aria-current='false'] {
    display: none;
  }

  & [aria-current='step'] {
    white-space: nowrap;
  }
`;

const WrapperWithoutMapStyles = css`
  & [aria-current='false'] {
    display: none;
    ${media.aboveDesktop`
        display: block;
    `}
  }
`;

export const Wrapper = styled.div`
  & .context-card-wismo__star-rating {
    grid-area: rating;
  }

  & .map {
    grid-area: map;
    height: 200px;
    ${media.aboveDesktop`
      height: auto
  `}
  }

  & .stepper {
    grid-area: stepper;
  }

  & .scooter,
  .truck {
    grid-area: scooter;
    ${(props: { paymentIssue: boolean }): string =>
      props.paymentIssue &&
      css`
        margin-right: 0px;
      `};
  }

  & .delivery-status {
    grid-area: status;
  }

  & ol[role='progressbar'] {
    grid-area: progress;
    margin-top: ${(props: { paymentIssue: boolean }): string =>
      props.paymentIssue
        ? css`
            ${spacing.md};
          `
        : css`
            ${spacing.lg};
          `};
    ${media.aboveTabletLarge`
      margin-top: ${spacing.md};
    `}
  }

  ${media.aboveDesktop`
  ${({ showMap }: { showMap: boolean }): unknown =>
    showMap &&
    css`
      display: grid;
      grid-template-columns: auto 50%;
      grid-auto-flow: column;
      column-gap: 14px;
      grid-template-areas: 'stepper map' 'rating map';
      .vehicle-icon {
        display: none;
      }
    `}
`}
  ${props => (props.showMap ? WrapperWithMapStyles : WrapperWithoutMapStyles)};
`;

export const PaymentIssueMainBody = styled.div`
  display: block;
  margin-left: ${spacing.xs};
  ${(props: { paymentIssue: SUPPORTED_PAYMENT_RETRY_OPTION | null }): string | void => {
    if (props.paymentIssue) {
      if (checkForRetryEnabled(props.paymentIssue)) {
        return css`
          ${media.aboveTabletLarge`
            margin-left: ${spacing.lg};
          `}

          & .delivery-status {
            padding-top: ${spacing.sm};
            ${media.aboveTabletLarge`
              padding-top: ${spacing.xxxl};
            `}
          }
        `;
      } else {
        return css`
          ${media.aboveTabletLarge`
            margin-left: ${spacing.md};
          `}
        `;
      }
    }
  }};

  & .place-order-again-standalone {
    font-size: ${fontSize.xs};
  }
`;

export const PaymentIssueErrorLayout = styled.div`
  & .update-payment-form {
    display: none;
  }
  ${(props: { shoppingMethod: string }): string | void => {
    if (props.shoppingMethod === DELIVERY) {
      return css`
        padding-top: ${spacing.md};
        padding-bottom: ${spacing.md};
        display: grid;
        white-space: wrap;
        grid-template-areas:
          'a a'
          'b b';
        row-gap: ${spacing.md};
        column-gap: ${spacing.md};
        align-items: start;

        ${media.aboveMobileLarge`
          grid-template-areas: 
            "a b"
            "a b";
        `};

        ${media.aboveTablet`
          grid-template-areas: 
            "a a"
            "b b";
        `};

        ${media.aboveTabletLarge`
          grid-template-areas: 
            "a b"
            "a b";
        `};

        & .payment-issue-inline-message {
          grid-area: a;
        }

        & .payment-issue-error-message {
          color: ${colors.text};
        }

        & .update-payment-button {
          grid-area: b;
          height: auto;
          width: auto;
        }
      `;
    } else {
      return css`
        display: flex;
        flex-direction: column;

        & .payment-issue-inline-message {
          margin-top: ${spacing.sm};
        }

        & .update-payment-button {
          margin-top: ${spacing.sm};
        }

        ${media.aboveMobileLarge`
          & .update-payment-button {
            width: fit-content;
          }
        `};
      `;
    }
  }})}
`;

export const DeliveryHeadingWrapper = styled.div`
  ${(props: { shoppingMethod: string }): string | void => {
    if (props.shoppingMethod === COLLECTION) {
      return css`
        margin-top: ${spacing.xx};
      `;
    }
  }}
`;

PaymentIssueErrorLayout.displayName = 'WismoStepperPaymentIssueErrorLayout';
