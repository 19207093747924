export const getDistanceBetweenCoordinates = (
  driverGeoLocation,
  destinationGeoLocation
) => {
  if (!driverGeoLocation || !destinationGeoLocation) return undefined;
  const { latitude: latitudeA, longitude: longitudeA } = driverGeoLocation;
  const { latitude: latitudeB, longitude: longitudeB } = destinationGeoLocation;
  let theta = longitudeA - longitudeB;
  let dist =
    Math.sin(deg2rad(latitudeA)) * Math.sin(deg2rad(latitudeB)) +
    Math.cos(deg2rad(latitudeA)) *
      Math.cos(deg2rad(latitudeB)) *
      Math.cos(deg2rad(theta));
  dist = Math.acos(dist);
  dist = rad2deg(dist);
  dist *= 60 * 1.1515;
  return dist;
};

function deg2rad(deg) {
  return (deg * Math.PI) / 180.0;
}

function rad2deg(rad) {
  return (rad * 180.0) / Math.PI;
}
