import React from 'react';
import { connect } from '#/lib/render/connect-deep-compare';
import helpers from '#/lib/decorators/helpers';
import { getOnDemandDynamicDeliveryData } from '#/lib/shopping-method-util';
import DeliveryHeading from '#/components/home/context-cards/wismo/shared/delivery-heading';
import DeliveryStatus from '#/components/home/context-cards/wismo/shared/delivery-status';
import BookSlotLink from '#/components/home/context-cards/wismo/shared/book-slot-link';
import CancelledContextCard from '#/components/home/cancelled-context-card';
import { SlotTimeInline } from '#/components/home/context-cards/wismo/shared/styled';
import { emitWismoEvent } from '#/components/home/context-cards/wismo/shared/analytics';
import { getFulfilmentEstimatedTime } from '#/selectors/slot';
import { ON_DEMAND } from '#/constants/shopping-methods';
import { SUPPORTED_WISMO_TRACKING_STATUS } from '#/constants/order-statuses';
import { TOnDemandWismoStepperProps } from '../types';
import { DynamicDeliveryTime } from '#/custom-typings/redux-store/slot.defs';
import { TTranslateFunc } from '#/lib/records/helpers.defs';
import DriverOnMapLayout from '../../shared/driver-on-map/layout';
import { getIsAmongSlotBookingVariants } from '#/experiments/oop-1978/selectors';

type TOwnProps = {
  t: TTranslateFunc;
};

type TOwnState = {
  fulfilmentEstimatedTime: DynamicDeliveryTime;
  showBookASlotButton: boolean;
};

const mapStateToProps = (state: Store): TOwnState => ({
  fulfilmentEstimatedTime: getFulfilmentEstimatedTime(state),
  showBookASlotButton: !getIsAmongSlotBookingVariants(state),
});

type TStepsTemplateProps = TOwnState & TOwnProps & TOnDemandWismoStepperProps;

export const StepsTemplate: React.FC<TStepsTemplateProps> = ({
  t: translate,
  orderNo,
  postcode,
  status: currentStatus,
  deliveryStart,
  deliveryEnd,
  fulfilmentEstimatedTime,
  showMap,
  homeGeoLocation,
  driverGeoLocation,
  starRating,
  shoppingMethod,
  showBookASlotButton,
}) => {
  if (currentStatus === SUPPORTED_WISMO_TRACKING_STATUS.CANCELLED) {
    return (
      <CancelledContextCard
        orderNo={orderNo}
        postcode={postcode}
        slotStart={deliveryStart}
        slotEnd={deliveryEnd}
        starRating={starRating}
        shoppingMethod={shoppingMethod}
      />
    );
  }

  const localeMappedStatus = currentStatus.replace(/_/g, '-').toLowerCase();
  const deliveryHeading = <DeliveryHeading postcode={postcode} shoppingMethod={ON_DEMAND} />;

  const showDeliveryToday = true; //TODO: Remove this once API is ready with order specific dynamic deliverytime
  const { onDemandDynamicDeliverySuffix, unit, min, max } = getOnDemandDynamicDeliveryData(
    true,
    true,
    fulfilmentEstimatedTime,
    showDeliveryToday,
  );
  const primaryDeliveryStatus = [
    SUPPORTED_WISMO_TRACKING_STATUS.DELIVERY_SCHEDULED,
    SUPPORTED_WISMO_TRACKING_STATUS.ARRIVED_AT_CUSTOMER_LOCATION,
  ].includes(currentStatus) ? (
    <SlotTimeInline start={deliveryStart} end={deliveryEnd} alt={true} tagName="span" />
  ) : currentStatus !== SUPPORTED_WISMO_TRACKING_STATUS.DELIVERED ? (
    translate(
      `context-cards:ondemand-wismo-stepper.steps.${localeMappedStatus}.primary${onDemandDynamicDeliverySuffix}`,
      {
        unit,
        min,
        max,
      },
    )
  ) : (
    translate(`context-cards:ondemand-wismo-stepper.steps.${localeMappedStatus}.primary`)
  );
  const secondaryDeliveryStatus = (
    <>
      {translate(`context-cards:ondemand-wismo-stepper.steps.${localeMappedStatus}.secondary`)}
      {showBookASlotButton && currentStatus === SUPPORTED_WISMO_TRACKING_STATUS.DELIVERED && (
        <BookSlotLink shoppingMethod={ON_DEMAND} />
      )}
    </>
  );

  const deliveryStatus = (
    <DeliveryStatus primaryDeliveryStatus={primaryDeliveryStatus} secondaryDeliveryStatus={secondaryDeliveryStatus} />
  );
  React.useEffect(() => emitWismoEvent(ON_DEMAND, { status: currentStatus }, orderNo), []);
  return (
    <DriverOnMapLayout
      deliveryHeading={deliveryHeading}
      deliveryStatus={deliveryStatus}
      status={currentStatus}
      shoppingMethod={shoppingMethod}
      showMap={showMap}
      homeGeoLocation={homeGeoLocation}
      driverGeoLocation={driverGeoLocation}
      starRating={starRating}
    />
  );
};

export default helpers(['t'])(connect(mapStateToProps)(StepsTemplate));
