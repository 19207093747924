import styled, { css } from 'styled-components';
import { Text } from '@ddsweb/text';
import Link from '@ddsweb/link';
import { fontSize, spacing } from '@ddsweb/selectors';

export const PrimaryDeliveryStatus = styled(Text)`
  && {
    margin-bottom: 6px; 
    font-weight: bold;
    ${(props: { paymentIssue: boolean }): void =>
      props.paymentIssue &&
      css`
        margin-bottom: ${spacing.xs};
        font-size: ${fontSize.md};
      `}
`;

PrimaryDeliveryStatus.displayName = 'WismoStepperPrimaryDeliveryStatus';

export const SecondaryDeliveryStatus = styled(Text)`
  margin-bottom: ${(props: { paymentIssue: boolean }): string =>
    props.paymentIssue
      ? css`
          ${spacing.sm};
        `
      : css`
          ${spacing.xs};
        `};
`;
SecondaryDeliveryStatus.displayName = 'WismoStepperSecondaryDeliveryStatus';

export const ThirdDeliveryStatus = styled(Link)`
  font-weight: bold;
  font-size: ${fontSize.xs};
`;
ThirdDeliveryStatus.displayName = 'WismoStepperThirdDeliveryStatus';
