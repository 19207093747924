import React from 'react';
import PropTypes from 'prop-types';
import Link from '#/components/link-check-spa';
import { getCheckoutEntryPoint } from '../../../utils/checkout-utils';
import analyticsBus from '../../../analytics/analyticsBus';
import { basicEvent } from '../../../analytics/types/basic';
import { CONTEXT_CARD, CHECKOUT, DELAY } from '../../../analytics/constants';
import helpers from '#/lib/decorators/helpers';
import { connect } from '../../../lib/render/connect-deep-compare';
import { getDefaultSlotsPath } from '../../../reducers/slot';

export const mapStateToProps = (state, { f }) => ({
  checkoutEntryPoint: getCheckoutEntryPoint(state),
  defaultSlotsPath: getDefaultSlotsPath(f)(state)
});

function emitCheckoutAnalyticsEvent() {
  basicEvent(analyticsBus, {
    type: CONTEXT_CARD,
    value: CHECKOUT,
    action: DELAY
  });
}

const ReservedButton = props => {
  if (props.isEmpty || props.cannotPurchaseTrolley) {
    return (
      <div className="action">
        <Link
          className={'button button-secondary'}
          to={props.l(props.defaultSlotsPath)}
          data-auto="action-button"
        >
          {props.t('home:book-a-slot.change-slot')}
        </Link>
      </div>
    );
  } else {
    return (
      <div className="action">
        <Link
          className={'button button-primary'}
          to={props.checkoutEntryPoint}
          onClick={emitCheckoutAnalyticsEvent}
          data-auto="action-button"
        >
          {props.t('home:book-a-slot.checkout')}
        </Link>
      </div>
    );
  }
};

ReservedButton.propTypes = {
  cannotPurchaseTrolley: PropTypes.bool,
  checkoutEntryPoint: PropTypes.string.isRequired,
  currentUrl: PropTypes.string.isRequired,
  defaultSlotsPath: PropTypes.string.isRequired,
  isEmpty: PropTypes.bool,
  l: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired
};

export default helpers(['f'])(connect(mapStateToProps)(ReservedButton));
