import React from 'react';
import { SUPPORTED_WISMO_TRACKING_STATUS } from '#/constants/order-statuses';
import StepsTemplate from './steps-template';
import { TOnDemandWismoStepperProps } from './types';

const OnDemandWismoCard: React.FC<TOnDemandWismoStepperProps> = props => {
  const supportedStatuses = [
    SUPPORTED_WISMO_TRACKING_STATUS.PREPARING_ORDER,
    SUPPORTED_WISMO_TRACKING_STATUS.DELIVERY_SCHEDULED,
    SUPPORTED_WISMO_TRACKING_STATUS.ARRIVED_AT_CUSTOMER_LOCATION,
    SUPPORTED_WISMO_TRACKING_STATUS.DELIVERED,
    SUPPORTED_WISMO_TRACKING_STATUS.NOT_AVAILABLE,
    SUPPORTED_WISMO_TRACKING_STATUS.CANCELLED,
  ];

  return supportedStatuses.includes(props.status) ? <StepsTemplate {...props} /> : null;
};

export default OnDemandWismoCard;
