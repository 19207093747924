import React from 'react';
import { ConnectedProps } from 'react-redux';
import { connect } from '#/lib/render/connect-deep-compare';
import helpers from '#/lib/decorators/helpers';
import { getCurrency, getLanguageLink } from '#/reducers/app';
import DriverOnMap from '.';
import ProgressBar from '../progress-bar';
import UpdatePaymentDetailsButton from '../update-payment-details-button/index';
import { useOrderContext } from '#/context/order-context-provider';
import { ON_DEMAND, DELIVERY, COLLECTION } from '#/constants/shopping-methods';
import { getSteps, mapStatusToStepNumber } from './utils';
import { CANCELLED, SUPPORTED_PAYMENT_RETRY_OPTION } from '#/constants/order-statuses';
import { InLineMessaging } from '@ddsweb/messaging';
import { Text } from '@ddsweb/text';
import { StandaloneLink } from '@ddsweb/link';
import {
  Layout,
  DeliveryStatusLayout,
  Wrapper,
  PaymentIssueMainBody,
  PaymentIssueErrorLayout,
  DeliveryHeadingWrapper,
} from './styled';
import { SlotTimeInline } from '../styled';
import { TCurrency } from '#/custom-typings/redux-store/app.defs';

const mapStateToProps = (state: Store): object => {
  return {
    ordersURL: getLanguageLink(state, `/orders/`),
    currency: getCurrency(state),
  };
};

const connector = connect(mapStateToProps);

type TOwnProps = {
  t: (key: string, options?: object) => string;
  deliveryHeading: JSX.Element;
  deliveryStatus?: JSX.Element;
  starRating?: React.Component;
  showMap?: boolean;
  display3Steps?: boolean;
  ordersURL: string;
  currency: TCurrency;
};

type TProps = TOwnProps & ConnectedProps<typeof connector>;

export const DriverOnMapLayout: React.FC<TProps> = ({
  t: translate,
  ordersURL,
  deliveryHeading,
  showMap,
  display3Steps,
  deliveryStatus,
  starRating,
  currency,
}) => {
  const { order } = useOrderContext();
  const isCancelled = order.status === CANCELLED;
  const shoppingMethod = order.shoppingMethod;
  const status = order.wismoSlot?.currentTrackingStatus?.status;
  const driverGeoLocation = order.wismoSlot?.currentTrackingStatus?.geoLocation;
  const homeGeoLocation = order.wismoSlot?.destination?.geoLocation;
  const totalPrice = order.payment?.amount;
  const retryOption = order.payment?.retryOption;
  const retryEnabled = retryOption === SUPPORTED_PAYMENT_RETRY_OPTION.ENABLED;
  const retryExpired = retryOption === SUPPORTED_PAYMENT_RETRY_OPTION.EXPIRED;
  const paymentIssue = retryEnabled || retryExpired;
  const steps = getSteps(display3Steps, order.shoppingMethod, paymentIssue);
  const currentStep = mapStatusToStepNumber(status, display3Steps, paymentIssue);
  const progressBar = currentStep && (
    <ProgressBar fullWidth steps={steps} currentStep={currentStep} paymentIssue={paymentIssue} />
  );
  const hideStepper = retryEnabled && shoppingMethod === COLLECTION;

  const renderPaymentIssueMainBody = (): JSX.Element => {
    return (
      <PaymentIssueMainBody paymentIssue={paymentIssue ? retryOption : null}>
        <div className="payment-issue-title">
          <div className="delivery-status">{deliveryStatus}</div>
        </div>
        {retryExpired && (
          <StandaloneLink
            className="place-order-again-standalone"
            href={`${ordersURL}${order.orderNo}`}
            icon={{ graphic: 'forwardLink', position: { global: 'right' } }}
            emphasized={true}
          >
            {translate('context-cards:wismo-context-card-place-order-again')}
          </StandaloneLink>
        )}
      </PaymentIssueMainBody>
    );
  };

  const renderPaymentIssueRetryEnabled = (): JSX.Element => {
    return (
      <div className="payment-issue-error">
        <PaymentIssueErrorLayout shoppingMethod={shoppingMethod} className="payment-issue-error-layout">
          {shoppingMethod === COLLECTION && renderAddressTotalDetails()}
          <InLineMessaging variant="error" className="payment-issue-inline-message">
            <Text className="payment-issue-error-message">
              {translate('context-cards:wismo-context-card-payment-issue-error-message')}
            </Text>
          </InLineMessaging>
          <UpdatePaymentDetailsButton className="update-payment-button" />
        </PaymentIssueErrorLayout>
      </div>
    );
  };

  const renderAddressTotalDetails = (): JSX.Element => {
    return (
      <div>
        {order.slotStart?.format('ddd D MMM')}
        {': '}
        <SlotTimeInline paymentIssue={true} start={order.slotStart} end={order.slotEnd} alt={true} tagName="span" />
        <div>{`${order.address?.name}, ${order.address?.postcode}`}</div>
        <div>
          <span>{translate('home:total')}</span>
          <span style={{ fontWeight: 'bold' }}>
            {currency.symbol}
            {totalPrice}
          </span>
        </div>
      </div>
    );
  };

  const renderVehicle = (): JSX.Element => {
    return (
      <div
        className={
          order.shoppingMethod === ON_DEMAND
            ? 'scooter vehicle-icon'
            : order.shoppingMethod === COLLECTION
            ? 'car vehicle-icon'
            : 'truck vehicle-icon'
        }
      />
    );
  };

  return (
    <Layout variant="border">
      {retryExpired && (
        <InLineMessaging variant="error" className="payment-failed-inline-message">
          <Text className="payment-failed-error-message">
            {shoppingMethod === DELIVERY
              ? translate('context-cards:wismo-context-card-payment-failed-error-message')
              : translate('context-cards:wismo-context-card-payment-failed-error-message-cc')}
          </Text>
        </InLineMessaging>
      )}
      <DeliveryHeadingWrapper shoppingMethod={shoppingMethod}>{deliveryHeading}</DeliveryHeadingWrapper>
      <Wrapper showMap={showMap} paymentIssue={paymentIssue}>
        {!hideStepper && (
          <div className="stepper">
            <DeliveryStatusLayout
              className={showMap ? 'map-visible' : ''}
              variant="background"
              paymentIssue={paymentIssue}
            >
              {renderVehicle()}

              {paymentIssue && shoppingMethod !== ON_DEMAND ? (
                renderPaymentIssueMainBody()
              ) : (
                <>
                  <div className="delivery-status">{deliveryStatus}</div>
                  {!isCancelled && progressBar}
                </>
              )}
              {retryEnabled && progressBar}
            </DeliveryStatusLayout>
          </div>
        )}

        {showMap && homeGeoLocation && driverGeoLocation && (
          <>
            <div className="map">
              <DriverOnMap
                homeGeoLocation={homeGeoLocation}
                driverGeoLocation={driverGeoLocation}
                shoppingMethod={order.shoppingMethod}
              />
            </div>
          </>
        )}
        {retryEnabled && shoppingMethod !== ON_DEMAND && <div>{renderPaymentIssueRetryEnabled()}</div>}

        {starRating && (
          <div className="context-card-wismo__star-rating" data-auto="context-card-wismo-star-rating">
            {starRating}
          </div>
        )}
      </Wrapper>
    </Layout>
  );
};

export default connect(mapStateToProps)(helpers(['t'])(DriverOnMapLayout));
