import React from 'react';
import Icon from '@ddsweb/icon';
import { StepErrorIcon } from '@ddsweb/icon';
import helpers from '#/lib/decorators/helpers';
import { Wrapper, Step, StepMarker, StepText } from './styled';

type Props = {
  steps: { title: string }[];
  currentStep: number;
  t: (key: string, options?: object) => string;
  fullWidth?: boolean;
  paymentIssue?: boolean;
};

export const ProgressBar = ({ steps, currentStep, t: translate, fullWidth, paymentIssue = false }: Props) => {
  return (
    <Wrapper currentStep={currentStep}>
      {steps.map((step, index) => {
        const stepNo = index + 1;
        const isComplete = stepNo <= currentStep && !paymentIssue;
        const isCurrentStep = stepNo === currentStep;

        const accessibilityText = isComplete
          ? 'context-cards:wismo-stepper-step-completed'
          : 'context-cards:wismo-stepper-step-not-completed';

        return (
          <Step fullWidth={fullWidth} key={translate(step.title)} isComplete={isComplete} noOfSteps={steps.length}>
            <span className="visually-hidden">{translate(accessibilityText)}</span>
            <StepMarker isComplete={isComplete}>
              {paymentIssue && stepNo == 1 ? <StepErrorIcon /> : <Icon graphic="benefits" size="xs" inverse={true} />}
            </StepMarker>
            <StepText
              headingLevel="5"
              stepNo={stepNo}
              isComplete={isComplete}
              isCurrentStep={isCurrentStep}
              noOfSteps={steps.length}
              aria-current={isCurrentStep && 'step'}
              paymentIssue={paymentIssue}
            >
              {translate(step.title)}
            </StepText>
          </Step>
        );
      })}
    </Wrapper>
  );
};

export default helpers(['t'])(ProgressBar);
