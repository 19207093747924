import React from 'react';
import helpers from '#/lib/decorators/helpers';
import StarRating from '#/components/star-rating';
import { RATING_STARS } from '#/constants/order-context-card';
import { getActiveExperimentsFromWindow } from '#/lib/open-feedback-window';
import analyticsBus from '#/analytics/analyticsBus';
import { basicEvent } from '#/analytics/types/basic';
import { NOW } from '#/analytics/constants';
import { emitStarRatingEvent } from '#/components/home/context-cards/wismo/shared/analytics';
import { ShoppingMethod } from '#/constants/shopping-methods';
import { isOnDemandShoppingMethod } from '#/lib/shopping-method-util';

type CurrentTrackingStatus = {
  punctuality: string;
  status: string;
};

type Props = {
  shoppingMethod: ShoppingMethod;
  customerVariables: object;
  currentTrackingStatus: CurrentTrackingStatus;
  showWismoStepper: boolean;
  t: (key: string, options?: object) => string;
};

const WismoStarRating = ({
  shoppingMethod,
  currentTrackingStatus,
  showWismoStepper,
  customerVariables,
  t: translate,
}: Props): JSX.Element => {
  const ratingLabels: string[] = [
    translate('context-cards:star-rating-radio-1'),
    translate('context-cards:star-rating-radio-2'),
    translate('context-cards:star-rating-radio-3'),
    translate('context-cards:star-rating-radio-4'),
    translate('context-cards:star-rating-radio-5'),
  ];

  const legendText: string = isOnDemandShoppingMethod(shoppingMethod)
    ? translate('context-cards:feedback-stars-ondemand-legend')
    : translate('context-cards:feedback-stars-legend');

  const onClick = (star: string): void => {
    const customerVariablesWithStar = {
      ...customerVariables,
      overall_rating: star,
    };

    const value = currentTrackingStatus?.punctuality
      ? `wismo ${currentTrackingStatus.punctuality.toLowerCase()}`
      : 'wismo';

    if (showWismoStepper) {
      const trackingInfo = isOnDemandShoppingMethod(shoppingMethod)
        ? { status: currentTrackingStatus?.status }
        : currentTrackingStatus;

      emitStarRatingEvent(star, trackingInfo, shoppingMethod);
    } else {
      basicEvent(analyticsBus, {
        type: `${star} star`,
        value,
        action: NOW,
      });
    }

    window.OOo.oo_launchStarRating({
      ...customerVariablesWithStar,
      ...getActiveExperimentsFromWindow(),
    });
  };

  return (
    <StarRating
      name={RATING_STARS}
      inlineDescriptors
      ratingLabels={ratingLabels}
      legendText={legendText}
      onClick={onClick}
    />
  );
};

export default helpers(['t'])(WismoStarRating);
