import React, { Component } from 'react';
import PropTypes from 'prop-types';
import analyticsBus from '#/analytics/analyticsBus';
import { COLLECTION } from '#/constants/shopping-methods';
import helpers from '#/lib/decorators/helpers';
import OrderCreatedContextCard from '#/components/home/context-cards/order-created-context-card';
import OrderDueContextCard from '#/components/home/context-cards/order-due-context-card';
import WismoContextCard from '#/components/home/context-cards/wismo/wismo-context-card';
import AmendOrderContextCard from '#/components/home/amend-order-context-card';
import OrderAmendedContextCard from '#/components/home/context-cards/order-amended-context-card';
import SlotReservedContextCard from '#/components/home/context-cards/slot-reserved-context-card';
import NoSlotBookedContextCard from '#/components/home/context-cards/no-slot-booked-context-card';
import { connect } from '#/lib/render/connect-deep-compare';
import { hasItems } from '#/lib/trolley/trolley-utils';
import {
  CREATED,
  ORDER_DUE,
  RESERVED,
  EXPIRED,
  BOOKED,
  RECEIVED,
  PENDING,
  AMENDED,
  ORDER_DUE_WITH_WISMO,
  SUPPORTED_PAYMENT_RETRY_OPTION,
  FAILED_PAYMENT_COLLECTION
} from '#/constants/order-statuses';
import { getItems } from '#/selectors/trolley';
import { getLanguage, getTimezone } from '#/reducers/app';
import { getTimezoneAndLocaleAppliedMoment } from '#/lib/date-helpers';
import PaymentFailed from '../context-cards/wismo/components/payment-failed';
import { getIsAmongSlotBookingVariants } from '#/experiments/oop-1978/selectors';
import BookSlotButton from '#/experiments/oop-1978/components/book-slot';
import { CONTEXT_CARD_STATUSES } from '#/experiments/oop-1978/constants';

const mapStateToProps = (state, { c: config }) => {
  return {
    items: getItems(state),
    language: getLanguage(state),
    timezone: getTimezone(state),
    displayAsGuidePrice: config('displayAsGuidePrice'),
    checkoutInAmend: config('checkout:enableInAmendMode'),
    showBookASlotButton: getIsAmongSlotBookingVariants(state)
  };
};

@helpers(['c'])
@connect(mapStateToProps)
export default class BookASlotContextCardHome extends Component {
  static propTypes = {
    address: PropTypes.object,
    c: PropTypes.func.isRequired,
    displayAsGuidePrice: PropTypes.bool.isRequired,
    items: PropTypes.array,
    language: PropTypes.string,
    order: PropTypes.shape({
      address: PropTypes.object,
      expiry: PropTypes.string,
      id: PropTypes.string,
      orderNo: PropTypes.string,
      shoppingMethod: PropTypes.string,
      slot: PropTypes.object,
      wismoSlot: PropTypes.shape({
        deliveryWindow: PropTypes.shape({
          end: PropTypes.string.isRequired,
          start: PropTypes.string.isRequired
        })
      }),
      status: PropTypes.string,
      guidePrice: PropTypes.number,
      showWismoStepper: PropTypes.bool,
      payment: PropTypes.shape({
        retryOption: PropTypes.oneOf([
          SUPPORTED_PAYMENT_RETRY_OPTION.ENABLED,
          SUPPORTED_PAYMENT_RETRY_OPTION.EXPIRED,
          SUPPORTED_PAYMENT_RETRY_OPTION.NOT_APPLICABLE
        ])
      })
    }),
    timezone: PropTypes.string.isRequired
  };

  slot = this.props.order?.slot;
  wismoSlot = this.props.order?.wismoSlot;

  formatDate = date =>
    getTimezoneAndLocaleAppliedMoment(
      date,
      this.props.timezone,
      this.props.language
    );

  render() {
    return (
      <section className="context-card-book-a-slot">
        {this.getContent()}
      </section>
    );
  }

  fireContextCardAnalytics = cardName => {
    analyticsBus().emit('UIEventBasicEvent', {
      action: 'now',
      type: 'slots',
      value: `contextcard:${cardName}:impression`
    });
  };

  getOrderDueContextCard = () => {
    const { displayAsGuidePrice, order } = this.props;

    const {
      shoppingMethod,
      address,
      guidePrice,
      id,
      slot,
      orderNo,
      locationUuid
    } = order;

    return (
      <OrderDueContextCard
        displayAsGuidePrice={displayAsGuidePrice}
        address={address}
        guidePrice={guidePrice}
        orderId={id}
        orderNo={orderNo}
        slot={slot}
        locationUuid={locationUuid}
        shoppingMethod={shoppingMethod}
      />
    );
  };

  getWismoContextCard = () => {
    const { order } = this.props;
    const {
      shoppingMethod,
      slot,
      address,
      guidePrice,
      orderNo,
      wismoSlot,
      showWismoStepper,
      locationUuid
    } = order;

    return (
      <WismoContextCard
        shoppingMethod={shoppingMethod}
        slot={slot}
        address={address}
        guidePrice={guidePrice}
        orderNo={orderNo}
        wismoSlot={wismoSlot}
        showWismoStepper={showWismoStepper}
        locationUuid={locationUuid}
      />
    );
  };

  getContent() {
    const {
      order,
      c: config,
      checkoutInAmend,
      items,
      address,
      showBookASlotButton
    } = this.props;

    const NoSlotBookedContextCardComponent = showBookASlotButton
      ? BookSlotButton
      : NoSlotBookedContextCard;

    if (order !== null) {
      const now = this.formatDate();
      const expiryDate = this.formatDate(order.expiry);
      const isDue = now.isAfter(expiryDate);
      const status =
        (order.status === CREATED || order.status === RECEIVED) && isDue
          ? ORDER_DUE
          : order.status;
      const hasTrolleyItems = hasItems(items);

      switch (status) {
        case FAILED_PAYMENT_COLLECTION:
          this.fireContextCardAnalytics(CONTEXT_CARD_STATUSES.PAYMENT_FAILURE);
          return <PaymentFailed />;

        case RESERVED:
        case EXPIRED:
        case BOOKED:
          return <SlotReservedContextCard address={address} order={order} />;

        case RECEIVED:
        case CREATED:
        case PENDING:
          this.fireContextCardAnalytics(CONTEXT_CARD_STATUSES.UPCOMING_ORDER);
          return <OrderCreatedContextCard order={order} />;

        case AMENDED:
          return (
            <>
              {config('enableAmendModeModal') && !checkoutInAmend ? (
                <OrderAmendedContextCard
                  order={order}
                  hasTrolleyItems={hasTrolleyItems}
                />
              ) : (
                <AmendOrderContextCard order={order} address={address} />
              )}
            </>
          );
        case ORDER_DUE_WITH_WISMO:
          this.fireContextCardAnalytics(CONTEXT_CARD_STATUSES.WISMO);
          return this.getWismoContextCard();

        case ORDER_DUE:
          this.fireContextCardAnalytics(
            order.shoppingMethod === COLLECTION
              ? CONTEXT_CARD_STATUSES.DAY_OF_COLLECTION
              : CONTEXT_CARD_STATUSES.UPCOMING_ORDER
          );
          return this.getOrderDueContextCard();

        default:
          return <NoSlotBookedContextCardComponent />;
      }
    } else {
      return <NoSlotBookedContextCardComponent />;
    }
  }
}
