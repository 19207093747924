import styled from 'styled-components';
import Link from '@ddsweb/link';
import { spacing } from '@ddsweb/selectors';

export const SlotLink = styled(Link)`
  && {
    display: block;
    margin-top: ${spacing.sm};
  }

  && .beans-link__icon-container {
    vertical-align: bottom;
  }
`;
SlotLink.displayName = 'WismoStepperSlotLink';
