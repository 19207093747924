import { logApmError } from '#/lib/apm';
import { deliveryTrackingMocks } from '#/mock-data/delivery-tracking';
import { clientGraphqlPost } from './client-graphql';
import uuidv4 from 'uuid/v4';
import { GET_TRIP_TRACKING } from '#/constants/request-names';

const GET_TRIP_TRACKING_QUERY = `
query GetTripTracking($tripTrackingId: String!) {
  deliveryTracking(tripTrackingId: $tripTrackingId) {
    tripTrackingId
    deliveryWindow {
      start
      end
    }
    currentTrackingStatus {
      status
      punctuality
      stopsUntilDelivery
      geoLocation {
        latitude
        longitude
      }
    }
    destination {
      geoLocation {
        latitude
        longitude
      }
    }
  }
}
`;
export default async deliveryTrackingRequestData => {
  const {
    atrc,
    apiKey,
    region,
    endpoint,
    wismoSlot
  } = deliveryTrackingRequestData;
  const uuid = uuidv4();
  const traceId = `${atrc}:${uuid}`;
  const context = { 'x-apikey': apiKey, traceId, region };
  const queryVariables = {
    tripTrackingId: wismoSlot?.tripTrackingId
  };
  let response = null;
  let orderStatusFromSessionStorage = null;

  if (typeof window !== 'undefined') {
    const wismoStatus = window?.sessionStorage?.getItem('wismo-test-status');
    if (wismoStatus) {
      orderStatusFromSessionStorage = JSON.parse(wismoStatus);
    }
  }

  try {
    if (orderStatusFromSessionStorage) {
      response =
        deliveryTrackingMocks[orderStatusFromSessionStorage.toUpperCase()];
    } else {
      const requestName = GET_TRIP_TRACKING;

      response = await clientGraphqlPost(
        endpoint,
        context,
        GET_TRIP_TRACKING_QUERY,
        queryVariables,
        atrc,
        requestName,
        traceId
      );
    }
    const { data, cache } = await response;
    const deliveryTrackingData = data.deliveryTracking;
    const maxAge = cache?.maxAge;
    return {
      wismoSlot: deliveryTrackingData,
      pollingFrequency: maxAge
    };
  } catch (error) {
    const res = error;
    if (typeof window !== 'undefined') {
      //browser errors
      const error = res?.parsedBody?.errors?.[0];
      const statusCode = res?.status || 'unknown';
      const errorMessage = error?.message || res.message || 'unknown';
      const message = `Get Trip Tracking Error - Message: ${errorMessage}, Status: ${statusCode}`;
      logApmError(new Error(message), { traceId });
    }
  }
  return null;
};
