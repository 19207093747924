import React, { Component } from 'react';
import PropTypes from 'prop-types';
import helpers from '#/lib/decorators/helpers';
import SafelyOutputString from '#/components/safely-output-string';
import AmendOrderButton from '#/components/shared/amend-order-button';
import { connect } from '#/lib/render/connect-deep-compare';
import Icon from './icon';
import DeliveryDate from './delivery-date';
import MainText from '#/components/home/context-cards/main-text';
import { COLLECTION } from '#/constants/shopping-methods';
import { getTimezoneAndLocaleAppliedMoment } from '#/lib/date-helpers';
import { getLanguage, getTimezone } from '#/reducers/app';
import { getFormattedDate } from '#/lib/i18n/date-utils';
import { BookSlotContextCardWrapper } from './styled';
import { getFormattedTime } from '#/components/shared/formatted-time/selector';

const mapStateToProps = (state, { order, c: config }) => {
  return {
    language: getLanguage(state),
    timezone: getTimezone(state),
    displayAsGuidePrice: config('displayAsGuidePrice'),
    expiryTime: getFormattedTime(state, order.expiry, config)
  };
};

@helpers(['c', 't'])
@connect(mapStateToProps)
class OrderCreatedContextCard extends Component {
  static propTypes = {
    c: PropTypes.func,
    isAmendOrder: PropTypes.bool,
    language: PropTypes.string.isRequired,
    order: PropTypes.shape({
      address: PropTypes.object.isRequired,
      displayAsGuidePrice: PropTypes.bool,
      expiry: PropTypes.string.isRequired,
      guidePrice: PropTypes.number,
      id: PropTypes.string.isRequired,
      orderNo: PropTypes.string.isRequired,
      confirmationDateTime: PropTypes.string,
      slot: PropTypes.shape({
        start: PropTypes.string,
        end: PropTypes.string
      })
    }).isRequired,
    t: PropTypes.func,
    timezone: PropTypes.string
  };

  static defaultProps = {
    isAmendOrder: true
  };

  formatDate = date =>
    getTimezoneAndLocaleAppliedMoment(
      date,
      this.props.timezone,
      this.props.language
    );

  getInfoHtmlTitle = (sameDayEnabled, expiresToday, shoppingMethod) => {
    if (sameDayEnabled && expiresToday)
      return 'home:book-a-slot.order-placed.details-same-day';

    if (shoppingMethod === COLLECTION)
      return 'home:book-a-slot.order-placed.details-collection';

    return 'home:book-a-slot.order-placed.details';
  };

  render() {
    const {
      order: {
        address,
        expiry,
        guidePrice,
        orderNo,
        shoppingMethod,
        slot: { start: slotStart, end: slotEnd },
        confirmationDateTime
      },
      displayAsGuidePrice,
      expiryTime,
      c: config,
      isAmendOrder,
      t: translate
    } = this.props;

    const start = this.formatDate(slotStart);
    const end = this.formatDate(slotEnd);
    const expiryDate = this.formatDate(expiry);
    const now = this.formatDate();

    const sameDayEnabled = config('sameDayClickAndCollectEnabled');
    const expiresToday = expiryDate.isSame(now, 'day');
    const amendByInfo = {
      amend_by_time: expiryTime,
      amend_by_date: expiryDate.format('dddd, D MMMM YYYY')
    };

    const title = this.getInfoHtmlTitle(
      sameDayEnabled,
      expiresToday,
      shoppingMethod
    );
    const infoHTML = translate(title, amendByInfo);

    const orderPlacedOn = getFormattedDate(
      confirmationDateTime,
      'Do MMMM YYYY'
    );

    return (
      <div
        className="context-card-book-a-slot--order-placed"
        data-auto="hp-order-placed-context-card"
      >
        <BookSlotContextCardWrapper
          className="context-card-book-a-slot__top"
          addMargin={false}
        >
          <Icon>
            <DeliveryDate date={end} />
          </Icon>
          <h2 className="context-card-book-a-slot__title">
            {translate('home:book-a-slot.order-placed.title')}
          </h2>
          <MainText
            address={address}
            displayAsGuidePrice={displayAsGuidePrice}
            showDeliverySlotWindow={true}
            orderDate={orderPlacedOn}
            end={end}
            guidePrice={guidePrice}
            start={start}
          />
          {isAmendOrder && (
            <AmendOrderButton
              amendCutoff={expiry}
              buttonClasses="button button-primary"
              buttonText="home:book-a-slot.order-placed.make-changes"
              formClasses="action"
              orderNo={orderNo}
            />
          )}
        </BookSlotContextCardWrapper>
        {isAmendOrder && (
          <div className="context-card-book-a-slot--bottom">
            <SafelyOutputString>{infoHTML}</SafelyOutputString>
          </div>
        )}
      </div>
    );
  }
}

export default OrderCreatedContextCard;
