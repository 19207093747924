import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Link, { TextButtonLink } from '@ddsweb/link';
import styled from 'styled-components';
import { fontSize, spacing } from '@ddsweb/selectors';
import { connect } from '#/lib/render/connect-deep-compare';
import helpers from '#/lib/decorators/helpers';
import analyticsBus from '#/analytics/analyticsBus';
import { basicEvent } from '#/analytics/types/basic';
import { CONTEXT_CARD, BOOK_A_SLOT, DELAY } from '#/analytics/constants';
import { mediumMin } from '#/constants/viewports';
import SafelyOutputString from '#/components/safely-output-string';
import { getDefaultSlotsPath } from '#/reducers/slot';
import { getLanguage } from '#/reducers/app';
import { getFirstTimeShopperTooltipVariant } from '#/reducers/experiments';
import { getIsDeliveryAvailable } from '#/selectors/available-shopping-methods';
import { isOnlyCollectionAvailable } from '#/selectors/trolley';
import FirstTimeShopperTooltip from './first-time-shopper-tooltip';
import Icon from './icon';

export const LinkButtonText = styled.span`
  font-size: ${fontSize.xs};
`;

export const ActionButton = styled.div`
  padding: ${spacing.lg} 0;
  @media (min-width: ${mediumMin}) {
    padding: 10px 0;
  }
`;

const mapStateToProps = (state, { f }) => ({
  defaultSlotsPath: getDefaultSlotsPath(f)(state),
  firstTimeShopperTooltipVariant: getFirstTimeShopperTooltipVariant(state),
  isDeliveryAvailable: getIsDeliveryAvailable(state),
  isOnlyCollectionAvailable: isOnlyCollectionAvailable(state),
  language: getLanguage(state)
});
@helpers(['f', 'l', 't', 'c'])
@connect(mapStateToProps)
export default class NoSlotBookedContextCard extends Component {
  static propTypes = {
    c: PropTypes.func.isRequired,
    defaultSlotsPath: PropTypes.string.isRequired,
    f: PropTypes.func.isRequired,
    firstTimeShopperTooltipVariant: PropTypes.string,
    isClickCollectContextCardOop1162: PropTypes.bool,
    isDeliveryAvailable: PropTypes.bool.isRequired,
    isOnlyCollectionAvailable: PropTypes.bool.isRequired,
    l: PropTypes.func.isRequired,
    language: PropTypes.string.isRequired,
    t: PropTypes.func.isRequired
  };

  emitBookASlotAnalyticsEvent() {
    basicEvent(analyticsBus, {
      type: CONTEXT_CARD,
      value: BOOK_A_SLOT,
      action: DELAY
    });
  }

  actionButtonRef = {}; // FTS experiment tooltip reference
  tooltipId = 'fts-tooltip'; // FTS tooltip id linking button and tooltip

  getButtonLabel() {
    const { t: translate, isOnlyCollectionAvailable } = this.props;
    return (
      <span>
        <span className="visually-hidden">
          {translate(
            isOnlyCollectionAvailable
              ? 'slots:fulfilment-card.not-booked.collection.intro'
              : 'slots:fulfilment-card.not-booked.intro'
          )}
        </span>
        <span aria-hidden="true">
          {translate(
            isOnlyCollectionAvailable
              ? 'slots:fulfilment-card.not-booked.collection.cta-button'
              : 'slots:fulfilment-card.not-booked.cta-button'
          )}
        </span>
      </span>
    );
  }

  getShoppingMethods() {
    const {
      c: config,
      language,
      t: translate,
      isOnlyCollectionAvailable
    } = this.props;

    const link = config(`links:firstTimeShopping:${language}`);
    return (
      <>
        <SafelyOutputString>
          {translate(
            isOnlyCollectionAvailable
              ? 'home:book-a-slot.no-slot-booked.collection.details2'
              : 'home:book-a-slot.no-slot-booked.details2'
          )}
        </SafelyOutputString>
        {link && !isOnlyCollectionAvailable && (
          <>
            <br />
            <Link href={link} target="_blank" rel="noopener">
              {translate('home:book-a-slot.no-slot-booked.details2-more')}
            </Link>
          </>
        )}
      </>
    );
  }
  renderDefaultContextCard() {
    const {
      defaultSlotsPath,
      l: language,
      t: translate,
      isOnlyCollectionAvailable
    } = this.props;
    const detailsText = translate(
      isOnlyCollectionAvailable
        ? 'home:book-a-slot.no-slot-booked.collection.details'
        : 'home:book-a-slot.no-slot-booked.details',
      {},
      { returnNullIfFalsy: true }
    );

    return (
      <>
        <div className="context-card-book-a-slot__text">
          {detailsText && (
            <p className="context-card-book-a-slot--details">{detailsText}</p>
          )}
          <p className="context-card-book-a-slot--details2">
            {this.getShoppingMethods()}
          </p>
        </div>
        <ActionButton className="action">
          <TextButtonLink
            href={language(defaultSlotsPath)}
            stretch
            onClick={this.emitBookASlotAnalyticsEvent}
          >
            <LinkButtonText>{this.getButtonLabel()}</LinkButtonText>
          </TextButtonLink>
        </ActionButton>
      </>
    );
  }
  render() {
    const {
      firstTimeShopperTooltipVariant,
      isOnlyCollectionAvailable,
      t: translate
    } = this.props;

    return (
      <div
        className="context-card-book-a-slot--not-booked"
        data-auto="hp-book-slot-context-card"
      >
        <div className="context-card-book-a-slot__top">
          <Icon>
            <div className="icon-bookaslot-01-white" />
          </Icon>
          <h2 className="context-card-book-a-slot__title">
            {translate(
              isOnlyCollectionAvailable
                ? 'slots:fulfilment-card.not-booked.collection.cta-title'
                : 'slots:fulfilment-card.not-booked.cta-title'
            )}
          </h2>
          {this.renderDefaultContextCard()}
          {firstTimeShopperTooltipVariant && (
            <FirstTimeShopperTooltip
              id={this.tooltipId}
              targetRef={this.actionButtonRef}
              firstTimeShopperTooltipVariant={firstTimeShopperTooltipVariant}
            />
          )}
        </div>
        <div className="context-card-book-a-slot--bottom">
          <p className="context-card-book-a-slot--info-message">
            {translate(
              isOnlyCollectionAvailable
                ? 'home:book-a-slot.no-slot-booked.collection.details3'
                : 'home:book-a-slot.no-slot-booked.details3'
            )}
          </p>
        </div>
      </div>
    );
  }
}
