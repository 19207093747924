import { useState, useCallback } from 'react';

export const useAsyncError = (): ((error: Error) => void) => {
  const [_, setError] = useState();

  return useCallback(
    error => {
      setError(() => {
        throw error;
      });
    },
    [setError],
  );
};
