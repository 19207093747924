import styled, { css } from 'styled-components';
import { Heading } from '@ddsweb/heading';
import { spacing, colors, fontSize } from '@ddsweb/selectors';
import { media } from '@ddsweb/foundation';

const stepMarkerWidth = 25;
const stepColor = props => (props.isComplete ? colors.success : colors.lines);
const mobileStepWidth = props => `${100 / (props.noOfSteps - 1)}%`;

export const Wrapper = styled.ol.attrs(props => ({
  role: 'progressbar',
  'aria-valuemin': 1,
  'aria-valuenow': props.currentStep,
  'aria-valuemax': props.noOfSteps,
}))`
  display: flex;
  list-style-type: none;
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
`;

const stepLineStyle = css`
  content: '';
  position: absolute;
  /* Subtracting half the stepMarkerWidth ensures that the line starts from the middle of the 
     circular step marker */
  width: calc(100% - ${stepMarkerWidth / 2}px);
  top: ${stepMarkerWidth / 2}px;
  right: 50%;
  height: 2px;
  background: ${stepColor};
`;

const stepFullWidthStyles = css`
    /* On mobile we want the markers to be evenly spaced out, with the first and last marker
  being adjacent to the very left and very right respectively (see diagram 1).
  We also need the step text of the first and last step to be aligned to the left and right
  (rather than the center under the marker) respectively. This is what the code below does.
  
  Diagram 1 (Mobile)
  |o----o----o---o| |o---o----o----o| |o---o---o---o|
   Step1               Step2                   step4

  Diagram 2 (Desktop)
  |--o--o--o--o--|
   Step1
  */

   :not(:first-of-type):not(:last-of-type) {
    width: ${mobileStepWidth};
  }

  :first-of-type {
    align-items: flex-start;
  }

  :last-of-type {
    align-items: flex-end;
  }

  :first-of-type,
  :last-of-type {
    /* 
      The first and last step should be half the width of the other steps
      First step  : o--
      Last step   : --o
      Other steps : --o--
    */
    width: calc(${mobileStepWidth} / 2);
  }

  :last-of-type {
    ::before {
      ${stepLineStyle}
      /* The last step width is half the other steps. Therefore for the step line
      to reach the second last step we need to use double the step width */
      width: calc(200% - ${stepMarkerWidth / 2}px);
      /* This ensures the line starts from the middle of the circular marker */
      right: ${stepMarkerWidth / 2}px;
    }
  }
`;

export const Step = styled.div`
  display: flex;
  flex-direction: column;
  width: ${props => `${100 / props.noOfSteps}%`};
  position: relative;
  align-items: center;
  /* This creates a progress line to the left of the marker, except for the first marker */
  :not(:first-of-type) {
    ::before {
      ${stepLineStyle}
    }
  }
  ${({ fullWidth }) => fullWidth && stepFullWidthStyles};

  ${media.belowTabletLarge`
      ${stepFullWidthStyles}
  `}
`;

export const StepMarker = styled.li`
  display: flex;
  justify-content: center;
  align-items: center;
  background: ${props => (props.isComplete ? colors.success : colors.background)};
  border: 1px solid ${stepColor};
  width: ${stepMarkerWidth}px;
  height: ${stepMarkerWidth}px;
  border-radius: ${stepMarkerWidth / 2}px;
  /* This is required to make sure the marker appears on top of the progress line */
  z-index: 1;
`;

export const StepText = styled(Heading)`
  && {
    color: ${props => (props.paymentIssue ? colors.text : props.isComplete ? colors.base : colors.text)};
  }
  font-size: ${props => props.paymentIssue && fontSize.xx};
  white-space: nowrap;
  padding-top: ${spacing.xs};

  margin-right: ${({ stepNo, noOfSteps }) => (stepNo === noOfSteps ? '0' : spacing.sm)};

  margin-left: ${({ stepNo }) => (stepNo === 1 ? '0' : spacing.sm)};

  text-align: center;

  /* The 'white-space' style below ensures the text can overflow between steps instead of creating two lines.
    This is important for mobile-like designs for this experiment */

  ${media.belowTabletLarge`
    display: ${props => props.isCurrentStep || 'none'};
    margin-right: 0;
    margin-left: 0;
    text-align: initial;
  `}
`;
