import styled from 'styled-components';
import { mediumMin } from '#/constants/viewports';

/**
 * DCS driven component and so margin-top is set with negative value.
 * This helps matching the design and also to avoid any change in parent component
 * when this doesn't render
 */
export const NotificationBannerWrapper = styled.div`
  padding: 0 6px;
  margin-top: -6px;

  @media (max-width: ${mediumMin}) {
    padding: 0;
    margin-top: 0;
  }
`;

NotificationBannerWrapper.displayName = 'NotificationBannerWrapper';
