import React from 'react';
import { TextButtonLink } from '@ddsweb/link';
import { connect } from '#/lib/render/connect-deep-compare';
import helpers from '#/lib/decorators/helpers';
import { TFeatureFunc, TTranslateFunc } from '#/lib/records/helpers.defs';
import { getDefaultSlotsPath } from '#/reducers/slot';
import { basicEvent } from '#/analytics/types/basic';
import analyticsBus from '#/analytics/analyticsBus';
import { TLanguageLink } from '#/components/slots/delivery-content-section/index.defs';
import { StyledBookSlotButtonWrapper } from './styled';
import { BOOK_A_SLOT, CONTEXT_CARD, DELAY } from '#/analytics/constants';

export type TOwnProps = {
  defaultSlotsPath: string;
  f: TFeatureFunc;
  l: TLanguageLink;
  t: TTranslateFunc;
};

const mapStateToProps = (
  state: Store,
  { f }: TOwnProps,
): {
  defaultSlotsPath: string;
} => ({
  defaultSlotsPath: getDefaultSlotsPath(f)(state),
});

export const BookSlotButton: React.FC<TOwnProps> = (props: TOwnProps): JSX.Element => {
  const { t: translate, l: language, defaultSlotsPath } = props;

  const emitBookASlotAnalyticsEvent = (): void => {
    basicEvent(analyticsBus, {
      type: CONTEXT_CARD,
      value: BOOK_A_SLOT,
      action: DELAY,
    });
  };

  return (
    <StyledBookSlotButtonWrapper data-auto="hp-book-slot-context-card">
      <TextButtonLink href={language(defaultSlotsPath)} stretch textSize="md" onClick={emitBookASlotAnalyticsEvent}>
        {translate('slots:fulfilment-card.not-booked.cta-button')}
      </TextButtonLink>
    </StyledBookSlotButtonWrapper>
  );
};

export default helpers(['f', 'l', 't'])(connect(mapStateToProps)(BookSlotButton));
