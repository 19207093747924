import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { connect } from '#/lib/render/connect-deep-compare';
import helpers from '#/lib/decorators/helpers';
import { interpolate } from '#/utils/text-utils';
import { Heading, GreetingMessage, GreetingName } from './styled';
import { getIsAmongSlotBookingVariants } from '#/experiments/oop-1978/selectors';

const mapStateToProps = state => ({
  updateWelcomeMessageStyle: getIsAmongSlotBookingVariants(state)
});

@connect(mapStateToProps)
@helpers(['t'])
export default class UserGreeting extends PureComponent {
  static propTypes = {
    t: PropTypes.func.isRequired,
    user: PropTypes.shape({
      displayName: PropTypes.string,
      isAuthenticated: PropTypes.bool.isRequired,
      isRegistered: PropTypes.bool.isRequired,
      lastName: PropTypes.string,
      title: PropTypes.string
    }),
    welcomeTranslations: PropTypes.object.isRequired,
    updateWelcomeMessageStyle: PropTypes.bool.isRequired
  };

  render() {
    const { user, welcomeTranslations, updateWelcomeMessageStyle } = this.props;
    const welcomeText = welcomeTranslations['welcome-message'];
    const contents = [
      <GreetingMessage data-auto="user-greeting--message" key="message">
        {welcomeText}
      </GreetingMessage>
    ];

    if (user.isAuthenticated && user.isRegistered) {
      const userNameText = welcomeTranslations['username-message'];

      contents.push(
        <GreetingName key="name">
          {this.getGreeting(user, userNameText)}
        </GreetingName>
      );
    }

    return (
      <Heading
        data-auto="hp-welcome-msg"
        className={classNames({
          'heading--in-line': updateWelcomeMessageStyle
        })}
      >
        {contents}
      </Heading>
    );
  }

  getGreeting(user, userNameText) {
    return interpolate(userNameText, {
      name: user.displayName,
      title: this.getTitle(user),
      lastName: user.lastName
    });
  }

  getTitle({ title }) {
    const { t: translate } = this.props;

    if (title) {
      const translatedTitle = translate(`name-title.${title}`);

      if (translatedTitle !== '') {
        return this._capitalize(translatedTitle);
      }

      return this._capitalize(title);
    }

    return '';
  }

  _capitalize(str) {
    return str.charAt(0).toUpperCase() + str.slice(1);
  }
}
