import styled from 'styled-components';
import { spacing, fontSize } from '@beans/selectors';
import { SubHeading, BodyText } from '@beans/typography';
import Modal from '@beans/modal';
import { colors } from '@beans/selectors';

export const StyledModal = styled(Modal)`
  .beans-modal__close-link {
    background-color: ${colors.white};
    color: ${colors.tescoBlue};
  }
  .beans-modal__mask {
    z-index: 12;
  }
`;

export const Content = styled.div`
  margin-bottom: ${spacing.lg};
`;

export const ButtonContainer = styled.section`
  display: flex;
  justify-content: space-between;
  .beans-button__container {
    width: 155px;
  }
`;

export const StyledHeading = styled(SubHeading)`
  && {
    margin: ${spacing.md} 0;
    font-size: ${fontSize.md};
  }
`;

export const StyledBodyText = styled(BodyText)`
  padding: ${spacing.xs} 0;
`;
